import { FC, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

export const Callback: FC = () => {
  const { oktaAuth } = useOktaAuth()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const handleLoginRedirect = async () => {
    const state = searchParams.get('state')
    if (state) {
      try {
        await oktaAuth.handleLoginRedirect()
      } catch (error) {
        if (
          error?.toString() ===
          'OAuthError: User is not assigned to the client application.'
        ) {
          navigate('/no-permission')
        }
      }
    } else {
      navigate('/')
    }
  }
  useEffect(() => {
    handleLoginRedirect()
  }, [oktaAuth])

  return null
}
