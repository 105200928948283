import { MutableRefObject, useRef, useState, useEffect } from 'react'

export const useIntersection = <T extends IntersectionObserverInit>(
  options?: T
): [MutableRefObject<HTMLDivElement | null>, boolean] => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) observer.observe(containerRef?.current)

    return () => {
      if (containerRef.current) observer.unobserve(containerRef?.current)
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}
