import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import { Command } from 'src/types/Command'
import { InspectionRapportView } from 'src/types/InspectionReport'
import {
  ObservationFindingsSearchInput,
  ObservationFindingOverview,
  ChangeType,
  Rate,
} from 'src/types/ObservationFindings'
import type { Locations, LocationsEnum } from 'src/types/locations'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'
import { getIsoDate } from 'src/utils/getIsoDate'

export function locationToEnum(location: Locations): LocationsEnum {
  switch (location) {
    case 'Onbekend':
      return '0'
    case 'Aalsmeer':
      return '1'
    case 'Naaldwijk':
      return '2'
    case 'Rijnsburg':
      return '3'
    case 'Eelde':
      return '7'
    case 'Rheinmaas':
      return '24'
    case 'RFHconcern':
      return '25'
    default:
      return '0'
  }
}

export const getObservationFindingsRapports = (
  searchInput: ObservationFindingsSearchInput,
  top = 25,
  page = 0
): AxiosPromise<ODataResponse<ObservationFindingOverview[]>> => {
  const typeFilter = []

  if (searchInput.type?.includes(ChangeType.reclamatie)) {
    typeFilter.push(Rate.complaint)
  }
  if (searchInput.type?.includes(ChangeType.bevinding)) {
    typeFilter.push(...[Rate.low, Rate.middle, Rate.high, Rate.none])
  }

  const filter = {
    rate: searchInput.type?.length ? { in: typeFilter } : undefined,
    location: searchInput.location?.length
      ? {
          in: searchInput.location.map(l => locationToEnum(l)),
        }
      : undefined,

    supplierId: searchInput.supplier?.clientNumber
      ? { eq: Number(searchInput.supplier.clientNumber) }
      : undefined,

    productName: searchInput.product
      ? { contains: searchInput.product.toUpperCase() }
      : undefined,
    'tolower(lastAuditedBy)': {
      contains: searchInput.approvedBy?.toLowerCase(),
    },
    plantType: searchInput.plantType?.length
      ? { in: searchInput.plantType }
      : undefined,
    auctionDate: searchInput.date
      ? {
          eq: getIsoDate(searchInput.date),
        }
      : undefined,
  }

  const query = buildQuery<ObservationFindingOverview>({
    top,
    skip: page * top,
    orderBy: [
      ['auctionDate', 'desc'],
      ['supplierId', 'asc'],
      ['supplyFormCode', 'asc'],
      ['supplyFormCharacter', 'asc'],
    ],
    filter,
    count: true,
  })

  return api.get(`/odata/v1/ObservationFindingRapports${query}`)
}

// verslag maken = je krijgt als response een id, die
// moet je dan gebruiken als je /api/v{version}/InspectionRapports/GetInspectionRapportView aanroept
// dan krijg je alle keurverslagen informatie in een object
export const postInspectionReport = (
  inspectionFileIds: string[],
  supplierId: number
): AxiosPromise<Command> =>
  api.post('/api/v1/InspectionRapports/AddNewInspectionRapport', {
    inspectionFileIds,
    supplierId,
  })

export const getInspectionRapportView = (
  // er is 1 inspection rapport met meerdere inspection files
  inspectionRapportId: string,
  culture: 'nl-NL' | 'en-GB' | 'de-DE'
): AxiosPromise<InspectionRapportView> =>
  api.get('/api/v1/InspectionRapports/GetInspectionRapportView', {
    params: {
      InspectionRapportId: inspectionRapportId,
      culture,
    },
  })

export const getCommentImageById = (queryString: string): AxiosPromise<string> =>
  api.get(`/api/v1/InspectionFiles/CommentImageById?${queryString}`)

export type PdfPayload = {
  supplierId: number
  inspectionRapportId: string
  fileExtension: 'pdf'
  image: string
}

export const addPdfToInspectionRapport = (
  payload: PdfPayload
): AxiosPromise<InspectionRapportView> =>
  api.post('/api/v1/InspectionRapports/AddPdfToInspectionRapport', payload)

export type AddAuditCommentPayload = {
  clockSupplyLineId: number
  inspectionRapportId: string
  inspectionFileId: string
  comment: string
  fileExtension: string
  image: string
  onAdded?: () => void
}

export const postAuditComment = (payload: AddAuditCommentPayload): AxiosPromise<void> =>
  api.post(
    `/api/v1/AuditAssignments/${payload.clockSupplyLineId}/AddAuditComment`,
    payload
  )

export type DeleteAuditCommentPayload = {
  clockSupplyLineId: number
  inspectionRapportId: string
  inspectionFileId: string
  auditAssignmentId: string
}

export const deleteAuditComment = (
  payload: DeleteAuditCommentPayload
): AxiosPromise<void> =>
  api.post(
    `/api/v1/AuditAssignments/${payload.clockSupplyLineId}/RemoveAuditComment`,
    payload
  )

export type ChangeInspectionFileRemarkPayload = {
  inspectionFileId: string
  inspectionRapportId: string
  remark: string
}

export const changeInspectionFileRemark = (
  payload: ChangeInspectionFileRemarkPayload
): AxiosPromise<void> =>
  api.post(
    `/api/v1/InspectionFiles/${payload.inspectionFileId}/ChangeInspectionFileRemark`,
    payload
  )
