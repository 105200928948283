import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, ReactNode, useContext } from 'react'

import { BatchStatus } from '../types/ClockSupplyLine'

export interface BatchStatusStore {
  previousBatchStatus?: BatchStatus
  setBatchStatus: (previousBatchStatus: BatchStatus | undefined) => void
  previousBatchRef?: string
  setBatchRef: (batchRef: string | undefined) => void
  isBatchStatusSaved: boolean
  setIsBatchStatusSaved: (success: boolean) => void
  isSearchDisabled: boolean
  setIsSearchDisabled: (disabled: boolean) => void
}

export const BatchStatusStoreContext = createContext<BatchStatusStore | null>(null)

export const initialState: BatchStatusStore = {
  previousBatchStatus: undefined,
  previousBatchRef: undefined,
  isBatchStatusSaved: false,
  isSearchDisabled: false,
  setBatchStatus(batchStatus) {
    this.previousBatchStatus = batchStatus
  },
  setBatchRef(batchRef) {
    this.previousBatchRef = batchRef
  },
  setIsBatchStatusSaved(saved) {
    this.isBatchStatusSaved = saved
  },
  setIsSearchDisabled(disabled) {
    this.isSearchDisabled = disabled
  },
}

// Store as component
export const BatchStatusStoreProvider: FC<{
  initial?: BatchStatusStore
  children: ReactNode
}> = ({ children, initial = initialState }) => {
  const batchStatusStore: BatchStatusStore = useLocalObservable(() => initial)

  return (
    <BatchStatusStoreContext.Provider value={batchStatusStore}>
      {children}
    </BatchStatusStoreContext.Provider>
  )
}

export const useBatchStatusStore = (): BatchStatusStore => {
  const store = useContext(BatchStatusStoreContext)

  if (!store) {
    throw new Error('No batch status store defined')
  }

  return store
}
