import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { Person } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { RfhLogo } from '@rfh-core/illustrations'

import { Feature, useFeatureFlags } from '../hooks/useFeatureFlag'
import { DRAWER_WIDTH, FilterContext, filterDrawerTransition } from './FilterDrawer'

export const AppBar: FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const { open } = useContext(FilterContext)

  const enabledFeatures = useFeatureFlags()

  return (
    <Box
      bgcolor='white'
      sx={{
        zIndex: ({ zIndex }) => zIndex.drawer + 1,
        width: '100%',
        position: 'inherit',
      }}
    >
      <Box
        sx={{
          transition: filterDrawerTransition(open),
          ml: { xs: 0, xl: open ? DRAWER_WIDTH : 0 },
        }}
      >
        <Container
          sx={{
            maxWidth: '1536px',
            display: 'flex',
            boxShadow: 'none',
            paddingBottom: ({ spacing }) => spacing(1),
            paddingTop: ({ spacing }) => spacing(1),
          }}
          maxWidth={false}
        >
          <Box role='button' onClick={() => navigate('/')}>
            <RfhLogo
              sx={{
                width: '83px',
                height: '44px',
                color: ({ rfhColors }) => rfhColors.black,
                display: 'block',
                marginRight: ({ spacing }) => spacing(4),
              }}
            />
          </Box>
          <Box width='100%' display='flex' alignItems='center'>
            <Typography component='h1' noWrap variant='body2' fontWeight='bold'>
              {t('appName')}
            </Typography>
          </Box>

          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            {t('menuItems', { returnObjects: true })
              .filter(
                ({ feature }) =>
                  feature === undefined || enabledFeatures.includes(feature as Feature)
              )
              .map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    a: {
                      color: theme =>
                        matchPath(item.path, location.pathname)
                          ? theme.rfhColors.dutchOrange
                          : theme.rfhColors.black,
                      textDecoration: 'none',
                      textTransform: 'capitalize',
                      fontWeight: 'bold',
                      '&:hover': {
                        color: theme => theme.rfhColors.hydroGreen,
                      },
                    },
                  }}
                >
                  <Link to={item.path}>{item.label}</Link>
                </MenuItem>
              ))}
            <MenuItem onClick={() => navigate('/logout')}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  '&:hover': {
                    color: theme => theme.rfhColors.hydroGreen,
                  },
                }}
              >
                <Person /> {t('logout')}
              </Box>
            </MenuItem>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
