import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, ReactNode, useContext } from 'react'

// TODO: currently isHotkeyActive acts for hotkeys at the same time
//       maybe we should make it an array of active hotkeys
// Zelfde todo als in useHotkey.ts -> moeten we hier nog wat mee?
export interface HotkeyStore {
  isHotkeyActive: boolean
  setHotkeyActive: (active: boolean) => void
}

export const HotkeyStoreContext = createContext<HotkeyStore | null>(null)

const initialState: HotkeyStore = {
  isHotkeyActive: true,
  setHotkeyActive(active) {
    this.isHotkeyActive = active
  },
}

// Store as component
export const HotkeyStoreProvider: FC<{
  initial?: HotkeyStore
  children: ReactNode
}> = ({ children, initial = initialState }) => {
  const hotkeyStore: HotkeyStore = useLocalObservable(() => initial)

  return (
    <HotkeyStoreContext.Provider value={hotkeyStore}>
      {children}
    </HotkeyStoreContext.Provider>
  )
}

export const useHotkeyStore = (): HotkeyStore => {
  const store = useContext(HotkeyStoreContext)

  if (!store) {
    throw new Error('No hotkey store defined')
  }

  return store
}
