import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import type {
  AddBatchCharacteristicParams,
  BatchCharacteristic,
  ChangeBatchCharacteristicParams,
  Characteristic,
  CharacteristicValueCode,
  RemoveBatchCharacteristicParams,
} from 'src/types/ClockSupplyLine'
import { NegativeCodes, PositiveCodes } from 'src/types/inspectionCodes'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'

export const queryCharacteristicValues = (
  characteristicCode: string
): AxiosPromise<CharacteristicValueCode[]> =>
  api.get('/api/v1/CharacteristicValues', {
    params: { $filter: `contains(characteristicCode,'${characteristicCode}')` },
  })

export const addBatchCharacteristic = (
  params: AddBatchCharacteristicParams
): AxiosPromise<BatchCharacteristic> =>
  api.post('/api/v1/BatchCharacteristics/Commands/Add', params)

export const changeBatchCharacteristic = (
  params: ChangeBatchCharacteristicParams,
  id: number
): AxiosPromise<BatchCharacteristic> =>
  api.post(`/api/v1/BatchCharacteristics/Commands/ChangeValueCode/${id}`, params)

export const removeBatchCharacteristic = (
  params: RemoveBatchCharacteristicParams,
  id: number
): AxiosPromise<void> =>
  api.post(`/api/v1/BatchCharacteristics/Commands/Remove/${id}`, params)

export const queryCharacteristicCodes = (
  currentCharacteristics: BatchCharacteristic[],
  searchQuery?: string
): AxiosPromise<ODataResponse<Characteristic[]>> => {
  const filter = {
    code: { contains: searchQuery?.toUpperCase() || undefined },
    not: {
      code: {
        in: [
          ...Object.values(NegativeCodes),
          ...Object.values(PositiveCodes),
          ...currentCharacteristics.map(item => item.characteristicCode),
        ],
      },
    },
  }

  const query = buildQuery<Characteristic>({
    filter,
  })

  return api.get(`/odata/v1/Characteristics${query}`)
}
