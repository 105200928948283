import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FormHelperText from '@mui/material/FormHelperText'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { useMutate } from 'src/hooks/useMutate'
import { useTransformToRow } from 'src/hooks/useTransformToRow'
import { ClockSupplyLine } from 'src/types/ClockSupplyLine'
import { NegativeCodes as NegativeCodesEnum } from 'src/types/inspectionCodes'
import { filterNegativeCodes } from 'src/utils/characteristic'

import { StyledTableContainer } from './styles/characteristics.styles'

type NegativeCodesProps = {
  batch: ClockSupplyLine
  setMutatedBatch: (batch: ClockSupplyLine) => void
}

export const NegativeCodes: FC<NegativeCodesProps> = ({ batch, setMutatedBatch }) => {
  const [generalError, setGeneralError] = useState<boolean>(false)

  const maxNumberOfNegativeCodes = 2
  const { t } = useTranslation()
  const { transformToMutableRow, transformToRow } = useTransformToRow(
    batch,
    setMutatedBatch,
    filterNegativeCodes,
    Object.keys(NegativeCodesEnum),
    maxNumberOfNegativeCodes,
    setGeneralError
  )

  const { mutatingActive } = useMutate()

  const negativeCodes = mutatingActive ? transformToMutableRow() : transformToRow()

  return (
    <>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>{t('inspection.labels.negativeCode')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {negativeCodes.length > 0 ? (
              negativeCodes.map((code, index) => (
                <TableRow key={index}>
                  {code.values.map((value, valueIndex) => (
                    <TableCell
                      key={valueIndex}
                      size='small'
                      sx={{ overflow: 'hidden' }}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>{t('inspection.noResults')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {generalError && (
        <FormHelperText error>
          {t('queryState.generalFailureMutation.message')}
        </FormHelperText>
      )}
    </>
  )
}
