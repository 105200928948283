import TableContainer from '@mui/material/TableContainer'
import { styled } from '@mui/material/styles'

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    fontSize: '14px',
    padding: theme.spacing(2, 3, 2, 0),
    verticalAlign: 'baseline',
    '&:first-of-type': {
      padding: theme.spacing(0, 2),
    },
  },
}))
