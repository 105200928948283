import { useMutation } from '@tanstack/react-query'

import { api } from 'src/utils/api'

type Payload = {
  clockSupplyLineId: number
  name: string
  fileExtension: 'jpeg'
  image: string | undefined
}

export const useUploadInspectionPhoto = () =>
  useMutation({
    mutationKey: ['uploadInspectionPhoto'],
    mutationFn: async ({ name, ...payload }: Payload) =>
      api.post(
        `api/v1/ClockSupplyLines/${payload.clockSupplyLineId}/AddAuditImage`,
        payload
      ),
  })
