import { observer } from 'mobx-react-lite'

import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { OrangeScanIcon } from '@rfh-core/icons/'

import { EmptyIcon, Form } from 'src/components/batch/BatchInput.styles'
import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import { useBatchStatusStore } from 'src/stores/BatchStatusStore'
import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { ClockSupplyLine } from 'src/types/ClockSupplyLine'
import { validateInput } from 'src/utils/validateInput'

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  marginTop: '0 !important',
  '& svg, & .MuiTypography-root': {
    fontSize: theme.spacing(2.5),
  },
}))

export type BatchInputProps = {
  batchRef?: string
  handleBatchSearch: (searchedBatchRef: string) => void
  batch?: ClockSupplyLine
}

export const BatchInput: FC<BatchInputProps> = observer(
  ({ batchRef, handleBatchSearch, batch }) => {
    const { t } = useTranslation()
    const { setHotkeyActive } = useHotkeyStore()
    const [searchedBatchRef, setSearchedBatchRef] = useState<string>('')
    const [validationError, setValidationError] = useState<boolean>(false)
    const { offline } = useOnlineOffline()

    const textFieldRef = useRef<HTMLInputElement>(null)
    const { isSearchDisabled, setIsSearchDisabled } = useBatchStatusStore()

    useEffect(() => {
      batchRef && setSearchedBatchRef(batchRef)
    }, [batchRef])

    useEffect(() => {
      if (batchRef && !validateInput(batchRef)) {
        setValidationError(true)
      }
    }, [])

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
      const value = event.target.value.replace(/[[\]]/g, '')

      const isValid = validateInput(value)
      setValidationError(!isValid)
      setSearchedBatchRef(value)
      isValid && setIsSearchDisabled(false)

      if (event.target.value.slice(-1) === ']') {
        handleBatchSearch(value)
      }
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
      event.preventDefault()
      handleBatchSearch(searchedBatchRef)

      textFieldRef?.current?.focus()
    }

    const hasNoSupplyFormCharacterMatch =
      batch &&
      batchRef &&
      batchRef.slice(-1).toLowerCase() !== batch.supplyFormCharacter.toLowerCase()

    return (
      <Form onSubmit={handleSubmit}>
        <Box display='flex' mt={3}>
          <TextField
            sx={{
              width: ['100%', '100%', '300px'],
            }}
            inputRef={textFieldRef}
            error={validationError}
            helperText={validationError ? t('inspection.batchInput.helperText') : null}
            placeholder={t('home.batchSearchCard.placeholderText')}
            value={searchedBatchRef}
            onChange={handleInputChange}
            variant='outlined'
            onBlur={() => setHotkeyActive(true)}
            onFocus={() => setHotkeyActive(false)}
            inputProps={{
              'aria-label': t('inspection.batchInput.batchRef'),
              className: 'batchRefInputField',
            }}
            InputProps={{
              id: 'batchRefInput',
              startAdornment: (
                <StyledInputAdornment position='start'>
                  <OrangeScanIcon />
                </StyledInputAdornment>
              ),
              endAdornment: (
                <StyledInputAdornment position='end'>
                  <EmptyIcon
                    aria-label={t('inspection.batchInput.emptyField')}
                    valuelength={searchedBatchRef.length} // no camelcase is intended because of an issue with SVGs and passing props
                    onClick={() => setSearchedBatchRef('')}
                  />
                </StyledInputAdornment>
              ),
            }}
          />
          <IconButton
            aria-label={t('inspection.batchInput.sendButton')}
            sx={{ borderRadius: '0', height: '40px' }}
            type='submit'
            className='contained'
            disabled={offline || validationError || isSearchDisabled}
          >
            <SearchIcon />
          </IconButton>
          {hasNoSupplyFormCharacterMatch && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: 4,
                fontWeight: 'bold',
              }}
            >
              <Box sx={{ fontWeight: 'regular', mr: 1 }}>
                {t('inspection.batchInput.batchMismatch')}
              </Box>
              {batch.supplyFormCharacter.toUpperCase()}
            </Box>
          )}
        </Box>
      </Form>
    )
  }
)
