import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

export const FinanceSettlementCard: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card className='CardSecondary'>
      <CardContent>
        <Box>
          <Typography variant='h4'>{t('home.financeSettlement.title')}</Typography>
          <Typography variant='body2'>
            {t('home.financeSettlement.bodyText')}
          </Typography>
        </Box>
        <CardActions>
          <Button onClick={() => navigate('/financial-corrections')} variant='text'>
            {t('home.financeSettlement.buttonText')}
          </Button>
        </CardActions>
      </CardContent>
      <CardMedia component='img' image='/assets/images/Logistiek_Aalsmeer_2016.png' />
    </Card>
  )
}
