import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { Button, Stack, Typography } from '@mui/material'

import { Config } from 'src/configs/Config'
import { useNotification } from 'src/hooks/useNotification'

import { Loader } from '../Loader'
import BottomBar from '../bottomBar/BottomBar'
import { BatchSwitcher } from './BatchSwitcher'
import { InspectionPhoto } from './inspectionPhoto/InspectionPhoto'
import { useBatchQuery } from './useBatchQuery'

export const Batch = () => {
  const { t } = useTranslation()
  // react router ensures that the code is always a string
  // therefore we can safely do this type assertion
  // this typing issue is resolved in @tanstack/react-router
  const { code } = useParams() as { code: string }
  const [inspectionPhotos, setInspectionPhotos] = useState<File[]>([])
  const { data, isLoading } = useBatchQuery(code)
  const [isCameraModalShown, setIsCameraModalShown] = useState(false)

  const changeInspectionPhotos = (fileList: FileList) => {
    setInspectionPhotos([...fileList])
  }
  const { showNotification } = useNotification()

  if (isLoading) return <Loader isLoading />

  const clockSupplyLineId = data?.batchImages![0]?.clockSupplyLineId
  if (!data || !clockSupplyLineId) {
    return (
      <div>
        Batch niet gevonden <Link to='/batch'>terug naar zoeker</Link>
      </div>
    )
  }

  const src = `${Config.beeldbankHostValue}/foto/volledig/${
    data.batchImages![0]?.supplierImageId
  }`

  if (inspectionPhotos.length > 0) {
    return (
      <InspectionPhoto
        clockSupplyLineId={clockSupplyLineId}
        files={inspectionPhotos}
        onSuccess={() => {
          showNotification([t('photoFeature.addInspectionPhotosSuccess')], 'success')
          setInspectionPhotos([])
        }}
        onCancel={() => {
          setInspectionPhotos([])
        }}
      />
    )
  }

  return (
    <>
      <BottomBar setIsCameraModalShown={setIsCameraModalShown} />
      {!isCameraModalShown && (
        <Stack direction='column' padding={4}>
          <Typography variant='h2'>{data.supplyFormCode}</Typography>

          <BatchSwitcher
            supplyFormCharacters={data.supplyFormCharacters}
            supplyFormCode={data.supplyFormCode}
          />

          <img src={src} alt='batch foto' />
          <Stack direction='column' spacing={4} bgcolor='#FFF' padding={6}>
            <Typography variant='h3'>{data.product?.productName}</Typography>

            <Button href={`/batch/${code}/clock-photo`} variant='outlined' fullWidth>
              {t('photoFeature.editClockPhoto')}
            </Button>
            <Button variant='outlined' fullWidth component='label'>
              {t('photoFeature.addInspectionPhoto')}
              <input
                type='file'
                hidden
                multiple
                onChange={({ target: { files } }) =>
                  files && changeInspectionPhotos(files)
                }
              />
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  )
}
