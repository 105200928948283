import { CourtesyCodes } from 'src/types/CourtesyCodes'

export type CharacteristicValueCode = {
  characteristicCode: string
  value: string
  valueCode: string
}

export type BatchCharacteristic = {
  characteristic?: Characteristic
  characteristicCode: string
  characteristicValue?: CharacteristicValue
  clockSupplyLineId: number
  created?: string
  createdBy?: string | null
  id: number
  lastModified?: string | null
  lastModifiedBy?: string | null
  sortingCode: number
  valueCode: string
  error?: boolean
  isReadonly: boolean
}

export type Characteristic = {
  code: string
  description: string
  isImmutable?: boolean | null
}

type CharacteristicValue = {
  characteristicCode: string
  value?: string | null
  valueCode: string
}

type BatchPart = {
  areacode: string
  batchNumberOfPieces: number
  clockSupplyLineId: number
  created?: string
  createdBy?: string | null
  id: number
  lastModified?: string | null
  lastModifiedBy?: string | null
  loadCarrierRowNumber: string
  loadCarrierRowSequenceNumber: string
}

export type BatchImage = {
  clockSupplyLineId: number
  created?: string
  createdBy?: string | null
  id: number
  lastModified?: string | null
  lastModifiedBy?: string | null
  sequence: number
  supplierImageId: string
}

type ClockSupplyStatus = {
  description: string
  id: string
  sequence: number
}

export type Product = {
  endDate: string
  productGroup: number
  productName: string
  startDate: string
  vbnProductCode: number
}

type Supplier = {
  clientNumber: number
  endDate: string
  name: string
  startDate: string
}

export type ClockSupplyLine = {
  auctionDate: string
  auctiongroupCode: number
  batchCharacteristics?: BatchCharacteristic[] | null
  batchImages?: BatchImage[] | null
  batchParts?: BatchPart[] | null
  batchReference?: string | null
  clockName: string
  clockSupplyStatus?: ClockSupplyStatus
  clockSupplyStatusId?: string | null
  courtesy: CourtesyCodes
  courtesyNote?: string | null
  created?: string
  createdBy?: string | null
  id: number
  lastModified?: string | null
  lastModifiedBy?: string | null
  numberOfLoadCarriers: number
  numberOfPieces: number
  piecesPerPackage: number
  product?: Product
  qualityGroupCode: string
  supplier?: Supplier
  supplierId: number
  supplyFormCharacter: string
  supplyFormCharacters: string[]
  supplyFormCode: string
  vbnPackageCode: number
  vbnProductCode: number
}

export enum BatchStatus {
  Approved = 'Approved',
  NotApproved = 'NotApproved',
  Skipped = 'Skipped',
}

export type BatchRef = {
  supplierId?: string
  formCode: string
  formCharacter: string
}

export type ChangeBatchCharacteristicParams = {
  batchCharacteristicId: number
  clockSupplyLineId: number
  valueCode: string
}

export type AddBatchCharacteristicParams = {
  characteristicCode: string
  clockSupplyLineId: number
  valueCode: string
}

export type RemoveBatchCharacteristicParams = {
  batchCharacteristicId: number
  clockSupplyLineId: number
}

export type ChangePiecesPerPackageParams = {
  clockSupplyLineId: number
  piecesPerPackage: number
}

export type ChangeCourtesyParams = {
  clockSupplyLineId: number
  courtesy: CourtesyCodes
  courtesyNote?: string
}
