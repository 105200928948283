import { observer } from 'mobx-react-lite'

import { Dispatch, FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { BatchNotSavedDialogActions } from 'src/components/batch/BatchNotSavedDialogActions'
import NotSavedYetIllustration from 'src/illustrations/RFH_illustratie_niet-opgeslagen.svg'
import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { BatchStatus } from 'src/types/ClockSupplyLine'

type BatchNotSavedDialogProps = {
  batchStatusHandler: Dispatch<BatchStatus>
  isOpen: boolean
}

export const BatchNotSavedDialog: FC<BatchNotSavedDialogProps> = observer(
  ({ batchStatusHandler, isOpen }) => {
    const { t } = useTranslation()
    const { setHotkeyActive } = useHotkeyStore()

    return (
      <Dialog
        open={isOpen}
        maxWidth='xs'
        onClose={() => batchStatusHandler(BatchStatus.Skipped)}
        TransitionProps={{
          onEntered: () => setHotkeyActive(false),
          onExited: () => setHotkeyActive(true),
        }}
      >
        <DialogContent>
          <Stack direction='column' gap={2}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              width='100%'
            >
              <Box mb={1.5}>
                <img
                  width='100%'
                  src={NotSavedYetIllustration}
                  alt='not saved yet illustration'
                />
              </Box>
              <Typography variant='h4'>
                {t('inspection.notSavedDialog.title')}
              </Typography>
            </Box>
            <Box>{t('inspection.notSavedDialog.content')}</Box>
            <Box
              borderBottom={1}
              sx={{
                borderColor: ({ rfhColors }) => rfhColors.grey[200],
              }}
              width='100%'
              mt={1.5}
            />
          </Stack>
        </DialogContent>
        <Box mb={4}>
          <BatchNotSavedDialogActions batchStatusHandler={batchStatusHandler} />
        </Box>
      </Dialog>
    )
  }
)
