import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Layout } from 'src/components/Layout'
import { EmptyState } from 'src/components/styles/EmptyState/EmptyState'
import { EmptyStateIcon } from 'src/components/styles/EmptyState/EmptyStateIcon'

export const NoPermission: FC = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Box mt={6}>
        <EmptyState
          bottomComponent={
            <Button
              href='https://portal.royalfloraholland.com/nl-NL/service/kennisbank/artikel/?id=KA-02434&categoryid=CAT-01159'
              variant='contained'
            >
              {t('noPermission.button')}
            </Button>
          }
          graphic={<EmptyStateIcon />}
          header={t('noPermission.header')}
          subheader={t('noPermission.subHeader')}
        />
      </Box>
    </Layout>
  )
}
