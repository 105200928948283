import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import type { InspectionRapportView } from 'src/types/InspectionReport'

import { QiClass } from './QiClass'

type DetailsBoxProps = {
  userData: InspectionRapportView
}

export const CreateReportDetails: FC<DetailsBoxProps> = ({ userData }) => {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        p: 5,
        position: 'sticky',
        top: 10,
      }}
    >
      <Typography variant='h4' pb={3}>
        {t('createReport.details')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Typography variant='body1'>{t('createReport.captain')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* istanbul ignore next  */}
          <Typography variant='body1' className='book'>
            {userData.supplierName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography variant='body1'>{t('createReport.captainNumber')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* istanbul ignore next  */}
          <Typography variant='body1' className='book'>
            {userData.supplierId}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography variant='body1'>{t('createReport.date')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant='body1' className='book'>
            {userData.inspectionFiles[0].clockSupplyLine.auctionDate
              .split('T')[0]
              .split('-')
              .reverse()
              .join('-')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography variant='body1'>{t('createReport.qiClass')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box sx={{ display: 'flex' }}>
            {/* {priorityLetters.map(priority => ( */}
            <QiClass
              // qiClasse is altijd hier hetzelfde want we werken met EEN dag
              key={userData.inspectionFiles[0].clockSupplyLine.qiClass}
              priority={
                userData.inspectionFiles[0].clockSupplyLine.qiClass === 'geen'
                  ? '-'
                  : /* istanbul ignore next */
                  userData.inspectionFiles[0].clockSupplyLine.qiClass
                  ? userData.inspectionFiles[0].clockSupplyLine.qiClass
                  : /* istanbul ignore next */
                    ''
              }
              qiClass={userData?.inspectionFiles[0].clockSupplyLine.qiClass ?? ''}
            />
            {/* ))} */}
          </Box>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography variant='body1'>{t('createReport.languageCode')}</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant='body1' className='book'>
              {userData.language || 'NL'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
