import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import { Command } from 'src/types/Command'
import {
  ChangeType,
  ObservationFindingOverview,
  ObservationFindingsSearchInput,
  Rate,
} from 'src/types/ObservationFindings'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'
import { getIsoDate } from 'src/utils/getIsoDate'

export enum LocationCode {
  Aalsmeer = 1,
  Naaldwijk = 2,
  Rijnsburg = 3,
  Eelde = 4,
}

export const getObservationFindings = (
  searchInput: ObservationFindingsSearchInput,
  top = 25,
  page = 0
): AxiosPromise<ODataResponse<ObservationFindingOverview[]>> => {
  const typeFilter = []

  if (searchInput.type?.includes(ChangeType.reclamatie)) {
    typeFilter.push(Rate.complaint)
  }
  if (searchInput.type?.includes(ChangeType.bevinding)) {
    typeFilter.push(...[Rate.low, Rate.middle, Rate.high, Rate.none])
  }

  const date = new Date()
  date.setHours(0, 0, 0, 0)

  const filter = {
    rate: searchInput.type?.length ? { in: typeFilter } : undefined,
    location: searchInput.location?.length
      ? {
          in: searchInput.location,
        }
      : undefined,

    supplierNumber: searchInput.supplier?.clientNumber
      ? { eq: Number(searchInput.supplier.clientNumber) }
      : undefined,

    productName: searchInput.product
      ? { contains: searchInput.product.toUpperCase() }
      : undefined,
    'tolower(approvers)': {
      contains: searchInput.approvedBy?.toLowerCase(),
    },
    plantType: searchInput.plantType?.length
      ? { in: searchInput.plantType }
      : undefined,
    transactionDate: searchInput.dateRange?.every(input => input)
      ? {
          ge: getIsoDate(searchInput.dateRange?.[0] as Date),
          le: getIsoDate(searchInput.dateRange?.[1] as Date),
        }
      : {
          eq: getIsoDate(date),
        },
  }

  const query = buildQuery<ObservationFindingOverview>({
    top,
    skip: page * top,
    orderBy: [
      ['transactionDate', 'desc'],
      ['supplierNumber', 'asc'],
      ['supplyFormCode', 'asc'],
      ['supplyFormCharacter', 'asc'],
    ],
    filter,
    count: true,
  })

  return api.get(`/odata/v1/ObservationFindingOverviews${query}`)
}

export const postObservationFindingsQiComponent = (
  row: ObservationFindingOverview,
  isQiComponent: boolean
): AxiosPromise<Command> =>
  api.post(`/api/v1/ObservationFindings/${row.id}/Commands/ChangeIsQiComponent`, {
    observationFindingId: row.id,
    isQiComponent,
    clockSupplyLineId: row.clockSupplyLineId,
  })
