import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'

export const CharacteristicsGridItem = styled(Grid)(() => ({
  '& .MuiAutocomplete-endAdornment': {
    right: '0px !important',
  },
  '& .MuiOutlinedInput-root, .MuiFilledInput-root': {
    height: '36px',
    padding: '7px !important',
  },
}))
