import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const ImageBox = styled(Box)<{ aspectRatio?: string }>(
  ({ theme, aspectRatio = '3 / 2' }) => ({
    aspectRatio,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.rfhColors.grey[100],
    display: 'flex',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  })
)
