import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { FormFields } from 'src/components/InspectionReportsFormProvider'
import { ObservationFindingOverview } from 'src/types/ObservationFindings'

import { StyledTableContainer } from './ObservationFindings.styles'
import { ObservationFindingsTableBody } from './ObservationFindingsTableBody'

type ObservationFindingsTableProps = {
  rows: ObservationFindingOverview[]
  loading: boolean
  tablePaginationProps: TablePaginationProps
}

export const ObservationFindingsTable: FC<ObservationFindingsTableProps> = observer(
  ({ rows, loading, tablePaginationProps }) => {
    const { t } = useTranslation()
    const form = useFormContext<FormFields>()

    return (
      <StyledTableContainer>
        <Table className='noZebra'>
          <TableHead>
            <TableRow>
              {form ? (
                <TableCell>
                  <Checkbox
                    inputProps={{ 'aria-label': t('inspectionReports.selectAll') }}
                    disabled={loading || rows.length === 0}
                    checked={
                      !!rows.length &&
                      rows.length === form.getValues('observationId').length
                    }
                    onChange={() => {
                      const isChecked =
                        rows.length === form.getValues('observationId').length
                      if (isChecked) {
                        form.setValue('observationId', [])
                      } else {
                        form.setValue(
                          'observationId',
                          rows.map(
                            ({ observationFindingId }) => observationFindingId + ''
                          )
                        )
                      }
                    }}
                    sx={{ svg: { fill: theme => theme.rfhColors.white } }}
                  />
                </TableCell>
              ) : (
                <TableCell />
              )}
              {Object.values(
                t('observationFindings.tableHeaders', { returnObjects: true })
              ).map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <ObservationFindingsTableBody loading={loading} rows={rows} />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{
                  '.MuiInputBase-root ': {
                    padding: ({ spacing }) => spacing(0, 2),
                    border: ({ rfhColors }) => `1px solid ${rfhColors.grey[400]}`,
                  },
                }}
                data-testid='observationFindingsTablePagination'
                {...tablePaginationProps}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    )
  }
)
