import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TableCell from '@mui/material/TableCell'
import { TablePaginationProps } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { FinanceSettlementOverview } from 'src/types/financeSettlement'

import { RefetchDataFunction } from './FinanceSettlementTable'
import { FinanceSettlementTableRow } from './FinanceSettlementTableRow'

type FinanceSettlementTableBodyProps = {
  loading: boolean
  rows: FinanceSettlementOverview[]
  tablePaginationProps: TablePaginationProps
  refetchData: RefetchDataFunction
}

const CenteredRow: FC<{ children: React.ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={100}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          my: 4,
        }}
      >
        {children}
      </Box>
    </TableCell>
  </TableRow>
)

export const FinanceSettlementTableBody: FC<FinanceSettlementTableBodyProps> = ({
  loading,
  rows,
  tablePaginationProps,
  refetchData,
}) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <CenteredRow>
        <CircularProgress aria-label={t('queryState.loading.label')} />
      </CenteredRow>
    )
  }

  if (rows.length === 0) {
    return (
      <CenteredRow>
        <Typography variant='body2'>{t('queryState.notFound.message')}</Typography>
      </CenteredRow>
    )
  }

  return (
    <>
      {rows.map(row => (
        <FinanceSettlementTableRow
          refetchData={refetchData}
          tablePaginationProps={tablePaginationProps}
          key={row.id}
          row={row}
        />
      ))}
    </>
  )
}
