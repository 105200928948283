import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Chip from '@mui/material/Chip'

export const Indicator: FC<{ value: boolean }> = ({ value }) => {
  const { t } = useTranslation()
  return (
    <Chip
      size='small'
      sx={{ fontWeight: 'bold' }}
      color={value ? 'info' : 'disabled'}
      label={value ? t('indicator.yes') : t('indicator.no')}
    />
  )
}
