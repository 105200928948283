import { OktaAuthOptions } from '@okta/okta-auth-js'

export const scopeDomainPrefix = 'qsi-kwaliteit-'
const redirectUri = `${window.location.origin}/callback`

const pipelineValues = {
  envValue: '#{OktaOmgeving}#',
  oktaAuthentication: '#{OktaAuthentication}#',
  apiHostValue: '#{ApiHost}#',
  clientIdValue: '#{OktaClientId}#',
  domainValue: '#{OktaDomain}#',
  issuerValue: '#{OktaIssuer}#',
  scopeValue: '#{OktaScope}#',
  sentryEnvironment: '#{Omgeving}#',
  beeldbankHostValue: '#{BeeldbankHost}#',
}

const localValues: any = {
  envValue: 'sys',
  oktaAuthentication: 'on',
  apiHostValue:
    'https://qsi-tc-qualityserviceinspection-api.commstaging.royalfloraholland.com',
  clientIdValue: '0oa3qu6lgo0gqaMjb0x7',
  domainValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
  issuerValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
  scopeValue: scopeDomainPrefix + 'api-',
  beeldbankHostValue: 'https://beeldbankfotos-sys.royalfloraholland.com',
}

type KeyType = keyof typeof pipelineValues

// The {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used.
Object.keys(pipelineValues).forEach(key => {
  if (pipelineValues[key as KeyType].startsWith('#')) {
    pipelineValues[key as KeyType] = localValues[key as KeyType]
  }
})

const oktaScopes = [
  'openid',
  'profile',
  'email',
  pipelineValues.scopeValue + pipelineValues.envValue,
]

export type AppConfig = {
  env: 'dev' | 'sys' | 'acc' | 'prd' | string
  oktaRoute: string
  api: {
    host: string
    path: string
    version: string
  }
  oidc: OktaAuthOptions
  beeldbankHostValue: string
}
export const Config: AppConfig = {
  env: pipelineValues.envValue,
  oktaRoute: pipelineValues.oktaAuthentication,
  api: {
    host: pipelineValues.apiHostValue,
    path: '',
    version: '',
  },
  oidc: {
    clientId: pipelineValues.clientIdValue,
    issuer: pipelineValues.issuerValue,
    redirectUri,
    scopes: oktaScopes,
    pkce: process.env.NODE_ENV === 'test' ? false : true,
    devMode: process.env.NODE_ENV === 'development' ? true : false,
  },
  beeldbankHostValue: pipelineValues.beeldbankHostValue,
}
