import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, ReactNode, useContext } from 'react'

import { ObservationFindingOverview } from 'src/types/ObservationFindings'

export interface TableStore {
  expandedRows: number[]
  rows: ObservationFindingOverview[]
  setRows: (rows: ObservationFindingOverview[]) => void
  toggleRowExpansion: (id: number) => void
  toggleAllRowsExpansion: () => void
}

export const TableStoreContext = createContext<TableStore | null>(null)

export const initialState: TableStore = {
  expandedRows: [],
  rows: [],
  setRows(rows) {
    this.rows = rows
  },
  toggleRowExpansion(id) {
    if (this.expandedRows.includes(id)) {
      this.expandedRows = this.expandedRows.filter(rowId => rowId !== id)
    } else {
      this.expandedRows = [...this.expandedRows, id]
    }
  },
  toggleAllRowsExpansion() {
    if (this.expandedRows.length > 0) {
      this.expandedRows = []
    } else {
      this.expandedRows = this.rows.map(row => row.id)
    }
  },
}

export const TableStoreProvider: FC<{ initial?: TableStore; children: ReactNode }> = ({
  children,
  initial = initialState,
}) => {
  const TableStore: TableStore = useLocalObservable(() => initial)

  return (
    <TableStoreContext.Provider value={TableStore}>
      {children}
    </TableStoreContext.Provider>
  )
}

export const useTableStore = (): TableStore => {
  const store = useContext(TableStoreContext)

  if (!store) {
    throw new Error('No table store defined')
  }

  return store
}
