export enum Code {
  Backspace = 'Backspace',
  Tab = 'Tab',
  Enter = 'Enter',
  ShiftLeft = 'ShiftLeft',
  ShiftRight = 'ShiftRight',
  CtrlLeft = 'ControlLeft',
  CtrlRight = 'ControlRight',
  AltLeft = 'AltLeft',
  AltRight = 'AltRight',
  PauseBreak = 'Pause',
  CapsLock = 'CapsLock',
  Escape = 'Escape',
  Space = 'Space',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  End = 'End',
  Home = 'Home',

  LeftArrow = 'ArrowLeft',
  UpArrow = 'ArrowUp',
  RightArrow = 'ArrowRight',
  DownArrow = 'ArrowDown',

  Insert = 'Insert',
  Delete = 'Delete',

  Zero = 'Digit0',
  // ClosedParen = Zero,
  One = 'Digit1',
  // ExclamationMark = One,
  Two = 'Digit2',
  // AtSign = Two,
  Three = 'Digit3',
  // PoundSign = Three,
  // Hash = PoundSign,
  Four = 'Digit4',
  // DollarSign = Four,
  Five = 'Digit5',
  // PercentSign = Five,
  Six = 'Digit6',
  // Caret = Six,
  // Hat = Caret,
  Seven = 'Digit7',
  // Ampersand = Seven,
  Eight = 'Digit8',
  Star = Eight,
  // Asterisk = Star,
  Nine = 'Digit9',
  // OpenParen = Nine,

  A = 'KeyA',
  B = 'KeyB',
  C = 'KeyC',
  D = 'KeyD',
  E = 'KeyE',
  F = 'KeyF',
  G = 'KeyG',
  H = 'KeyH',
  I = 'KeyI',
  J = 'KeyJ',
  K = 'KeyK',
  L = 'KeyL',
  M = 'KeyM',
  N = 'KeyN',
  O = 'KeyO',
  P = 'KeyP',
  Q = 'KeyQ',
  R = 'KeyR',
  S = 'KeyS',
  T = 'KeyT',
  U = 'KeyU',
  V = 'KeyV',
  W = 'KeyW',
  X = 'KeyX',
  Y = 'KeyY',
  Z = 'KeyZ',

  MetaLeft = 'MetaLeft',
  MetaRight = 'MetaRight',
  SelectKey = 'ContextMenu',

  Numpad0 = 'Numpad0',
  Numpad1 = 'Numpad1',
  Numpad2 = 'Numpad2',
  Numpad3 = 'Numpad3',
  Numpad4 = 'Numpad4',
  Numpad5 = 'Numpad5',
  Numpad6 = 'Numpad6',
  Numpad7 = 'Numpad7',
  Numpad8 = 'Numpad8',
  Numpad9 = 'Numpad9',
  NumpadEnter = 'NumpadEnter',

  Multiply = 'NumpadMultiply',
  Add = 'NumpadAdd',
  Subtract = 'NumpadSubtract',
  DecimalPoint = 'NumpadDecimal',
  Divide = 'NumpadDivide',
  NumpadEqual = 'NumpadEqual',

  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F6 = 'F6',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F10 = 'F10',
  F11 = 'F11',
  F12 = 'F12',
  F13 = 'F13',
  F14 = 'F14',
  F15 = 'F15',
  F16 = 'F16',
  F17 = 'F17',
  F18 = 'F18',
  F19 = 'F19',
  F20 = 'F20',

  NumLock = 'NumLock',
  ScrollLock = 'ScrollLock',

  SemiColon = 'Semicolon',
  Equals = 'Equal',
  Comma = 'Comma',
  Dash = 'Minus',
  Period = 'Period',
  // UnderScore = Dash,
  // PlusSign = Equals,
  Slash = 'Slash',
  Backslash = 'Backslash',
  Backquote = 'Backquote',
  IntlBackslash = 'IntlBackslash',
  // GraveAccent = Tilde,

  BracketLeft = 'BracketLeft',
  BracketRight = 'BracketRight',
  Quote = 'Quote',
  NonExistingKey = '',
}
