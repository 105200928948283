import TableContainer from '@mui/material/TableContainer'
import { styled } from '@mui/material/styles'

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    fontSize: '16px',
    padding: theme.spacing(1.5, 0, 1.5, 3),
  },
  '& .MuiTableCell-root:first-child': {
    width: '15%',
  },
  '& .MuiTableCell-root:nth-child(2)': {
    width: '25%',
  },
  '& .MuiTableCell-root:nth-child(3)': {
    width: '40%',
  },
  '& .MuiTableCell-root:nth-child(4)': {
    width: '20%',
  },
  '&:first-of-type': {
    padding: theme.spacing(0, 2),
  },
}))

export const StyledTableContainerCharacteristics = styled(TableContainer)(
  ({ theme }) => ({
    '& .MuiTableCell-root': {
      fontSize: '16px',
      padding: theme.spacing(1.5, 0, 1.5, 3),
    },
    '& .MuiTableCell-root:first-child': {
      width: '30px',
    },
    '& .MuiTableCell-root:nth-child(2)': {
      width: '15%',
    },
    '& .MuiTableCell-root:nth-child(3)': {
      width: '55%',
    },
    '& .MuiTableCell-root:nth-child(4)': {
      width: '15%',
      textAlign: 'right',
    },
    '&:first-of-type': {
      padding: theme.spacing(0, 2),
    },
  })
)

export const StyledTablePartyIdContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    verticalAlign: 'baseline',
    fontSize: '16px',
    padding: theme.spacing(1.5, 3, 1.5, 3),
  },
}))
