import { useQuery } from '@tanstack/react-query'

import { queryBatch } from 'src/services/batchService'

/** Similar to the useBatch hook, but leverages the power of react-query for all async state management */
export const useBatchQuery = (code: string) =>
  useQuery({
    queryKey: ['batch', code],
    enabled: code !== '',
    staleTime: 2 * 60 * 1000,
    select: ({ data }) => data,
    queryFn: () => queryBatch(code),
  })
