import isToday from 'date-fns/isToday'
import { observer } from 'mobx-react-lite'

import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Layout } from 'src/components/Layout'
import { BatchInput } from 'src/components/batch/BatchInput'
import { BatchNotSavedDialog } from 'src/components/batch/BatchNotSavedDialog'
import { BatchResult } from 'src/components/batch/BatchResult'
import { BatchResultActions } from 'src/components/batch/BatchResultActions'
import { BatchSavedDialog } from 'src/components/batch/BatchSavedDialog'
import { AuctionDateDialog } from 'src/components/dialogs/AuctionDateDialog'
import { useAddBatchStatus } from 'src/hooks/useAddBatchStatus'
import { useBarcodeScanner } from 'src/hooks/useBarcodeScanner'
import { useBatch } from 'src/hooks/useBatch'
import { useHotkey } from 'src/hooks/useHotkey'
import { useMutate } from 'src/hooks/useMutate'
import { useBatchStatusStore } from 'src/stores/BatchStatusStore'
import { BatchStatus } from 'src/types/ClockSupplyLine'
import { Code } from 'src/types/Code'
import { validateInput } from 'src/utils/validateInput'

import { Feature, useFeatureFlag } from '../hooks/useFeatureFlag'

type InspectionDetailsParams = {
  id: string
}
export const gridSpacing = 4

export const InspectionDetails: FC = observer(() => {
  const navigate = useNavigate()
  useBarcodeScanner()

  const { id: batchRef } = useParams<InspectionDetailsParams>()
  const { batch, loading, apiError, getBatchAsync } = useBatch()
  const { addBatchStatusAsync } = useAddBatchStatus()
  const { previousBatchStatus, previousBatchRef, isBatchStatusSaved } =
    useBatchStatusStore()

  const { setMutating } = useMutate()

  const [isAuctionDateDialogOpen, setIsAuctionDateDialogOpen] = useState<boolean>(false)
  const [isNotSavedDialogOpen, setIsNotSavedDialogOpen] = useState<boolean>(false)
  const [isSavedDialogOpen, setIsSavedDialogOpen] = useState<boolean>(false)

  const [searchedBatchRef, setSearchedBatchRef] = useState<string>()

  useEffect(() => {
    if (batch && !isToday(new Date(batch.auctionDate))) {
      setIsAuctionDateDialogOpen(true)
    }
  }, [batch])

  useEffect(() => {
    const searchAsync = async (ref: string) => {
      await getBatchAsync(ref)
      setSearchedBatchRef(ref)
    }

    if (batchRef && validateInput(batchRef)) {
      searchAsync(batchRef)
    }
  }, [batchRef])

  useEffect(() => {
    // checks if previous batch status is not skipped or undefined
    const isRealStatus =
      previousBatchStatus === BatchStatus.Approved ||
      previousBatchStatus === BatchStatus.NotApproved

    // checks if next batch search input is available
    const isNextBatch = previousBatchRef !== searchedBatchRef

    if (isBatchStatusSaved && isRealStatus && !isNextBatch) {
      setIsSavedDialogOpen(true)
    }
  }, [isBatchStatusSaved])

  const handleBatchSearch = (input: string): void => {
    if (!input || input.length === 0 || input === previousBatchRef) {
      return
    }

    if (batch) {
      if (previousBatchStatus) {
        navigate(`/inspection/${input}`)
      } else {
        setIsNotSavedDialogOpen(true)
      }
    } else {
      navigate(`/inspection/${input}`)
    }
    setSearchedBatchRef(input)
  }

  const isPartijOpvragenSchermMuterenEnabled = useFeatureFlag(
    Feature.PartijOpvragenSchermMuteren
  )
  const batchStatusHandler = async (status: BatchStatus) => {
    if (batch) {
      setIsNotSavedDialogOpen(false)
      await addBatchStatusAsync(status, batch.id)
      navigate(`/inspection/${searchedBatchRef}`)
    }
  }

  useHotkey(Code.RightArrow, () => batchStatusHandler(BatchStatus.Approved))

  const batchStatusHandlerAction = async (status: BatchStatus) => {
    await addBatchStatusAsync(status, batch!.id)
    setMutating(false)
  }

  return (
    <Layout>
      <Box my={6}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} md={6}>
            <BatchInput
              handleBatchSearch={handleBatchSearch}
              batchRef={batchRef}
              batch={batch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {batch && isPartijOpvragenSchermMuterenEnabled && (
              <BatchResultActions
                batchStatusHandler={batchStatusHandlerAction}
                setMutating={setMutating}
              />
            )}
          </Grid>

          <BatchResult batch={batch} loading={loading} error={apiError} />
          <AuctionDateDialog
            isOpen={isAuctionDateDialogOpen}
            handleClose={() => setIsAuctionDateDialogOpen(false)}
          />
          <BatchNotSavedDialog
            isOpen={isNotSavedDialogOpen}
            batchStatusHandler={batchStatusHandler}
          />
          <BatchSavedDialog
            isOpen={isSavedDialogOpen}
            setIsOpen={setIsSavedDialogOpen}
            handleBatchSearch={handleBatchSearch}
          />
        </Grid>
      </Box>
    </Layout>
  )
})
