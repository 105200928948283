/* istanbul ignore file */
import noop from 'lodash.noop'

import { FC, useMemo, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { debounce } from '@mui/material/utils'
import { useQuery } from '@tanstack/react-query'

import { getSuppliers } from 'src/services/supplierService'
import { Supplier } from 'src/types/Supplier'

import { ClearAdornment } from './ClearAdornment'

type SupplierAutocompleteProps = Omit<
  AutocompleteProps<Supplier, false, true, false>,
  'renderInput' | 'options'
> & {
  controlProps?: ControllerRenderProps<any, any>
  error?: boolean
  label?: string
  placeholder?: string
  handleClear?: () => void
}

export const SupplierAutocomplete: FC<SupplierAutocompleteProps> = ({
  controlProps,
  error = false,
  label,
  placeholder,
  handleClear = noop,
  ...props
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const handleSearchQuery = useMemo(() => debounce(setSearchQuery, 300), [])

  const { data: result, isLoading } = useQuery({
    queryKey: ['supplier', searchQuery],
    queryFn: () => getSuppliers(searchQuery),
  })

  return (
    <Autocomplete
      filterOptions={x => x}
      {...props}
      {...controlProps}
      forcePopupIcon={false}
      disableClearable
      onChange={(_event, item) => controlProps?.onChange && controlProps.onChange(item)}
      options={result?.data.value ?? []}
      getOptionLabel={option =>
        typeof option === 'object' ? `${option.clientNumber}: ${option.name}` : ''
      }
      loading={isLoading}
      renderInput={renderInputProps => (
        <TextField
          {...renderInputProps}
          error={error}
          InputProps={{
            ...renderInputProps.InputProps,
            endAdornment: <ClearAdornment onClick={handleClear} />,
          }}
          InputLabelProps={{ required: true }}
          onChange={event => handleSearchQuery(event.target.value)}
          fullWidth
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}
