import { FC } from 'react'

import Box from '@mui/material/Box'
import RfhColors from '@rfh-core/theme'

type QiIndicatorProps = {
  priority: string
  qiClass: string
}
export const QiClass: FC<QiIndicatorProps> = ({ priority, qiClass }) => (
  <Box
    aria-label='qi-class-badge'
    sx={{
      backgroundColor:
        priority === qiClass
          ? RfhColors.rfhColors.royalPurpleShade
          : RfhColors.rfhColors.grey[100],
      border: '1px solid',
      borderColor:
        priority === qiClass
          ? RfhColors.rfhColors.royalPurple
          : RfhColors.rfhColors.grey[800],
      borderRadius: '15px',
      marginRight: 1,
      height: '30px',
      width: '30px',
      fontSize: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {priority}
  </Box>
)
