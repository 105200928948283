import { resizeDetail } from './clockPhoto/resizeDetail'

// The resize file is omitted from the test coverage because it
// makes nog sense to check pixel content in a unit test
// And is would require us to make a fake implementation of URL.createObjectURL
// https://github.com/jsdom/jsdom/issues/1721

const loadImage = async (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#security_and_tainted_canvases
    img.crossOrigin = 'Anonymous'
    if (src.includes('blob:')) {
      img.src = src
    } else {
      // waarom deze rare cahce header?
      // https://stackoverflow.com/questions/44865121/canvas-tainted-by-cors-data-and-s3
      ///https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
      img.src = src + '?1=1'
    }

    img.onload = () => {
      URL.revokeObjectURL(src)
      resolve(img)
    }

    img.onerror = () => reject('Could not load image')
  })

/** Returns the resized image as a base64 string */
export const resize = async (imageSource: string, maxSize: number) => {
  const image = await loadImage(imageSource)

  const factor = Math.max(image.width, image.height) / maxSize
  const canvas = document.createElement('canvas')
  canvas.width = image.width / factor
  canvas.height = image.height / factor

  const context = canvas.getContext('2d')
  context?.drawImage(image, 0, 0, canvas.width, canvas.height)

  return canvas.toDataURL('image/jpeg')
}

export const addDetailPhoto = async (mainImage: string, detailImage: string) => {
  const main = await loadImage(mainImage)
  const detail = await loadImage(detailImage)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = main.width
  canvas.height = main.height

  context?.drawImage(main, 0, 0, canvas.width, canvas.height)
  context?.drawImage(detail, ...resizeDetail(main, detail))

  return canvas.toDataURL('image/jpeg')
}

export const degreesToRadian = (degrees: number) => (degrees * Math.PI) / 180

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, degrees: number) {
  // Dit artikel legt uit hoe je de nieuwe bounding box van een geroteerde rechthoek berekent
  // https://math.stackexchange.com/questions/4001034/calculate-the-dimensions-of-a-rotated-rectangle-inside-a-bounding-box
  const radian = degreesToRadian(degrees)
  return {
    width: Math.abs(Math.cos(radian) * width) + Math.abs(Math.sin(radian) * height),
    height: Math.abs(Math.sin(radian) * width) + Math.abs(Math.cos(radian) * height),
  }
}

type Options = {
  backgroundColor: string
  rotation: number
  cropArea: {
    width: number
    height: number
    x: number
    y: number
  }
}

export const modifyImage = async (
  mainImage: string,
  { cropArea, backgroundColor, rotation }: Options
) => {
  const image = await loadImage(mainImage)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) {
    throw new Error('Could not create canvas context')
  }

  // calculate bounding box of the rotated image
  const { width: boundingWidth, height: boundingHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = boundingWidth
  canvas.height = boundingHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  context.translate(canvas.width / 2, canvas.height / 2)
  context.rotate(degreesToRadian(rotation))
  context.translate(-image.width / 2, -image.height / 2)
  context.drawImage(image, 0, 0)

  // create a new canvas to crop the image
  const croppedCanvas = document.createElement('canvas')
  const croppedContext = croppedCanvas.getContext('2d')
  if (!croppedContext) {
    throw new Error('Could not create canvas context')
  }

  croppedCanvas.height = cropArea.height
  croppedCanvas.width = cropArea.width
  croppedContext.fillStyle = backgroundColor
  croppedContext.fillRect(0, 0, croppedCanvas.width, croppedCanvas.height)
  croppedContext.drawImage(
    canvas,
    cropArea.x,
    cropArea.y,
    cropArea.width,
    cropArea.height,
    0,
    0,
    cropArea.width,
    cropArea.height
  )

  return croppedCanvas.toDataURL('image/jpeg')
}
