import { FC } from 'react'

import Popper, { PopperProps } from '@mui/material/Popper'

export const AutocompletePopper: FC<PopperProps> = props => (
  <Popper
    {...props}
    placement='bottom-start'
    style={{
      maxWidth: 'fit-content',
    }}
  />
)
