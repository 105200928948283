import { ReactElement, useCallback, useEffect, useRef } from 'react'
import Webcam from 'react-webcam'

import { BrowserMultiFormatReader } from '@zxing/browser'
import { Result, BarcodeFormat, DecodeHintType } from '@zxing/library'

const enabledFormats = [
  BarcodeFormat.QR_CODE,
  BarcodeFormat.EAN_13,
  BarcodeFormat.EAN_8,
]

// This component is set up in such a way that it contains the least (business) logic.
// This is purely and simply the integration of two libraries (webcam + zxing).
// For this reason we are excluding this file from code coverage.
export const Scanner = ({
  onUpdate,
  width = '100%',
  height = '100%',
}: {
  onUpdate: (result: Result) => void
  width?: number | string
  height?: number | string
}): ReactElement => {
  const webcamRef = useRef<Webcam>(null)

  const capture = useCallback(() => {
    const hints = new Map()
    hints.set(DecodeHintType.POSSIBLE_FORMATS, enabledFormats)
    const reader = new BrowserMultiFormatReader(hints)
    const screenshot = webcamRef?.current?.getScreenshot()

    if (screenshot) {
      reader
        .decodeFromImageUrl(screenshot)
        .then(result => {
          if (result) {
            onUpdate(result)
          }
        })
        .catch(() => {
          // no-op
        })
    }
  }, [onUpdate])

  useEffect(() => {
    const interval = setInterval(capture, 500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Webcam
      width={width}
      height={height}
      ref={webcamRef}
      screenshotFormat='image/jpeg'
      videoConstraints={{
        facingMode: 'environment',
      }}
      audio={false}
    />
  )
}
export default Scanner
