import { useEffect } from 'react'

import { useBarcodeStore } from 'src/stores/BarcodeStore'

type useBarcodeScannerReturntype = {
  initializeScanner: (event: KeyboardEvent) => void
}
export const useBarcodeScanner = (): useBarcodeScannerReturntype => {
  const { isBarcodeScannerDisabled } = useBarcodeStore()

  const initializeScanner = (event: KeyboardEvent) => {
    const { tagName } = event.target as HTMLElement
    const input = Array.from(
      document.querySelectorAll('.batchRefInputField')
    ).pop() as HTMLInputElement

    /* istanbul ignore next */
    if (event.key === '[') {
      input.value = ''
    }
    /* istanbul ignore next */
    if (tagName !== 'INPUT' && event.key !== 'Enter') {
      input.focus()
      input.value = event.key
      event.preventDefault()
    }
  }

  useEffect(() => {
    !isBarcodeScannerDisabled &&
      document.addEventListener('keypress', initializeScanner)
    return () => {
      document.removeEventListener('keypress', initializeScanner)
    }
  }, [isBarcodeScannerDisabled])

  return { initializeScanner }
}
