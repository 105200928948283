import { FC, forwardRef, ReactElement, Ref, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import type { TransitionProps } from '@mui/material/transitions'
import {
  DataGrid,
  GridCellValue,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  nlNL,
} from '@mui/x-data-grid'

import { useObservationsReport } from 'src/hooks/useObservationsReport'
import { useBarcodeStore } from 'src/stores/BarcodeStore'
import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { Observation } from 'src/types/ObservationsReport'
import { getFormattedDate } from 'src/utils/format'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement
    },
    ref: Ref<unknown>
  ) => <Slide direction='up' ref={ref} {...props} />
)

export type ObservationsReportDialogProps = {
  open: boolean
  supplierId?: number
  handleClose: () => void
}

export const PageSizeOptions = [5, 10, 20, 50, 100]

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton
      placeholder=''
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    />
    <GridToolbarFilterButton
      placeholder=''
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    />
    <GridToolbarExport />
  </GridToolbarContainer>
)

export const ObservationsReportDialog: FC<ObservationsReportDialogProps> = ({
  open,
  supplierId,
  handleClose,
}) => {
  const { t } = useTranslation()
  const { setHotkeyActive } = useHotkeyStore()
  const { setIsBarcodeScannerDisabled } = useBarcodeStore()

  const [pageSize, setPageSize] = useState<(typeof PageSizeOptions)[number]>(20)

  const {
    loading,
    observationsReport,
    getObservationsReportAsync,
    setObservationsReport,
  } = useObservationsReport()

  const rows = useMemo<Observation[]>(
    () =>
      observationsReport?.observations.response?.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
    [observationsReport]
  )

  useEffect(() => {
    setIsBarcodeScannerDisabled(open)

    if (open && supplierId) {
      getObservationsReportAsync(supplierId)
    }
  }, [open])

  const columns: GridColumns = [
    {
      field: 'lotAuctionDate',
      headerName: t('findings.columnHeaders.auctionDate'),
      flex: 2,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
        getFormattedDate(params.value as string),
    },
    {
      field: 'deliveryNote',
      headerName: t('findings.columnHeaders.deliveryNote'),
      flex: 2,
    },
    {
      field: 'productCode',
      headerName: t('findings.columnHeaders.productCode'),
      flex: 2,
    },
    {
      field: 'productClockName',
      headerName: t('findings.columnHeaders.productClockName'),
      flex: 4,
    },
    { field: 'location', headerName: t('findings.columnHeaders.location'), flex: 1 },
    {
      field: 'observationTypeDescription',
      headerName: t('findings.columnHeaders.observationType'),
      flex: 2,
    },
    {
      field: 'observationCategoryDescription',
      headerName: t('findings.columnHeaders.observationCategory'),
      flex: 4,
    },
    {
      field: 'offeredLotValue',
      headerName: t('findings.columnHeaders.declaredAs'),
      flex: 2,
    },
    {
      field: 'transactionLotValue',
      headerName: t('findings.columnHeaders.auctionedWith'),
      flex: 2,
    },
    {
      field: 'correctionLotValue',
      headerName: t('findings.columnHeaders.correctedTo'),
      flex: 2,
    },
  ]

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onEntered: () => setHotkeyActive(false),
        onExited: () => {
          setObservationsReport(undefined)
          setHotkeyActive(true)
        },
      }}
      TransitionComponent={Transition}
    >
      <AppBar color='default' position='relative' elevation={0}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label={t('findings.closeDialog')}
            >
              <CloseIcon />
            </IconButton>
            <Box
              width='100%'
              display='flex'
              alignItems='center'
              fontWeight='bold'
              ml={2}
            >
              {t('findings.historyTitle')}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth='xl'>
        <Box mt={4} mb={2}>
          <Typography variant='h3' style={{ fontWeight: 'bold' }}>
            {t('findings.historyPageTitle')}
          </Typography>
        </Box>
        <Box height='70vh'>
          <DataGrid
            localeText={{ ...nlNL.components.MuiDataGrid.defaultProps.localeText }}
            autoHeight
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={observationsReport?.observations.count ?? 0}
            rowsPerPageOptions={PageSizeOptions}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Container>
    </Dialog>
  )
}
