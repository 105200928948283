import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

export const BatchFotoCard: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card
      className='CardPrimary'
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <CardMedia component='img' image='/assets/images/qualityOfficer.jpeg' />
      <CardContent sx={{ flex: 1 }}>
        <Box>
          <Typography variant='h4' sx={{ pb: 2 }}>
            {t('home.batchFoto.title')}
          </Typography>
        </Box>
        <CardActions sx={{ marginTop: 'auto' }}>
          <Button onClick={() => navigate('/batch')} fullWidth variant='contained'>
            {t('home.batchFoto.buttonText')}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}
