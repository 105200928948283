/* istanbul ignore file */
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import debounce from 'lodash.debounce'

import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { InformationSection } from 'src/components/InformationSection'
import { LocationsRow } from 'src/components/LocationsRow'
import { CourtesyRow } from 'src/components/courtesy/CourtesyRow'
import { ChangeProductDialog } from 'src/components/dialogs/ChangeProductDialog'
import { ObservationsReportDialog } from 'src/components/dialogs/ObservationsReportDialog'
import { StyledTablePartyIdContainer } from 'src/components/styles/characteristics.styles'
import { useMutate } from 'src/hooks/useMutate'
import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import {
  changePiecesPerPackage,
  getBatchCharacteristics,
} from 'src/services/batchService'
import { ClockSupplyLine, Product } from 'src/types/ClockSupplyLine'
import { ApiError, badRequestCode } from 'src/types/StatusCodes'
import { gridSpacing } from 'src/views/InspectionDetails'

import { BatchImage } from './BatchImage'

export type BatchResultProps = {
  loading: boolean
  batch?: ClockSupplyLine
  error?: ApiError
}

export const BatchResult: FC<BatchResultProps> = ({ batch, error, loading }) => {
  const { t } = useTranslation()
  const { mutatingActive, setMutating } = useMutate()
  const [changeProductDailogOpen, setChangeProductDialogOpen] = useState(false)
  const [mutatedBatch, setMutatedBatch] = useState<ClockSupplyLine | undefined>(batch)
  const [apeValue, setApeValue] = useState<string>('')
  const [isObservationsDialogOpen, setIsObservationsDialogOpen] =
    useState<boolean>(false)
  const { offline } = useOnlineOffline()

  const [changeMutationError, setChangeMutationError] = useState<boolean>(false)
  const [generalError, setGeneralError] = useState<boolean>(false)
  const [mutationLoading, setMutationLoading] = useState(false)

  const [validationError, setValidationError] = useState<boolean>(false)

  const handlePiecesPerPackageChange = async (value: string) => {
    setMutationLoading(true)

    const params = {
      clockSupplyLineId: Number(batch!.id),
      piecesPerPackage: Number(value),
    }
    try {
      await changePiecesPerPackage(params, Number(batch!.id))
    } catch (apiError) {
      if ((apiError as AxiosError).response?.status === badRequestCode) {
        setChangeMutationError(true)
      } else {
        setGeneralError(true)
      }
    } finally {
      setMutationLoading(false)
    }
  }

  const debounceFn = useCallback(debounce(handlePiecesPerPackageChange, 1000), [batch])

  const debounceChangeHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const validationRegex = /^\d+$/
    const isValid = validationRegex.test(event.target.value)
    setApeValue(event.target.value)
    setValidationError(!isValid)
    setChangeMutationError(false)
    setGeneralError(false)

    if (isValid) {
      setMutatedBatch({
        ...mutatedBatch!,
        piecesPerPackage: Number(event.target.value),
      })
      debounceFn(event.target.value)
    }
  }

  useEffect(() => {
    if (batch) {
      setMutating(false)
      setMutatedBatch(batch)
      setApeValue(batch.piecesPerPackage.toString())
    }
  }, [batch])

  const handleProductChange = async (product: Product) => {
    const { data: batchCharacteristics } = await getBatchCharacteristics(
      mutatedBatch!.id
    )

    setMutatedBatch(currentBatch => ({
      ...currentBatch!,
      batchCharacteristics,
      product,
    }))
  }

  const piecesPerPackageCell = mutationLoading ? (
    <Box height='36px'>
      <CircularProgress aria-label={t('queryState.loading.label')} />
    </Box>
  ) : (
    <TextField
      value={apeValue}
      onChange={debounceChangeHandler}
      error={changeMutationError || validationError}
      helperText={
        (changeMutationError || validationError) && t('queryState.badRequest.message')
      }
      autoFocus={true}
      inputProps={{
        'aria-label': 'ape',
        inputMode: 'numeric',
        pattern: '[0-9]{1,4}',
        maxLength: 4,
      }}
    />
  )

  if (loading) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress aria-label={t('queryState.loading.label')} />
        <Typography>{t('queryState.loading.label')}</Typography>
      </Box>
    )
  }

  if (error) {
    const title = t(`queryState.${error}.title`)
    const message = t(`queryState.${error}.message`)

    return (
      <Grid item xs={12}>
        {/* MessageCard van Rfh-ui */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            bgcolor: ({ rfhColors }) => rfhColors.white,
            color: ({ rfhColors }) => rfhColors.black,
            p: [2, 2, 3],
          }}
        >
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='body1'>{message}</Typography>
        </Box>
      </Grid>
    )
  }

  return mutatedBatch ? (
    <>
      <Grid item xs={12} md={8}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <StyledTablePartyIdContainer>
              <Table
                className='noZebra'
                sx={{
                  // maxWidth: '312px',
                  border: ({ rfhColors }) => `1px solid ${rfhColors.grey[200]}`,
                  '.MuiTableCell-root': {
                    border: 'none',
                  },
                  '.MuiTableBody-root': {
                    border: 'none',
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: ({ rfhColors }) => rfhColors.black,
                        backgroundColor: ({ rfhColors }) => rfhColors.white,
                      }}
                      colSpan={2}
                    >
                      <Typography variant='body1' py={2}>
                        {t('inspection.batchInformation.partyInformation')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.supplier')}
                    </TableCell>
                    <TableCell sx={{ textOverflow: 'ellipsis' }}>
                      <Box display='flex' alignItems='center'>
                        {[
                          mutatedBatch?.supplier?.clientNumber,
                          batch?.supplier?.name,
                        ].join(' ')}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.product')}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {mutatedBatch?.product?.productName || ''}
                      {mutatingActive && (
                        <IconButton
                          sx={{
                            svg: {
                              width: '16px',
                              height: '16px',
                            },
                          }}
                          aria-label={t('inspection.batchActions.changeProduct')}
                          onClick={() => {
                            setChangeProductDialogOpen(true)
                          }}
                          className='outlined'
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.productCode')}
                    </TableCell>
                    <TableCell>{mutatedBatch?.product?.vbnProductCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.packageCode')}
                    </TableCell>
                    <TableCell>{mutatedBatch?.vbnPackageCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.auctionDate')}
                    </TableCell>
                    <TableCell>
                      {format(new Date(mutatedBatch.auctionDate), 'dd-MM')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.status')}
                    </TableCell>
                    <TableCell>
                      {mutatedBatch?.clockSupplyStatus?.description || ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.auctionGroup')}
                    </TableCell>
                    <TableCell>{mutatedBatch?.auctiongroupCode}</TableCell>
                  </TableRow>
                  {/* Onderstaande KVV staat nu niet in de api, dit is een toekomstige wens */}
                  {/* <TableRow>
                  <TableCell component='th'>{t('inspection.batchInformation.KVV')}</TableCell>
                  <TableCell>{mutatedBatch?.klokVoorverkoop}</TableCell>
                </TableRow> */}
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.loadCarrier')}
                    </TableCell>
                    <TableCell>{mutatedBatch?.numberOfLoadCarriers}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.piecesPerLoadCarrier')}
                    </TableCell>
                    <TableCell>
                      {batch?.numberOfLoadCarriers === 0
                        ? batch?.numberOfPieces
                        : Math.round(
                            mutatedBatch.numberOfPieces /
                              mutatedBatch.numberOfLoadCarriers
                          )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th'>
                      {t('inspection.batchInformation.piecesPerPackage')}
                    </TableCell>
                    <TableCell>
                      {mutatingActive
                        ? piecesPerPackageCell
                        : mutatedBatch?.piecesPerPackage}
                    </TableCell>
                  </TableRow>

                  <LocationsRow batch={batch} />

                  <Divider component='tr' />
                  <CourtesyRow disabled={!mutatingActive} batch={mutatedBatch} />
                  {mutatedBatch && !error && (
                    <>
                      <Divider component='tr' />
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Box sx={{ pb: 2 }}>
                            <Button
                              sx={{ padding: 0 }}
                              variant='text'
                              disabled={offline || loading}
                              onClick={() => setIsObservationsDialogOpen(true)}
                            >
                              {t('findings.buttonText')}
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </StyledTablePartyIdContainer>
            {generalError && (
              <FormHelperText error>
                {t('queryState.generalFailureMutation.message')}
              </FormHelperText>
            )}
          </Grid>

          <InformationSection batch={mutatedBatch} setMutatedBatch={setMutatedBatch} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <BatchImage
          batch={mutatedBatch}
          isMutating={mutatingActive}
          updateMutatedBatch={setMutatedBatch}
        />
      </Grid>
      <ChangeProductDialog
        clockSupplyLineId={mutatedBatch.id}
        open={changeProductDailogOpen}
        onProductChange={handleProductChange}
        onClose={() => {
          setChangeProductDialogOpen(false)
        }}
      />
      <ObservationsReportDialog
        supplierId={batch?.supplierId}
        open={isObservationsDialogOpen}
        handleClose={() => setIsObservationsDialogOpen(false)}
      />
    </>
  ) : null
}
