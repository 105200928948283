import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import { ObservationFindingOverview } from 'src/types/ObservationFindings'

import { ObservationFindingsTableRow } from './ObservationFindingsTableRow'

type ObservationFindingsTableBodyProps = {
  loading: boolean
  rows: ObservationFindingOverview[]
}

const CenteredRow: FC<{ children: React.ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={100}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          my: 4,
        }}
      >
        {children}
      </Box>
    </TableCell>
  </TableRow>
)

export const ObservationFindingsTableBody: FC<ObservationFindingsTableBodyProps> = ({
  loading,
  rows,
}) => {
  const { t } = useTranslation()

  if (loading) {
    return (
      <CenteredRow>
        <CircularProgress aria-label={t('queryState.loading.label')} />
      </CenteredRow>
    )
  }

  if (rows.length === 0) {
    return (
      <CenteredRow>
        <Typography variant='body2'>{t('queryState.notFound.message')}</Typography>
      </CenteredRow>
    )
  }

  return (
    <>
      {rows.map(row => (
        <ObservationFindingsTableRow key={row.id} row={row} />
      ))}
    </>
  )
}
