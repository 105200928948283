import { AxiosError } from 'axios'

import { useState } from 'react'

import { addBatchStatus } from 'src/services/batchService'
import { useBatchStatusStore } from 'src/stores/BatchStatusStore'
import { BatchStatus } from 'src/types/ClockSupplyLine'

type useAddBatchStatusReturnType = {
  loading: boolean
  error?: AxiosError
  addBatchStatusAsync: (batchStatus: BatchStatus, id: number) => Promise<void>
}

export const useAddBatchStatus = (): useAddBatchStatusReturnType => {
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState<AxiosError>()

  const { setBatchStatus, setIsBatchStatusSaved } = useBatchStatusStore()

  const addBatchStatusAsync = async (batchStatus: BatchStatus, id: number) => {
    setLoading(true)
    setIsBatchStatusSaved(false)
    try {
      await addBatchStatus(batchStatus, id)
      setBatchStatus(batchStatus)
      setApiError(undefined)
      setIsBatchStatusSaved(true)
    } catch (error) {
      setApiError(error as AxiosError)
    } finally {
      setLoading(false)
    }
  }

  return { loading, error: apiError, addBatchStatusAsync }
}
