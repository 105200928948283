import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  ChevronDownSm,
  ChevronLeftSm,
  ChevronRightSm,
  ChevronUpSm,
} from '@rfh-core/icons'

export const DRAWER_WIDTH = '270px'
export const DRAWER_BAR_WIDTH = '48px'

export const FilterContext = createContext<{
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}>({ open: false, setOpen: () => null })

export const FilterDrawerContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <FilterContext.Provider value={{ open, setOpen }}>
      {children}
    </FilterContext.Provider>
  )
}

export const filterDrawerTransition =
  (open: boolean) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ({ transitions }: Theme) =>
    transitions.create('all', {
      duration: open
        ? transitions.duration.leavingScreen
        : transitions.duration.enteringScreen,
    })

export const FilterDrawerContainer: FC<{
  enabled?: boolean
  children: ReactNode
  minHeight?: string
}> = ({ enabled, children, minHeight = 'calc(100vh - 54px)' }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))
  const { open, setOpen } = useContext(FilterContext)

  useEffect(() => {
    setOpen(enabled!)
    return () => setOpen(enabled!) // reset on unmount
  }, [enabled])

  return (
    <Box
      sx={{
        transition: filterDrawerTransition(open),
        ml: { xs: 0, md: enabled && open ? DRAWER_WIDTH : 0 },
        pl: { xs: 0, md: open || !enabled ? 0 : DRAWER_BAR_WIDTH },
        display: 'flex',
        flexDirection: 'column',
        minHeight,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          transition: filterDrawerTransition(open),
          width: { xs: '100%', md: DRAWER_BAR_WIDTH },
          p: 4,
          height: { xs: DRAWER_BAR_WIDTH, md: '100%' },
          position: 'absolute',
          left: { xs: 0, md: open ? -48 : 0 },
          bottom: { xs: open ? -48 : 0, md: 0 },
          background: ({ rfhColors }) => rfhColors.grey[50],
          color: ({ rfhColors }) => rfhColors.dutchOrange,
          display: enabled ? 'flex' : 'none',
          justifyContent: { xs: 'flex-end', md: 'center' },
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        <span
          style={{ display: 'flex' }}
          onClick={() => setOpen(current => !current)}
          aria-label='Toon/verberg filters'
        >
          {matches ? <ChevronRightSm /> : <ChevronUpSm />}
        </span>
      </Box>
      {children}
    </Box>
  )
}

export const FilterDrawer: FC<{ children: ReactNode }> = ({ children }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))
  const { open, setOpen } = useContext(FilterContext)

  return (
    <Drawer
      variant='persistent'
      open={open}
      anchor={matches ? 'left' : 'bottom'}
      sx={{
        width: { xs: '100%', md: DRAWER_WIDTH },
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          border: 'none',
          backgroundColor: ({ rfhColors }) => rfhColors.grey[50],
          width: { xs: '100%', md: DRAWER_WIDTH },
          p: 4,
          boxSizing: 'border-box',
          boxShadow: { xs: 12, md: 0 },
        },
      }}
    >
      <Box
        sx={{
          pt: { md: '54px' },
          position: 'relative',
          form: {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '0px',
            color: ({ rfhColors }) => rfhColors.dutchOrange,
            cursor: 'pointer',
          }}
          onClick={() => setOpen(current => !current)}
        >
          {matches ? <ChevronLeftSm /> : <ChevronDownSm />}
        </Box>
        {children}
      </Box>
    </Drawer>
  )
}
