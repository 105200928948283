import { observer } from 'mobx-react-lite'

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import { TablePaginationProps } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import { LeniencyIndicator } from 'src/components/createReport/LeniencyIndicator'
import { FinanceSettlementOverview } from 'src/types/financeSettlement'
import { getFormattedDate } from 'src/utils/format'

import { Feature, useFeatureFlag } from '../../hooks/useFeatureFlag'
import { FinanceSettlementDialog } from './FinanceSettlementDialog'
import { RefetchDataFunction } from './FinanceSettlementTable'

export const FinanceSettlementTableRow: FC<{
  row: FinanceSettlementOverview
  tablePaginationProps: TablePaginationProps
  refetchData: RefetchDataFunction
}> = observer(({ row, tablePaginationProps, refetchData }) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }
  const isFinancieelCorrectieOverzichtMuteren = useFeatureFlag(
    Feature.FinancieelCorrectieOverzichtMuteren
  )
  return (
    <>
      <TableRow>
        <TableCell>{row.supplierId}</TableCell>
        <TableCell>{row.invoiceCreatedInvoiceId}</TableCell>
        <TableCell>{getFormattedDate(row.invoiceCreatedInvoiceDateTime)}</TableCell>
        <TableCell>{`${row.supplyFormCode} ${row.supplyFormCharacter}`}</TableCell>
        <TableCell>
          <LeniencyIndicator withMargin={false} value={row.rate} heffing={row.rate} />
        </TableCell>
        {isFinancieelCorrectieOverzichtMuteren && (
          <TableCell align='right'>
            <IconButton
              sx={{
                svg: {
                  width: '16px',
                  height: '16px',
                },
              }}
              className='outlined'
              disabled={['Rollback', 'Update'].includes(row.transactionType)}
              aria-label={t('FinanceSettlement.deleteBtnLabel')}
              onClick={handleOpenDialog}
            >
              <Delete />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <FinanceSettlementDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        rowData={row}
        tablePaginationProps={tablePaginationProps}
        refetchData={refetchData}
      />
    </>
  )
})
