import { AxiosError } from 'axios'

import { useState } from 'react'

import { queryCharacteristicValues } from 'src/services/characteristicsService'
import { CharacteristicValueCode } from 'src/types/ClockSupplyLine'
import { ApiError, badRequestCode, notFoundCode } from 'src/types/StatusCodes'

type CharacteristicValuesReturntype = {
  loading: boolean
  characteristicValues?: CharacteristicValueCode[]
  apiError?: ApiError
  getCharacteristicValuesAsync: (characteristicCode: string) => Promise<void>
}

export const useCharacteristicValues = (): CharacteristicValuesReturntype => {
  const [loading, setLoading] = useState(false)
  const [characteristicValues, setCharacteristicValues] =
    useState<CharacteristicValueCode[]>()
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const getCharacteristicValuesAsync = async (characteristicCode: string) => {
    if (!loading) {
      setLoading(true)
      setCharacteristicValues([])
      try {
        const { data: responseData } = await queryCharacteristicValues(
          characteristicCode
        )
        setCharacteristicValues(responseData)
        setApiError(undefined)
      } catch (error) {
        if ((error as AxiosError).response?.status === notFoundCode) {
          setApiError(ApiError.notFound)
        } else if ((error as AxiosError).response?.status === badRequestCode) {
          setApiError(ApiError.badRequest)
        } else {
          setApiError(ApiError.generalFailure)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return { loading, apiError, characteristicValues, getCharacteristicValuesAsync }
}
