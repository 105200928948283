import { AxiosResponse } from 'axios'

import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import CircularProgress from '@mui/material/CircularProgress'
import Radio from '@mui/material/Radio'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useIsFetching } from '@tanstack/react-query'

import { queryClient } from 'src/App'
import { Product } from 'src/types/ClockSupplyLine'
import { ODataResponse } from 'src/types/odata'

export const ChangeProductDialogTableBody: FC<{
  selectedProduct: Product | undefined
  setSelectedProduct: Dispatch<SetStateAction<Product | undefined>>
  queryKeys: any[]
}> = ({ selectedProduct, setSelectedProduct, queryKeys }) => {
  const fetching = useIsFetching({ queryKey: ['products'] })
  const { t } = useTranslation()

  const queryData =
    queryClient.getQueryData<AxiosResponse<ODataResponse<Product[]>>>(queryKeys)

  // loading
  if (fetching > 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4} align='center'>
            <CircularProgress aria-label={t('queryState.loading.label')} />
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  // initial message
  if (!queryData) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4} align='center'>
            {t('inspection.changeProductDialog.table.initialMessage')}
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  // no result
  if (queryData?.data.value.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4} align='center'>
            {t('inspection.changeProductDialog.table.noResultMessage')}
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  // result
  return (
    <TableBody>
      {queryData?.data.value.map(row => (
        <TableRow
          key={row.vbnProductCode}
          sx={{
            cursor: 'pointer',
            ':hover': {
              backgroundColor: ({ rfhColors }) => rfhColors.grey[50],
            },
          }}
          onClick={() => setSelectedProduct(row)}
        >
          <TableCell sx={{ padding: '0 !important' }}>
            <Radio checked={row.vbnProductCode === selectedProduct?.vbnProductCode} />
          </TableCell>
          <TableCell>{row.vbnProductCode}</TableCell>
          <TableCell>{row.productName}</TableCell>
          <TableCell>{row.productGroup}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
