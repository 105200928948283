import { FC, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

import { MultipleLocationsIndicator } from 'src/components/styles/InformationSection.styles'
import type { ClockSupplyLine } from 'src/types/ClockSupplyLine'

type LocationsProps = {
  batch?: ClockSupplyLine
}

const LocationsPopper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5),
  background: theme.rfhColors.white,
  borderRadius: 2,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
}))

const LocationsList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}))

export const LocationsRow: FC<LocationsProps> = ({ batch }) => {
  const { t } = useTranslation()
  const [showLocationsPopper, setShowLocationsPopper] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleOnMouseEnter = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
    setShowLocationsPopper(true)
  }

  const handleOnMouseLeave = (): void => {
    setShowLocationsPopper(false)
    setAnchorEl(null)
  }

  return (
    <TableRow>
      <TableCell component='th'>{t('inspection.labels.locations')}</TableCell>
      {batch!.batchParts!.length > 0 ? (
        <TableCell>
          <Box sx={{ my: 2 }}>
            {t('inspection.rowNumber')}
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {`${[...batch!.batchParts!][0]?.loadCarrierRowNumber} ${
                [...batch!.batchParts!][0]?.loadCarrierRowSequenceNumber
              }`}
              {batch!.batchParts!.length > 1 && (
                <MultipleLocationsIndicator
                  data-testid='locations-indicator'
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                >
                  {batch!.batchParts!.length - 1}
                </MultipleLocationsIndicator>
              )}
            </Box>
            <Popper open={showLocationsPopper} anchorEl={anchorEl} placement='right'>
              <LocationsPopper>
                <LocationsList>
                  {[...batch!.batchParts!].map(
                    (item, index) =>
                      !!index && (
                        <li key={`${index}${item.loadCarrierRowNumber}`}>{`${t(
                          'inspection.rowNumber'
                        )} ${item.loadCarrierRowNumber} ${
                          item.loadCarrierRowSequenceNumber
                        }`}</li>
                      )
                  )}
                </LocationsList>
              </LocationsPopper>
            </Popper>
          </Box>
        </TableCell>
      ) : (
        <Box sx={{ my: 2 }}>
          <TableCell>{t('inspection.noResults')}</TableCell>
        </Box>
      )}
    </TableRow>
  )
}
