import { useMutateStore } from 'src/stores/MutateStore'

type useMutateReturntype = {
  mutatingActive: boolean
  setMutating: (isMutating: boolean) => void
}

export const useMutate = (): useMutateReturntype => {
  const { mutatingActive, setMutatingActive } = useMutateStore()

  const setMutating = (isMutating: boolean) => {
    setMutatingActive(isMutating)
  }
  return { mutatingActive, setMutating }
}
