import { Slider } from '@mui/material'

export const ColorSlider = ({
  value,
  setValue,
}: {
  value: number
  setValue: (value: number) => void
}) => (
  <Slider
    value={value}
    // MUI Slider does not implement the generic type correctly, value can be number | number[]
    // It should have inferred the type from the value prop, but it doesn't, hence the type assertion
    onChange={(_, v) => setValue(v as number)}
    min={0}
    max={100}
    step={1}
    sx={{
      '& .MuiSlider-rail': {
        backgroundImage: 'linear-gradient(to right, black , white)',
        border: '1px solid hsl(0deg 0% 80%)',
        opacity: 1,
        height: '20px',
      },
      '& .MuiSlider-thumb': {
        height: '20px',
        width: '20px',
      },
    }}
    track={false}
    aria-label='color'
  />
)
