import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useOnlineOffline } from 'src/hooks/useOnlineOffline'

type BatchSavedDialogActionsProps = {
  handleClose: () => void
  validationError: boolean
}

export const BatchSavedDialogActions: FC<BatchSavedDialogActionsProps> = ({
  handleClose,
  validationError,
}) => {
  const { t } = useTranslation()
  const { offline } = useOnlineOffline()

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-around' width='100%'>
      <Button variant='text' disabled={offline} onClick={handleClose}>
        {t('inspection.savedDialog.closeButtonText')}
      </Button>
      <Button type='submit' variant='contained' disabled={offline || validationError}>
        {t('inspection.savedDialog.startButtonText')}
      </Button>
    </Box>
  )
}
