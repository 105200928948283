import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import ClearOutlined from '@mui/icons-material/ClearOutlined'
import InputAdornment from '@mui/material/InputAdornment'

export const ClearAdornment: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <InputAdornment
      position='end'
      sx={{
        '& .MuiSvgIcon-root': {
          color: ({ rfhColors }) => `${rfhColors.grey[800]} !important`,
          cursor: 'pointer',
        },
      }}
      aria-label={t('buttons.clear')}
      role='button'
      onClick={onClick}
    >
      <ClearOutlined />
    </InputAdornment>
  )
}
