import sub from 'date-fns/sub'

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { TablePaginationProps } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DateRange } from '@mui/x-date-pickers-pro'
import { useMutation } from '@tanstack/react-query'

import { useNotification } from 'src/hooks/useNotification'
import { postRollback } from 'src/services/financeSettlementService'
import {
  FinanceSettlementOverview,
  FinanceSettlementRollBackInput,
  FinanceSettlementSearchInput,
} from 'src/types/financeSettlement'
import { getFormattedDate } from 'src/utils/format'

import { LeniencyIndicator } from '../createReport/LeniencyIndicator'
import { RefetchDataFunction } from './FinanceSettlementTable'

type FinanceSettlementDialogProps = {
  open: boolean
  onClose: () => void
  rowData: FinanceSettlementOverview
  tablePaginationProps: TablePaginationProps
  refetchData: RefetchDataFunction
}

export const FinanceSettlementDialog: FC<FinanceSettlementDialogProps> = ({
  open,
  onClose,
  rowData,
  tablePaginationProps,
  refetchData,
}) => {
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const [rollbackReason, setRollbackReason] = useState('')
  const handleClose = () => {
    onClose()
  }

  const currentDate = new Date()
  const startDate = sub(currentDate, { days: 7 })
  const endDate = new Date()
  const queryParams = new URLSearchParams(window.location.search)
  const supplierIdFilter = queryParams.get('supplierId')
  const invoiceCreatedInvoiceIdFilter = queryParams.get('invoiceCreatedInvoiceId')
  const supplyFormCodeFilter = queryParams.get('supplyFormCode')
  const dateRangeFilter = queryParams.get('dateRange')

  const values: FinanceSettlementSearchInput = {
    supplierId: supplierIdFilter ? Number(supplierIdFilter) : undefined,
    invoiceCreatedInvoiceId: invoiceCreatedInvoiceIdFilter || undefined,
    supplyFormCode: supplyFormCodeFilter || undefined,
    dateRange: dateRangeFilter
      ? (dateRangeFilter.split(',').map(date => new Date(date)) as DateRange<Date>)
      : [startDate, endDate],
  }

  const { mutate } = useMutation({
    mutationFn: (params: FinanceSettlementRollBackInput) => postRollback(params),
    onSuccess: () => {
      refetchData(
        {
          supplierId: values.supplierId,
          invoiceCreatedInvoiceId: values.invoiceCreatedInvoiceId,
          supplyFormCode: values.supplyFormCode,
          dateRange: values.dateRange,
        },
        tablePaginationProps.rowsPerPage,
        tablePaginationProps.page
      )
      showNotification([t('FinanceSettlement.notificationRollback')], 'success')
    },
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          width: { xs: '100%', md: '500px' },
        }}
      >
        <Grid item xs={12}>
          <Table
            className='noZebra'
            sx={{
              '.MuiTableCell-root': {
                border: 'none',
                paddingLeft: '0px',
              },
              '.MuiTableBody-root': {
                border: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: ({ rfhColors }) => rfhColors.black,
                    backgroundColor: ({ rfhColors }) => rfhColors.white,
                  }}
                  colSpan={2}
                >
                  <Typography variant='h4'>
                    {t('FinanceSettlement.createRollback')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component='th'>
                  {t('FinanceSettlement.tableHeaders.supplierNumber')}
                </TableCell>
                <TableCell sx={{ textOverflow: 'ellipsis' }}>
                  {rowData.supplierId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  {t('FinanceSettlement.tableHeaders.invoiceNumber')}
                </TableCell>
                <TableCell sx={{ textOverflow: 'ellipsis' }}>
                  {rowData.invoiceCreatedInvoiceId}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  {t('FinanceSettlement.tableHeaders.invoiceDate')}
                </TableCell>
                <TableCell sx={{ textOverflow: 'ellipsis' }}>
                  {getFormattedDate(rowData.invoiceCreatedInvoiceDateTime)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  {t('FinanceSettlement.tableHeaders.supplyFormCode')}
                </TableCell>
                <TableCell sx={{ textOverflow: 'ellipsis' }}>
                  {`${rowData.supplyFormCode} ${rowData.supplyFormCharacter}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component='th'>
                  {t('FinanceSettlement.tableHeaders.observationCategory')}
                </TableCell>
                <TableCell sx={{ textOverflow: 'ellipsis' }}>
                  <LeniencyIndicator
                    withMargin={false}
                    value={rowData.rate}
                    heffing={rowData.rate}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <TextField
          placeholder='...'
          fullWidth
          label={t('FinanceSettlement.reason')}
          multiline
          rows={2}
          required
          onChange={event => setRollbackReason(event.target.value)}
        />
        <Divider sx={{ my: 4 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',

            pt: 2,
          }}
        >
          <Button variant='text' onClick={handleClose}>
            {t('inspection.changeProductDialog.button.close')}
          </Button>
          <Button
            variant='contained'
            type='submit'
            disabled={!rollbackReason}
            onClick={() =>
              mutate({
                settlementId: rowData.id,
                rollbackReason,
                transactionType: 'rollback',
              })
            }
          >
            {t('inspection.changeProductDialog.button.submit')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
