import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import { Hero } from '@rfh-core/components'

import { Layout } from 'src/components/Layout'
import { BatchFotoCard } from 'src/components/cards/BatchFotoCard'
import { BatchSearchCard } from 'src/components/cards/BatchSearchCard'
import { FinanceSettlementCard } from 'src/components/cards/FinanceSettlementCard'
import { InspectionReportsCard } from 'src/components/cards/InspectionReportsCard'
import { ObservationFindingsCard } from 'src/components/cards/ObservationFindingsCard'
import { useBarcodeScanner } from 'src/hooks/useBarcodeScanner'
import { Feature, useFeatureFlag } from 'src/hooks/useFeatureFlag'
import { useUserInformation } from 'src/hooks/useUserInformation'

export const Home: FC = () => {
  const { t } = useTranslation()
  useUserInformation()
  useBarcodeScanner()

  const isFinancieelCorrectieOverzichtLezenEnabled = useFeatureFlag(
    Feature.FinancieelCorrectieOverzichtLezen
  )
  const isKeurverslagenSchermLezenEnabled = useFeatureFlag(
    Feature.KeurverslagenSchermLezen
  )

  const isBatchFotoEnabled = useFeatureFlag(
    Feature.PartijOpvragenSchermToevoegenKlokFoto
  )

  return (
    <Layout
      slot={<Hero size='large' title={t('hero.title')} subtitle={t('hero.subtitle')} />}
      minHeight='calc(100vh - 145px)'
    >
      <Grid container spacing={4} mt={6}>
        <Grid item xs={12} md={6} lg={4}>
          <ObservationFindingsCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BatchSearchCard />
        </Grid>
        {isKeurverslagenSchermLezenEnabled && (
          <Grid item xs={12} md={6} lg={4}>
            <InspectionReportsCard />
          </Grid>
        )}
        {isFinancieelCorrectieOverzichtLezenEnabled && (
          <Grid item xs={12} md={6} lg={4}>
            <FinanceSettlementCard />
          </Grid>
        )}
        {isBatchFotoEnabled && (
          <Grid item xs={12} md={6} lg={4}>
            <BatchFotoCard />
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}
