import { sub } from 'date-fns'

import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { FilterDrawer } from 'src/components/FilterDrawer'
import { useDateRangePicker } from 'src/hooks/useDateRangePicker'
import { FinanceSettlementSearchInput } from 'src/types/financeSettlement'

type FinanceSettlementSearchFormProps = {
  onSearch: (searchValue: FinanceSettlementSearchInput) => void
}

const currentDate = new Date()
const startDate = sub(currentDate, { days: 7 })

const endDate = new Date()

export const defaultValues: FinanceSettlementSearchInput = {
  dateRange: [startDate, endDate],
  supplierId: undefined,
}

export const FinanceSettlementSearchForm: FC<FinanceSettlementSearchFormProps> = ({
  onSearch,
}) => {
  const { t } = useTranslation()

  const onSubmit = (formData: FinanceSettlementSearchInput) => {
    onSearch(formData)
    navigateToPathName(formData)
  }

  const { register, handleSubmit, reset, control } =
    useForm<FinanceSettlementSearchInput>({
      defaultValues,
    })

  const navigate = useNavigate()

  const navigateToPathName = (formData: FinanceSettlementSearchInput) => {
    const supplierIdFilter = formData.supplierId!
    const invoiceCreatedInvoiceIdFilter = formData.invoiceCreatedInvoiceId!
    const supplyFormCodeFilter = formData.supplyFormCode!
    const dateRangeFilter = formData.dateRange!

    const searchParams = new URLSearchParams()
    // istanbul ignore next
    searchParams.set('supplierId', supplierIdFilter?.toString() || '')
    // istanbul ignore next
    searchParams.set(
      'invoiceCreatedInvoiceId',
      invoiceCreatedInvoiceIdFilter?.toString() || ''
    )
    // istanbul ignore next
    searchParams.set('supplyFormCode', supplyFormCodeFilter?.toString() || '')
    // istanbul ignore next
    searchParams.append('dateRange', dateRangeFilter?.toString() || '')

    const pathName = `/financial-corrections?${searchParams.toString()}`

    navigate(pathName)
  }

  const dateRangePicker = useDateRangePicker({ name: 'dateRange', control })

  const handleReset = () => {
    reset()
    navigate('/financial-corrections')
  }
  return (
    <FilterDrawer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant='h3'>Filters</Typography>
        </Box>

        <TextField
          fullWidth
          label={t('FinanceSettlement.formLabels.supplierNumber')}
          {...register('supplierId')}
        />

        <TextField
          fullWidth
          label={t('FinanceSettlement.formLabels.invoiceNumber')}
          {...register('invoiceCreatedInvoiceId')}
        />
        <FormControl fullWidth>
          <InputLabel>{t('translations:FinanceSettlement.formLabels.date')}</InputLabel>
          {dateRangePicker}
        </FormControl>
        <TextField
          fullWidth
          label={t('FinanceSettlement.formLabels.supplyFormCode')}
          {...register('supplyFormCode')}
        />
        <Button type='submit' sx={{ mt: 2 }}>
          {t('FinanceSettlement.search')}
        </Button>
        <Button variant='outlined' onClick={() => handleReset()}>
          {t('FinanceSettlement.reset')}
        </Button>
      </form>
    </FilterDrawer>
  )
}
