import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Layout } from 'src/components/Layout'
import { EmptyState } from 'src/components/styles/EmptyState/EmptyState'
import { EmptyStateIcon } from 'src/components/styles/EmptyState/EmptyStateIcon'

export const NotFound: FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <Layout>
      <Box mt={6}>
        <EmptyState
          bottomComponent={
            <Button
              variant='contained'
              onClick={() => {
                navigate('/')
              }}
            >
              {t('notFound.button')}
            </Button>
          }
          graphic={<EmptyStateIcon />}
          header={t('notFound.header')}
          subheader={t('notFound.subHeader')}
        />
      </Box>
    </Layout>
  )
}
