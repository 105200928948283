import nl from 'date-fns/locale/nl'

import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider, nlNL } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { useOktaAuth } from '@okta/okta-react'

import { FilterDrawer } from 'src/components/FilterDrawer'
import { useDateRangePicker } from 'src/hooks/useDateRangePicker'
import {
  ChangeType,
  ObservationFindingsSearchInput,
  PlantType,
  SearchLocations,
} from 'src/types/ObservationFindings'

import { SupplierAutocomplete } from '../SupplierAutoComplete'

type ObservationFindingsSearchFormProps = {
  onSearch: (searchValue: ObservationFindingsSearchInput) => void
  inspectionReports?: boolean
}

export const defaultValues: ObservationFindingsSearchInput = {
  type: [ChangeType.bevinding],
  location: [],
  supplier: undefined,
  product: '',
  approvedBy: '',
  plantType: [],
}

export const ObservationFindingsSearchForm: FC<ObservationFindingsSearchFormProps> = ({
  onSearch,
  inspectionReports = false,
}) => {
  const { t } = useTranslation()
  const { authState } = useOktaAuth()

  const onSubmit = (formData: ObservationFindingsSearchInput) => {
    onSearch(formData)
  }

  const additionalValues = inspectionReports
    ? { date: new Date(new Date().setHours(0, 0, 0, 0)) }
    : {
        dateRange: [
          new Date(new Date().setHours(0, 0, 0, 0)),
          new Date(new Date().setHours(0, 0, 0, 0)),
        ],
      }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    resetField,
    formState: { errors },
  } = useForm<ObservationFindingsSearchInput>({
    defaultValues: {
      ...defaultValues,
      ...additionalValues,
      approvedBy: authState!.accessToken!.claims.sub,
    },
  })
  const dateRangePicker = useDateRangePicker({ name: 'dateRange', control })

  return (
    <FilterDrawer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant='h3'>Filters</Typography>
        </Box>
        <FormGroup sx={{ paddingTop: 5 }}>
          <Typography variant='body1'>
            {t('observationFindings.formLabels.changeType')}
          </Typography>

          {Object.values(ChangeType).map((type, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{ paddingLeft: 0, paddingY: 1 }}
                  {...register('type')}
                  checked={watch('type')?.includes(type)}
                  value={type}
                />
              }
              label={
                <Typography className='book' variant='body1'>
                  {type}
                </Typography>
              }
            />
          ))}
        </FormGroup>

        <FormGroup sx={{ paddingTop: 5 }}>
          <Typography variant='body1'>
            {t('observationFindings.formLabels.location')}
          </Typography>

          {Object.values(SearchLocations).map((location, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{ paddingLeft: 0, paddingY: 1 }}
                  {...register('location')}
                  checked={watch('location')?.includes(location)}
                  value={location}
                />
              }
              label={
                <Typography className='book' variant='body1'>
                  {location}
                </Typography>
              }
            />
          ))}
        </FormGroup>

        <FormGroup sx={{ paddingTop: 5 }}>
          <Typography variant='body1'>
            {t('observationFindings.formLabels.plantType')}
          </Typography>

          {Object.values(PlantType).map((plantType, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  sx={{ paddingLeft: 0, paddingY: 1 }}
                  {...register('plantType')}
                  checked={watch('plantType')?.includes(plantType)}
                  value={plantType}
                />
              }
              label={
                <Typography className='book' variant='body1'>
                  {plantType}
                </Typography>
              }
            />
          ))}
        </FormGroup>
        {inspectionReports ? (
          <Controller
            name='supplier'
            control={control}
            key={JSON.stringify(watch('supplier'))}
            rules={{ required: true }}
            render={({ field, formState }) => (
              <SupplierAutocomplete
                controlProps={field}
                error={!!formState.errors.supplier}
                label={t('observationFindings.formLabels.supplierNumber')}
                handleClear={() => {
                  resetField('supplier')
                }}
              />
            )}
          />
        ) : (
          <TextField
            fullWidth
            label={t('observationFindings.formLabels.supplierNumber')}
            {...register('supplier.clientNumber')}
            InputLabelProps={{ required: inspectionReports }}
            error={!!errors.supplier?.clientNumber}
            helperText={errors.supplier?.clientNumber?.message}
          />
        )}
        <TextField
          fullWidth
          label={t('observationFindings.formLabels.productDescription')}
          {...register('product')}
        />

        <TextField
          fullWidth
          label={t('observationFindings.formLabels.approvedBy')}
          {...register('approvedBy')}
        />

        <FormControl fullWidth>
          <InputLabel>
            {inspectionReports
              ? t('translations:observationFindings.formLabels.auctionDate')
              : t('translations:observationFindings.formLabels.date')}
          </InputLabel>
          {inspectionReports ? (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={nl}
              localeText={{
                ...nlNL.components.MuiLocalizationProvider.defaultProps.localeText,
              }}
            >
              <Controller
                name='date'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker onChange={newValue => onChange(newValue)} value={value} />
                )}
              />
            </LocalizationProvider>
          ) : (
            dateRangePicker
          )}
        </FormControl>
        <Button type='submit' sx={{ mt: 2 }}>
          {t('observationFindings.search')}
        </Button>
        <Button variant='outlined' onClick={() => reset()}>
          {t('observationFindings.reset')}
        </Button>
      </form>
    </FilterDrawer>
  )
}
