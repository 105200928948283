import { AxiosError } from 'axios'

import { useState } from 'react'

import { queryBatch } from 'src/services/batchService'
import { useBatchStatusStore } from 'src/stores/BatchStatusStore'
import { ClockSupplyLine } from 'src/types/ClockSupplyLine'
import { ApiError, badRequestCode, notFoundCode } from 'src/types/StatusCodes'

type useBatchReturntype = {
  loading: boolean
  batch?: ClockSupplyLine
  apiError?: ApiError
  getBatchAsync: (batchRef: string) => Promise<void>
}

export const useBatch = (): useBatchReturntype => {
  const [loading, setLoading] = useState(false)
  const [batch, setBatch] = useState<ClockSupplyLine>()
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const { setBatchStatus, setBatchRef, setIsSearchDisabled } = useBatchStatusStore()

  const getBatchAsync = async (batchRef: string) => {
    if (!loading) {
      setLoading(true)
      try {
        const { data: responseData } = await queryBatch(batchRef)

        setBatch(responseData)
        setApiError(undefined)
        setBatchStatus(undefined)
        setBatchRef(batchRef)
        setIsSearchDisabled(true)
      } catch (error) {
        setIsSearchDisabled(false)
        if ((error as AxiosError).response?.status === notFoundCode) {
          setApiError(ApiError.notFound)
        } else if ((error as AxiosError).response?.status === badRequestCode) {
          setApiError(ApiError.badRequest)
        } else {
          setApiError(ApiError.generalFailure)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return { loading, apiError, batch, getBatchAsync }
}
