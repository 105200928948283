import { AxiosError } from 'axios'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { ChevronDownSm } from '@rfh-core/icons'

import { AutocompletePopper } from 'src/components/AutoCompletePopper'
import { filterOptions } from 'src/components/Characteristics'
import { StyledAutocomplete } from 'src/components/batch/BatchInput.styles'
import { StyledCharacteristicBullet } from 'src/components/styles/InformationSection.styles'
import { useCharacteristicValues } from 'src/hooks/useCharacteristicValues'
import {
  addBatchCharacteristic,
  changeBatchCharacteristic,
  removeBatchCharacteristic,
} from 'src/services/characteristicsService'
import {
  BatchCharacteristic,
  CharacteristicValueCode,
  ClockSupplyLine,
} from 'src/types/ClockSupplyLine'
import { badRequestCode } from 'src/types/StatusCodes'
import {
  emptyBatchCharacteristic,
  filterCharacteristics,
} from 'src/utils/characteristic'

type Row = {
  values: (string | number | JSX.Element)[]
  header?: boolean
}

type useTransformToRowReturnType = {
  transformToMutableRow: () => Row[]
  transformToRow: () => Row[]
}

export const useTransformToRow = (
  batch: ClockSupplyLine,
  setMutatedBatch: (batch: ClockSupplyLine) => void,
  filter: (item: BatchCharacteristic) => boolean,
  options: string[],
  maxNumberOfRows: number,
  setGeneralError: (error: boolean) => void
): useTransformToRowReturnType => {
  const { t } = useTranslation()
  const [valueCode, setValueCode] = useState<string>('')
  const [characteristicCode, setCharacteristicCode] = useState<string>('')
  const [activeCode, setActiveCode] = useState<string>('')

  const [mutationLoading, setMutationLoading] = useState(false)
  const [mutatedItem, setMutatedItem] = useState<BatchCharacteristic>()

  const [addMutationError, setAddMutationError] = useState<boolean>(false)

  const resetErrors = () => {
    setGeneralError(false)
  }

  const { getCharacteristicValuesAsync, characteristicValues, loading } =
    useCharacteristicValues()

  useEffect(() => {
    getCharacteristicValuesAsync(activeCode)
  }, [activeCode])

  useEffect(() => {
    const filtered = [...batch.batchCharacteristics!]
      .filter(filter)
      .map(item => item.characteristicCode)

    const nextCharacteristic = options.find(x => filtered.indexOf(x) === -1)
    setCharacteristicCode(nextCharacteristic || '')
  }, [options, [...batch.batchCharacteristics!]])

  const handleValueCodeChange = async (
    value: string,
    item: BatchCharacteristic,
    isEmpty: boolean
  ) => {
    resetErrors()
    if (!isEmpty) {
      const params = {
        batchCharacteristicId: Number(item.id),
        clockSupplyLineId: Number(batch.id),
        valueCode: value,
      }

      setMutatedItem(item)
      setMutationLoading(true)

      const characteristics = [...batch.batchCharacteristics!]
      const target = characteristics.findIndex(current => current === item)
      try {
        const { data: responseData } = await changeBatchCharacteristic(params, item.id)
        characteristics[target] = responseData

        setMutatedBatch({ ...batch, batchCharacteristics: characteristics })
        setMutatedItem(undefined)
      } catch (error) {
        /* istanbul ignore next */
        if ((error as AxiosError).response?.status === badRequestCode) {
          characteristics[target].error = true
          characteristics[target].valueCode = value
          setMutatedBatch({ ...batch, batchCharacteristics: characteristics })
        } else {
          setGeneralError(true)
        }
      } finally {
        setMutationLoading(false)
      }
    } else {
      setValueCode(value)
    }
  }

  const handleRowRemove = async (item: BatchCharacteristic) => {
    const params = {
      batchCharacteristicId: Number(item.id),
      clockSupplyLineId: Number(batch.id),
    }
    setMutatedItem(item)
    setMutationLoading(true)
    resetErrors()
    try {
      await removeBatchCharacteristic(params, item.id)
      setMutatedBatch({
        ...batch,
        batchCharacteristics: batch.batchCharacteristics!.filter(
          current => current !== item
        ),
      })
      setMutatedItem(undefined)
    } catch (error) {
      setGeneralError(true)
    } finally {
      setMutationLoading(false)
    }
  }

  const handleRowCreate = async () => {
    const params = {
      characteristicCode,
      clockSupplyLineId: Number(batch.id),
      valueCode,
    }
    setMutationLoading(true)
    resetErrors()
    try {
      const { data: responseData } = await addBatchCharacteristic(params)
      setMutatedBatch({
        ...batch,
        batchCharacteristics: batch.batchCharacteristics!.concat(responseData),
      })
      setAddMutationError(false)
      setCharacteristicCode('')
      setValueCode('')
    } catch (error) {
      /* istanbul ignore next */
      if ((error as AxiosError).response?.status === badRequestCode) {
        setAddMutationError(true)
      } else {
        setGeneralError(true)
      }
    } finally {
      setMutationLoading(false)
    }
  }

  const transformToMutableRow = (): Row[] =>
    [...batch.batchCharacteristics!]
      .filter(filter)
      .concat(emptyBatchCharacteristic)
      .splice(0, maxNumberOfRows)
      .map(item => {
        const isEmpty = !item.characteristicCode || !item.valueCode
        return {
          values: [
            !isEmpty ? item.characteristicCode : characteristicCode,
            <StyledAutocomplete
              PopperComponent={AutocompletePopper}
              disabled={item.isReadonly}
              popupIcon={<ChevronDownSm />}
              disableClearable={true}
              loading={loading}
              loadingText='laden...'
              onFocus={() =>
                setActiveCode(item.characteristicCode || characteristicCode)
              }
              options={characteristicValues || []}
              value={!isEmpty ? item : { ...item, valueCode }}
              renderOption={(props, option) => (
                <li {...props}>
                  {(option as CharacteristicValueCode).valueCode} -{' '}
                  {(option as CharacteristicValueCode).value}
                </li>
              )}
              onChange={(_, newValue) => {
                handleValueCodeChange(
                  (newValue as CharacteristicValueCode).valueCode,
                  item,
                  isEmpty
                )
              }}
              filterOptions={filterOptions}
              autoSelect
              autoHighlight
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  error={(addMutationError && isEmpty) || item.error}
                  helperText={
                    ((addMutationError && isEmpty) || item.error) &&
                    t('queryState.badRequest.message')
                  }
                  inputProps={{
                    ...params.inputProps,
                    'aria-label': `autocomplete-${item.characteristicCode}`,
                  }}
                />
              )}
              getOptionLabel={option => (option as CharacteristicValueCode).valueCode}
            />,
            item.characteristicValue?.value || '',
            mutationLoading && (item === mutatedItem || isEmpty) ? (
              <Box display='flex' alignItems='center'>
                {/* TODO: CircularProgress werkt nog niet naar behoren */}
                <CircularProgress aria-label={t('queryState.loading.label')} />
              </Box>
            ) : (
              <IconButton
                sx={{
                  svg: {
                    width: '16px',
                    height: '16px',
                  },
                }}
                className='outlined'
                onClick={() => (isEmpty ? handleRowCreate() : handleRowRemove(item))}
                aria-label={isEmpty ? 'addButton' : 'removeButton'}
                disabled={isEmpty ? !valueCode || !characteristicCode : item.isReadonly}
              >
                {isEmpty ? <Add /> : <Delete />}
              </IconButton>
            ),
          ],
        }
      })

  const transformToRow = (): Row[] =>
    [...batch.batchCharacteristics!].filter(filter).map(item => ({
      values: [
        <>
          {filterCharacteristics(item) && (
            <StyledCharacteristicBullet sortingCode={item.sortingCode} />
          )}
          {`${item.characteristicCode}`}
        </>,
        item.valueCode,
        item.characteristicValue?.value || '',
      ],
    }))

  return { transformToMutableRow, transformToRow }
}
