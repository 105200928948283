import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledCharacteristicBullet = styled(Box)<{ sortingCode: number }>(
  ({ theme, sortingCode }) => ({
    width: '10px',
    height: '10px',
    transform: 'rotate(45deg) translate(3px, -3px)',
    backgroundColor:
      sortingCode === 0 ? theme.rfhColors.grey[800] : theme.rfhColors.hydroGreen,
    display: 'inline-flex',
  })
)

export const MultipleLocationsIndicator = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: `2px solid ${theme.rfhColors.dutchOrange}`,
  color: theme.rfhColors.dutchOrange,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'end',
  fontSize: '12px',
  fontWeight: 'bold',
  float: 'right',
  cursor: 'pointer',
}))
