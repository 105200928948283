import { FC } from 'react'
import {
  NavigateFunction,
  Route,
  Routes as ReactRouterRoutes,
  useNavigate,
} from 'react-router-dom'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Protected } from '@rfh-core/components'

// import { InspectionReportViewer } from 'src/exporters/InspectionReport'
import { Callback } from 'src/okta/Callback'
import { Login } from 'src/okta/Login'
import { Logout } from 'src/okta/Logout'
import { oktaAuth } from 'src/utils/auth'
import { CreateReport } from 'src/views/CreateReport'
import { FinanceSettlement } from 'src/views/FinanceSettlement'
import { Home } from 'src/views/Home'
import { InspectionDetails } from 'src/views/InspectionDetails'
import { InspectionReports } from 'src/views/InspectionReports'
import { NoPermission } from 'src/views/NoPermission'
import { NotFound } from 'src/views/NotFound'
import { ObservationFindings } from 'src/views/ObservationFindings'

import { InspectionReportsFormProvider } from './InspectionReportsFormProvider'
import { Batch } from './photoFeature/Batch'
import { BatchFinder } from './photoFeature/BatchFinder'
import { ClockPhoto } from './photoFeature/clockPhoto/ClockPhoto'

export const restoreOriginalUri =
  (navigate: NavigateFunction) =>
  (_oktaAuth: OktaAuth, originalUri = '/'): void => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true })
  }
export const onAuthRequired = (navigate: NavigateFunction) => (): void => {
  navigate('/login')
}

export const Routes: FC = () => {
  const navigate = useNavigate()

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri(navigate)}
      onAuthRequired={onAuthRequired(navigate)}
    >
      <ReactRouterRoutes>
        <Route
          path='/'
          element={
            <Protected>
              <Home />
            </Protected>
          }
        />
        <Route
          path='/inspection'
          element={
            <Protected>
              <InspectionDetails />
            </Protected>
          }
        >
          <Route path=':id' element={<InspectionDetails />} />
        </Route>
        <Route
          path='/findings'
          element={
            <Protected>
              <ObservationFindings />
            </Protected>
          }
        />
        <Route
          path='/inspection-reports/new'
          element={
            <Protected>
              <InspectionReportsFormProvider>
                <CreateReport />
              </InspectionReportsFormProvider>
            </Protected>
          }
        />
        <Route
          path='/inspection-reports'
          element={
            <Protected>
              <InspectionReportsFormProvider>
                <InspectionReports />
              </InspectionReportsFormProvider>
            </Protected>
          }
        />
        <Route
          path='/financial-corrections'
          element={
            <Protected>
              <FinanceSettlement />
            </Protected>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/batch/:code/clock-photo' element={<ClockPhoto />} />
        <Route path='/batch/:code' element={<Batch />} />
        <Route path='/batch' element={<BatchFinder />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/callback' element={<Callback />} />
        {/* handig als live preview van de PDF, want dan zie je de PDF in de browser verversen als je code wijzigt in VSCode. */}
        {/* <Route path='/inspection-report' element={<InspectionReportViewer />} /> */}
        <Route path='/no-permission' element={<NoPermission />} />
        <Route path='*' element={<NotFound />} />
      </ReactRouterRoutes>
    </Security>
  )
}
