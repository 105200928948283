import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'

import type {
  AddAuditCommentPayload,
  ChangeInspectionFileRemarkPayload,
  DeleteAuditCommentPayload,
} from 'src/services/inspectionReportsService'
import type {
  InspectionRapportView,
  InspectionReportImages,
} from 'src/types/InspectionReport'

import { StepContentLayout } from './StepContentLayout'

const VerticalStepper: FC<{
  stepsData: InspectionRapportView
  imagesFromApi: InspectionReportImages
  addRemark: (object: AddAuditCommentPayload) => void
  deleteRemark: (object: DeleteAuditCommentPayload) => void
  editComment: (object: ChangeInspectionFileRemarkPayload) => void
  isEditing: boolean
  setIsEditingRemark: (editing: boolean) => void
}> = ({
  stepsData,
  imagesFromApi,
  addRemark,
  isEditing,
  setIsEditingRemark,
  deleteRemark,
  editComment,
}) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  if (!stepsData) {
    return null
  }
  /* istanbul ignore next */
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setIsEditingRemark(false)
  }
  /* istanbul ignore next */
  const isLastStep = () =>
    stepsData.inspectionFiles.length > 0 &&
    activeStep === stepsData.inspectionFiles.length - 1

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {stepsData.inspectionFiles.map((step, index) => (
          <Step key={index} sx={{ height: 'auto' }}>
            <StepLabel>
              <Paper
                sx={{
                  marginLeft: 3,
                  padding: 5,
                  borderBottom: ({ rfhColors }) =>
                    index === activeStep
                      ? 'none'
                      : `1px solid ${rfhColors.grey[400]} !important`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant='h4'>
                    {t('createReport.partijId')}
                    {step.clockSupplyLine.supplyFormCode +
                      step.clockSupplyLine.supplyFormCharacter}
                  </Typography>
                  {activeStep !== index && (
                    <Button
                      variant='text'
                      onClick={() => {
                        setActiveStep(index)
                        setIsEditingRemark(false)
                      }}
                    >
                      {t('createReport.buttons.show')}
                    </Button>
                  )}
                </Box>
              </Paper>
            </StepLabel>

            <StepContent>
              <StepContentLayout
                onNext={handleNext}
                isLastStep={isLastStep}
                stepData={step}
                imagesFromApi={imagesFromApi}
                addRemark={addRemark}
                isEditing={isEditing}
                setIsEditingRemark={setIsEditingRemark}
                deleteRemark={deleteRemark}
                editComment={editComment}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default VerticalStepper
