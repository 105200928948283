import { useState } from 'react'
import { Area } from 'react-easy-crop'
import { useTranslation } from 'react-i18next'

import { PhotoFilter, Close, RotateRight } from '@mui/icons-material'
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material'

import { modifyImage } from '../resize'
import { ColorSlider } from './ColorSlider'
import { RfhCroppper } from './RfhCroppper'
import { useTransparentBackground } from './useTransparentBackground'

type Props = {
  src: string
  onClose: () => void
  savePreview: (preview: string) => void
}

export const EditPhoto = ({ src, onClose, savePreview }: Props) => {
  const { t } = useTranslation()
  const [rotation, setRotation] = useState(0)
  const [cropArea, setCropArea] = useState<Area>({ width: 0, height: 0, x: 0, y: 0 })
  const [lightness, setLightness] = useState(0)
  const { data: srcTransparent, isFetching } = useTransparentBackground(src)
  const [showTransparent, setShowTransparent] = useState(false)

  // We are using HSL color space to change, so we can easily range from black to white
  // 0% lightness is black, 100% lightness is white, as long as the saturation is 0%
  const backgroundColor = `hsl(0deg 0% ${lightness}%)`

  const isLoading = showTransparent && isFetching
  const imgSrc = showTransparent && srcTransparent ? srcTransparent : src

  return (
    <Stack
      spacing={4}
      sx={{ padding: '1rem', height: '100vh', backgroundColor: 'white' }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3'>Foto bewerken</Typography>
        <IconButton color='inherit' aria-label='close' onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>

      <RfhCroppper
        onCropChange={setCropArea}
        src={imgSrc}
        rotation={rotation}
        backgroundColor={backgroundColor}
      />

      <Stack direction='row' justifyContent='center' spacing={4}>
        <Stack direction='column' alignItems='center' justifyContent='flex-end'>
          <IconButton
            aria-label={t('photoFeature.removeBackground')}
            onClick={() => setShowTransparent(value => !value)}
            disabled={isLoading}
            className={showTransparent && !isFetching ? 'contained' : ''}
          >
            {isLoading ? <CircularProgress size={24} /> : <PhotoFilter />}
          </IconButton>

          <Typography variant='body2'>{t('photoFeature.removeBackground')}</Typography>
        </Stack>
        <Stack direction='column' alignItems='center'>
          <IconButton
            aria-label={t('photoFeature.rotate')}
            onClick={() => {
              setRotation((rotation + 90) % 360)
            }}
          >
            <RotateRight />
          </IconButton>
          <Typography variant='body2'>{t('photoFeature.rotate')}</Typography>
        </Stack>
      </Stack>

      {!isFetching && showTransparent && (
        <Stack sx={{ mb: 1, px: 2 }} alignItems='center'>
          <Typography variant='body1'>{t('photoFeature.colorSliderLabel')}</Typography>
          <ColorSlider value={lightness} setValue={v => setLightness(v)} />
        </Stack>
      )}

      <Button
        style={{ marginTop: 'auto' }}
        disabled={isLoading}
        onClick={async () => {
          savePreview(
            await modifyImage(imgSrc, {
              backgroundColor,
              rotation,
              cropArea,
            })
          )
          onClose()
        }}
      >
        Toepassen
      </Button>
    </Stack>
  )
}
