import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'

import { ImageBox } from 'src/components/styles/ImageBox'
import { Config } from 'src/configs/Config'
import DefaultPlaceholderImage from 'src/illustrations/RFH_placeholder-image.svg'
import type { ClockSupplyLine } from 'src/types/ClockSupplyLine'

import { BatchImageEdit } from './BatchImageEdit'

type BatchImageProps = {
  batch: ClockSupplyLine
  isMutating: boolean
  updateMutatedBatch: Dispatch<SetStateAction<ClockSupplyLine | undefined>>
}

export const BatchImage: FC<BatchImageProps> = ({
  batch,
  isMutating,
  updateMutatedBatch,
}) => {
  const [showImageDialog, setShowImageDialog] = useState(false)
  const { t } = useTranslation()
  const [mutatedImageId, setMutatedImageId] = useState<string>()
  const imageBase = `${Config.beeldbankHostValue}/foto/volledig/`
  const imageId = mutatedImageId || batch.batchImages![0]?.supplierImageId

  useEffect(() => {
    setMutatedImageId(batch.batchImages![0]?.supplierImageId)
  }, [batch.batchImages])

  const toggleDialog = () => setShowImageDialog(value => !value)

  return (
    <>
      <Box position='relative'>
        {isMutating && imageId && (
          <BatchImageEdit
            batchImage={batch.batchImages![0]}
            mutatedImageId={mutatedImageId}
            handleMutatedImageId={setMutatedImageId}
            updateMutatedBatch={updateMutatedBatch}
          />
        )}
        {batch.batchImages![0]?.supplierImageId ? (
          <>
            <ImageBox
              sx={{
                cursor: 'zoom-in',
              }}
              onClick={toggleDialog}
              aspectRatio='1 / 1'
              aria-label={t('inspection.batchImage.zoomIn')}
              role='button'
            >
              <img alt={batch.product?.productName} src={imageBase + imageId} />
            </ImageBox>
            <Dialog
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: '644px',
                  border: '2px solid #fff',
                },
              }}
              open={showImageDialog}
              onClose={toggleDialog}
            >
              <Box
                sx={{
                  cursor: 'zoom-out',
                  '& img': { display: 'block' },
                }}
                onClick={toggleDialog}
                aria-label={t('inspection.batchImage.zoomOut')}
                role='button'
              >
                <img alt={batch.product?.productName} src={imageBase + imageId} />
              </Box>
            </Dialog>
          </>
        ) : (
          <ImageBox aspectRatio='1 / 1' aria-label='default placeholder'>
            <img alt='default placeholder' src={DefaultPlaceholderImage} />
          </ImageBox>
        )}
      </Box>
    </>
  )
}
