export enum CourtesyCodes {
  geen_coulance = 'geen_coulance',
  meerdere_partijen_met_dezelfde_fout = 'meerdere_partijen_met_dezelfde_fout',
  minimale_afwijking_op_informatie = 'minimale_afwijking_op_informatie',
  kweker_heeft_verkeerde_keurkenmerk_gebruikt = 'kweker_heeft_verkeerde_keurkenmerk_gebruikt',
  wel_keurkenmerk_op_fysieke_brief_maar_niet_in_EAB = 'wel_keurkenmerk_op_fysieke_brief_maar_niet_in_EAB',
  kleine_partij = 'kleine_partij',
  kweker_heeft_informatie_omgewisseld_met_een_andere_partij = 'kweker_heeft_informatie_omgewisseld_met_een_andere_partij',
  kweker_heeft_voor_de_eerste_keer_een_fout_gemaakt = 'kweker_heeft_voor_de_eerste_keer_een_fout_gemaakt',
  overig_namelijk = 'overig_namelijk',
}

export type CourtesyCodesKey = Exclude<keyof typeof CourtesyCodes, 'geen_coulance'>
