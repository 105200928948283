import jwt_decode from 'jwt-decode'

import { useEffect, useState } from 'react'

import { UserClaims } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'

import { useAccountStore } from 'src/stores/AccountStore'

type UserInformation = UserClaims & { customerNumber: number }

export const useUserInformation = (): UserInformation | null => {
  const { setUser, setAuthState, setPermittedScopes } = useAccountStore()
  const { oktaAuth, authState } = useOktaAuth()
  const [account, setAccount] = useState<UserInformation | null>(null)

  const storeUserInformation = async () => {
    if (authState?.isAuthenticated) {
      const user = await oktaAuth.getUser()

      const decoded: any = jwt_decode(authState.accessToken?.accessToken as string)
      const { klntnr } = decoded
      const decodedKeys: string[] = Object.keys(decoded)
      const permittedScopes: string[] = decodedKeys.filter(
        item => decoded[item] === 'true'
      )

      const userExtended = {
        ...user,
        customerNumber: Number(klntnr),
      }
      setAccount(userExtended)
      setUser(userExtended)
      setAuthState(authState)
      setPermittedScopes(permittedScopes)
    }
  }

  useEffect(() => {
    storeUserInformation()
  }, [])

  return account
}
