import { ChangeEventHandler, FC, FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { OrangeScanIcon } from '@rfh-core/icons'

import { Form } from 'src/components/batch/BatchInput.styles'
import { BatchSavedDialogActions } from 'src/components/batch/BatchSavedDialogActions'
import SavedIllustration from 'src/illustrations/RFH_illustratie_opgeslagen.svg'
import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { validateInput } from 'src/utils/validateInput'

export type BatchSavedDialogProps = {
  handleBatchSearch: (searchedBatchRef: string) => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const BatchSavedDialog: FC<BatchSavedDialogProps> = ({
  handleBatchSearch,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation()
  const { setHotkeyActive } = useHotkeyStore()
  const [searchedBatchRef, setSearchedBatchRef] = useState<string>('')
  const [validationError, setValidationError] = useState<boolean>(false)

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value.replace(/[[\]]/g, '')

    const isValid = validateInput(value)
    setValidationError(!isValid)
    setSearchedBatchRef(value)

    if (event.target.value.endsWith(']')) {
      handleClose()
      handleBatchSearch(value)
      setSearchedBatchRef('')
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    handleBatchSearch(searchedBatchRef)
    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
    setSearchedBatchRef('')
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth='xs'
      onClose={handleClose}
      TransitionProps={{
        onEntered: () => setHotkeyActive(false),
        onExited: () => setHotkeyActive(true),
      }}
    >
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack direction='column' gap={2}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              width='100%'
              mb={3}
            >
              <Box mb={2}>
                <img
                  width='100%'
                  src={SavedIllustration}
                  alt='not saved yet illustration'
                />
              </Box>
              <Typography variant='h4'>{t('inspection.savedDialog.title')}</Typography>
            </Box>
            <Box fontWeight='bold'> {t('inspection.savedDialog.subTitle')}</Box>
            <Box>{t('inspection.savedDialog.content')}</Box>
            <Box mt={1.5}>
              <TextField
                error={validationError}
                helperText={
                  validationError ? t('inspection.batchInput.helperText') : null
                }
                autoFocus
                fullWidth
                variant='filled'
                placeholder={t('home.batchSearchCard.placeholderText')}
                onChange={handleInputChange}
                value={searchedBatchRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <OrangeScanIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  className: 'batchRefInputField',
                }}
              />
            </Box>
            <Box
              borderBottom={1}
              sx={{
                borderColor: ({ rfhColors }) => rfhColors.grey[200],
              }}
              width='100%'
              mt={1.5}
            />
          </Stack>
        </DialogContent>
        <Box mb={4}>
          <BatchSavedDialogActions
            handleClose={handleClose}
            validationError={validationError}
          />
        </Box>
      </Form>
    </Dialog>
  )
}
