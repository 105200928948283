import { FC } from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useIsMutating } from '@tanstack/react-query'

type LoaderProps = {
  isLoading?: boolean
}

export const Loader: FC<LoaderProps> = ({ isLoading = false }) => {
  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      !options.mutationKey || !options.mutationKey.includes('uploadInspectionPhoto'),
  })

  return (
    <Backdrop
      sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      open={isLoading || isMutating > 0}
      aria-hidden={isLoading || isMutating === 0}
    >
      <CircularProgress />
    </Backdrop>
  )
}
