import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete/'
import { styled } from '@mui/material/styles'

export const EmptyIcon = styled(CloseIcon)<{ valuelength: number }>(
  ({ theme, valuelength }) => ({
    color: `${theme.rfhColors.black} !important`,
    cursor: valuelength > 0 ? 'pointer' : 'auto',
    opacity: valuelength > 0 ? 1 : 0,
  })
)

export const Form = styled('form')(() => ({
  '.MuiFormHelperText-root': {
    position: 'absolute !important',
    bottom: '-20px',
  },
}))

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: theme.rfhColors.white,
  '& .MuiButtonBase-root': {
    marginRight: '8px',
    svg: {
      height: '12px',
      width: '12px',
    },
  },
  '& .MuiFormHelperText-root': {
    zIndex: '1',
  },
}))
