import { useEffect, useState } from 'react'

export type UseOnlineOfflineType = () => {
  status: 'online' | 'offline'
  online: boolean
  offline: boolean
}

export const useOnlineOffline: UseOnlineOfflineType = () => {
  const initialOnlineStatus = window.navigator.onLine

  const [status, setStatus] = useState<'online' | 'offline'>(
    initialOnlineStatus ? 'online' : 'offline'
  )
  const [online, setOnline] = useState<boolean>(initialOnlineStatus)
  const [offline, setOffline] = useState<boolean>(!initialOnlineStatus)

  useEffect(() => {
    const updateOnlineStatus = () => {
      const currentOnlineStatus = window.navigator.onLine
      setStatus(currentOnlineStatus ? 'online' : 'offline')
      setOnline(currentOnlineStatus)
      setOffline(!currentOnlineStatus)
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  return {
    status,
    online,
    offline,
  }
}
