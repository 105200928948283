/* eslint-disable id-blacklist */
import { AxiosError } from 'axios'
import FileSaver from 'file-saver'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { redirect, useLocation, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { pdf } from '@react-pdf/renderer'
import { useMutation } from '@tanstack/react-query'

import { Layout } from 'src/components/Layout'
import { Loader } from 'src/components/Loader'
import { CreateReportDetails } from 'src/components/createReport/CreateReportDetails'
import VerticalStepper from 'src/components/createReport/VerticalStepper'
import { InspectionReport } from 'src/exporters/InspectionReport'
import { blobToBase64 } from 'src/exporters/helpers'
import { useNotification } from 'src/hooks/useNotification'
import { useUserInformation } from 'src/hooks/useUserInformation'
import {
  getInspectionRapportView,
  getCommentImageById,
  addPdfToInspectionRapport,
  AddAuditCommentPayload,
  postAuditComment,
  DeleteAuditCommentPayload,
  deleteAuditComment,
  ChangeInspectionFileRemarkPayload,
  changeInspectionFileRemark,
} from 'src/services/inspectionReportsService'
import type {
  InspectionRapportView,
  InspectionReportImages,
} from 'src/types/InspectionReport'

// prullenbak op hetzelfde hoogte als plusje
// bij het bijwerken van een comment ook een ronde ikoontje toevoegen
export const CreateReport: FC = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const location = useLocation()
  const account = useUserInformation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [report, setReport] = useState<InspectionRapportView>()
  const [imagesFromApi, setImagesFromApi] = useState<InspectionReportImages>({})
  const id = new URLSearchParams(location.search).get('keurverslagId')

  useEffect(() => {
    if (!id) {
      redirect('/inspection-reports')
    } else {
      getRapports()
    }
  }, [])

  const setIsEditingRemark = (editing: boolean) => {
    setIsEditing(editing)
  }

  const { mutateAsync } = useMutation({
    mutationFn: async (auditCommentPayload: AddAuditCommentPayload) => {
      const responseData = await postAuditComment(auditCommentPayload)
      if (auditCommentPayload.onAdded) {
        auditCommentPayload.onAdded()
      }
      return responseData.data
    },
    onSuccess: () => {
      getRapports(false)
    },
    onError: (error: AxiosError) => {
      showNotification([error.message], 'error')
    },
  })

  const { mutateAsync: mutateDelete } = useMutation({
    mutationFn: async (auditCommentPayload: DeleteAuditCommentPayload) =>
      await deleteAuditComment(auditCommentPayload),
    onSuccess: () => {
      getRapports(false)
    },
    onError: (error: AxiosError) => {
      showNotification([error.message], 'error')
    },
  })

  const { mutateAsync: mutateEdit } = useMutation({
    mutationFn: async (auditCommentPayload: ChangeInspectionFileRemarkPayload) =>
      await changeInspectionFileRemark(auditCommentPayload),
    onSuccess: () => {
      getRapports(false)
    },
    onError: (error: AxiosError) => {
      showNotification([error.message], 'error')
    },
  })

  const getRapports = async (showLoading = true) => {
    if (!id) {
      return
    }

    if (showLoading) {
      setIsLoading(true)
    }

    try {
      const { data } = await getInspectionRapportView(id, 'nl-NL')
      const images = await getImages(data)

      setReport(data)
      setImagesFromApi(images)
    } catch (error) {
      showNotification([t('createReport.errors.failedToFetchreports')], 'error')
      navigate('/inspection-reports')
    } finally {
      setIsLoading(false)
    }
  }

  const getImages = async (inspectionRapport: InspectionRapportView) => {
    const images: InspectionReportImages = {}
    const imageQueryStrings: string[] = []
    inspectionRapport.inspectionFiles.forEach(inspectionFile => {
      inspectionFile.audits.forEach(audit => {
        audit.comments.forEach(comment => {
          imageQueryStrings.push(comment.supplierImageQuery)
        })
      })
    })
    const actualImages = await Promise.all(
      imageQueryStrings.map(imageQueryString =>
        getCommentImageById(`${imageQueryString}&Breakpoint=md`).then(({ data }) => ({
          data,
          imageQueryString,
        }))
      )
    )

    actualImages.forEach(({ data, imageQueryString }) => {
      images[imageQueryString] = `data:image/jpeg;base64,${data}`
    })
    return images
  }
  async function generatePDF() {
    if (!id || !report || !account || !account.name || !account.email) {
      return
    }
    setIsLoading(true)
    const cultures = {
      NL: 'nl-NL',
      EN: 'en-GB',
      DE: 'de-DE',
    } as const
    const culture = cultures[report.language || 'NL']
    try {
      const { data: translatedReport } = await getInspectionRapportView(id, culture)
      const blob = await pdf(
        <InspectionReport
          inspectionReport={translatedReport}
          images={imagesFromApi}
          languageCode={report.language || 'NL'}
          name={account.name}
          email={account.email}
        />
      ).toBlob()
      const base64Pdf = await blobToBase64(blob)
      const { data } = await addPdfToInspectionRapport({
        supplierId: report.supplierId,
        inspectionRapportId: report.inspectionRapportId,
        fileExtension: 'pdf',
        image: base64Pdf.split(',')[1],
      })
      FileSaver.saveAs(blob, data.pdfId)
    } catch (err) {
      showNotification([t('createReport.errors.failedToGeneratePDF')], 'error')
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return <Loader isLoading={isLoading} />
  }
  if (!report) {
    return null
  }

  return (
    <Layout minHeight='calc(100vh - 145px)'>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ marginTop: 10 }}>
          <Typography variant='h3'>{t('createReport.title')}</Typography>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CreateReportDetails userData={report} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <VerticalStepper
            stepsData={report}
            imagesFromApi={imagesFromApi}
            addRemark={mutateAsync}
            isEditing={isEditing}
            setIsEditingRemark={setIsEditingRemark}
            deleteRemark={mutateDelete}
            editComment={mutateEdit}
          />
          <Box display='flex' justifyContent='flex-end' sx={{ pt: 4, pb: 4 }}>
            <Button
              disabled={!id || isLoading}
              onClick={generatePDF}
              variant='contained'
            >
              {isLoading
                ? t('createReport.buttons.generatingPDF')
                : t('createReport.buttons.generatePDF')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}
