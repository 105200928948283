import { Dispatch, FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import { BatchStatus } from 'src/types/ClockSupplyLine'

type BatchNotSavedDialogActionsProps = {
  batchStatusHandler: Dispatch<BatchStatus>
}

export const BatchNotSavedDialogActions: FC<BatchNotSavedDialogActionsProps> = ({
  batchStatusHandler,
}) => {
  const { t } = useTranslation()
  const { offline } = useOnlineOffline()

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-around' width='100%'>
      <Button
        variant='text'
        disabled={offline}
        onClick={() => batchStatusHandler(BatchStatus.Skipped)}
      >
        {t('inspection.batchActions.skipWithoutSaving')}
      </Button>
      <Button
        autoFocus={true}
        variant='contained'
        disabled={offline}
        onClick={() => batchStatusHandler(BatchStatus.Approved)}
      >
        {t('inspection.batchActions.next')}
      </Button>
    </Box>
  )
}
