import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { ChevronDownSm } from '@rfh-core/icons'
import RfhColors from '@rfh-core/theme'

import { Indicator } from 'src/components/Indicator'
import { FormFields } from 'src/components/InspectionReportsFormProvider'
import { useTableStore } from 'src/stores/TableStore'
import { CourtesyCodes } from 'src/types/CourtesyCodes'
import { ObservationFindingOverview, Rate } from 'src/types/ObservationFindings'
import { getFormattedDate } from 'src/utils/format'

import { Feature, useFeatureFlag } from '../../hooks/useFeatureFlag'
import { ObservationFindingsQiToggle } from './ObservationFindingsQiToggle'

const TableCellHeader = styled(Box)(() => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
}))

export const ObservationFindingsTableRow: FC<{
  row: ObservationFindingOverview
}> = observer(({ row }) => {
  const { t } = useTranslation()
  const { expandedRows, toggleRowExpansion } = useTableStore()
  const isExpanded = expandedRows.includes(row.id)
  const form = useFormContext<FormFields>()
  const isInspectionReports = !!form
  const hasCourtesy = ![0, CourtesyCodes.geen_coulance].includes(row.courtesy)

  const isBevindingenOverzichtMuterenQi = useFeatureFlag(
    Feature.BevindingenOverzichtMuterenQi
  )

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: isExpanded
            ? `${RfhColors.rfhColors.grey[100]} !important`
            : `${RfhColors.rfhColors.white} !important`,
        }}
      >
        <TableCell>
          {isInspectionReports ? (
            <Checkbox
              checked={(form.watch('observationId') || []).includes(
                `${row.observationFindingId}`
              )}
              onChange={() => {
                const isChecked = (form.watch('observationId') || []).includes(
                  `${row.observationFindingId}`
                )
                if (isChecked) {
                  form.setValue(
                    'observationId',
                    form
                      .getValues('observationId')
                      .filter(
                        observationIdValue =>
                          observationIdValue !== row.observationFindingId + ''
                      )
                  )
                } else {
                  form.setValue('observationId', [
                    ...form.getValues('observationId'),
                    row.observationFindingId + '',
                  ])
                }
              }}
              inputProps={{ className: 'observationFindingsTaBleCheckbox' }}
            />
          ) : (
            <IconButton
              aria-label='expand'
              size='small'
              onClick={() => toggleRowExpansion(row.id)}
            >
              <ChevronDownSm
                sx={{
                  transform: isExpanded ? 'none' : 'rotate(-90deg)',
                  fontSize: 'initial',
                  fill: theme => theme.rfhColors.black,
                }}
              />
            </IconButton>
          )}
        </TableCell>
        {isInspectionReports ? (
          <TableCell>{getFormattedDate(row.auctionDate)}</TableCell>
        ) : (
          <TableCell>{getFormattedDate(row.transactionDate)}</TableCell>
        )}

        <TableCell>
          {isInspectionReports && (
            <TextField
              {...form.register('supplierId', { required: true, minLength: 1 })}
              inputProps={{ className: 'observationFindingsTaBleTextField' }}
              value={row.supplierId}
              style={{ display: 'none' }}
            />
          )}
          {isInspectionReports ? row.supplierId : row.supplierNumber}
        </TableCell>
        <TableCell>{row.supplierName}</TableCell>
        <TableCell>
          <Link
            to={`/inspection/${((row.supplierNumber || row.supplierId) + '').padStart(
              6,
              '0'
            )}${row.supplyFormCode.padStart(6, '0')}${row.supplyFormCharacter}`}
            target='_blank'
            style={{
              color: 'inherit',
            }}
          >
            {`${row.supplyFormCode} ${row.supplyFormCharacter}`}
          </Link>
        </TableCell>
        <TableCell>{row.productName}</TableCell>

        <TableCell>
          {isInspectionReports ? row.lastAuditedBy : row.approvedBy}
        </TableCell>

        <TableCell>
          {isInspectionReports
            ? row.observationCategory
            : row.observationCategoryDescription}
        </TableCell>
        <TableCell>{row.salesOrderValueCode}</TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>
          {isInspectionReports ? row.qiDescription : row.qiClass}
        </TableCell>
        <TableCell>
          <Indicator value={hasCourtesy} />
        </TableCell>
        <TableCell>
          {isInspectionReports || !isBevindingenOverzichtMuterenQi ? (
            <Indicator value={row.isQiComponent} />
          ) : (
            <ObservationFindingsQiToggle row={row} />
          )}
        </TableCell>
      </TableRow>
      {!isInspectionReports && (
        <>
          <TableRow
            sx={{
              visibility: isExpanded ? 'visible' : 'collapse',
              backgroundColor: `${RfhColors.rfhColors.grey[50]} !important`,
              'td:first-of-type': {
                borderBottom: 'none',
              },
            }}
          >
            <TableCell></TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.location')}
              </TableCellHeader>
              <Box>{row.location}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.type')}
              </TableCellHeader>
              <Box>{row.rate === Rate.complaint ? 'Reclamatie' : 'Bevinding'}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.productCode')}
              </TableCellHeader>
              <Box>{row.productCode}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.product')}
              </TableCellHeader>
              <Box>{row.plantType}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.type')}
              </TableCellHeader>
              <Box>{row.observationTypeCode}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.observationCategory')}
              </TableCellHeader>
              <Box>{row.rate}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.characteristicCode')}
              </TableCellHeader>
              <Box>{row.dataField}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.originalValue')}
              </TableCellHeader>
              <Box>{row.originalValueCode}</Box>
            </TableCell>
            <TableCell>
              <TableCellHeader>
                {t('observationFindings.nestedTableHeaders.correctionValue')}
              </TableCellHeader>
              <Box>{row.correctionValueCode}</Box>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {hasCourtesy && (
            <TableRow
              sx={{
                visibility: isExpanded ? 'visible' : 'collapse',
                backgroundColor: `${RfhColors.rfhColors.grey[50]} !important`,
                'td:first-of-type': {
                  borderBottom: 'none',
                },
              }}
            >
              <TableCell></TableCell>
              <TableCell>
                <TableCellHeader>
                  {t('observationFindings.nestedTableHeaders.courtesyBy')}
                </TableCellHeader>
                <Box>{row.courtesyUser}</Box>
              </TableCell>
              <TableCell colSpan={10}>
                <TableCellHeader>
                  {t('observationFindings.nestedTableHeaders.courtesyDescription')}
                </TableCellHeader>
                <Box>{row.courtesyNote}</Box>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  )
})
