import { useQuery } from '@tanstack/react-query'

export const useTransparentBackground = (src: string) =>
  useQuery({
    queryKey: ['removeBackground', src],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const removeBackground = await import('@imgly/background-removal')
      const blob = await removeBackground.default(
        `${src}${src.startsWith('http') ? '?1=3' : ''}`
      )
      return URL.createObjectURL(blob)
    },
  })
