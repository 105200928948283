import { AxiosPromise } from 'axios'

import { useQuery } from '@tanstack/react-query'

import { api } from '../utils/api'

export type FeatureFlag = {
  isEnabled: boolean
  name: Feature
}

export enum Feature {
  PartijOpvragenSchermLezen = 'partijOpvragenSchermLezen',
  PartijOpvragenSchermMuteren = 'partijOpvragenSchermMuteren',
  PartijOpvragenSchermKeuren = 'partijOpvragenSchermKeuren',
  PartijOpvragenSchermToevoegenKlokFoto = 'partijOpvragenSchermToevoegenKlokFoto',
  BevindingenOverzichtLezen = 'bevindingenOverzichtLezen',
  BevindingenOverzichtMuterenQi = 'bevindingenOverzichtMuterenQi',
  KeurverslagenSchermLezen = 'keurverslagenSchermLezen',
  KeurverslagenSchermMuteren = 'keurverslagenSchermMuteren',
  FinancieelCorrectieOverzichtLezen = 'financieelCorrectieOverzichtLezen',
  FinancieelCorrectieOverzichtMuteren = 'financieelCorrectieOverzichtMuteren',
  QiValueScaleLezen = 'qiValueScaleLezen',
  QiValueScaleMuteren = 'qiValueScaleMuteren',
}

const getFeatures = (): AxiosPromise<FeatureFlag[]> => api.get('/api/v1/Features')

export const useFeatureFlag = (featureFlag: Feature): boolean => {
  const response = useQuery({
    queryKey: ['Features'],
    queryFn: getFeatures,
    staleTime: 1000 * 60 * 5,
    select: ({ data }) =>
      data.find(({ name }) => name === featureFlag)?.isEnabled ?? false,
  })

  return response.data ?? false
}

export const useFeatureFlags = (): Feature[] => {
  const response = useQuery({
    queryKey: ['Features'],
    queryFn: getFeatures,
    staleTime: 1000 * 60 * 5,
    select: ({ data }) =>
      data.filter(({ isEnabled }) => isEnabled).map(({ name }) => name),
  })

  return response.data ?? []
}
