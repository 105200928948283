import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, ReactNode, useContext } from 'react'

export interface BarcodeStore {
  isBarcodeScannerDisabled: boolean
  setIsBarcodeScannerDisabled: (disabled: boolean) => void
}

export const BarcodeStoreContext = createContext<BarcodeStore | null>(null)

export const initialState: BarcodeStore = {
  isBarcodeScannerDisabled: false,
  setIsBarcodeScannerDisabled(disabled) {
    this.isBarcodeScannerDisabled = disabled
  },
}

// Store as component
export const BarcodeStoreProvider: FC<{
  initial?: BarcodeStore
  children: ReactNode
}> = ({ children, initial = initialState }) => {
  const barcodeStore: BarcodeStore = useLocalObservable(() => initial)

  return (
    <BarcodeStoreContext.Provider value={barcodeStore}>
      {children}
    </BarcodeStoreContext.Provider>
  )
}

export const useBarcodeStore = (): BarcodeStore => {
  const store = useContext(BarcodeStoreContext)

  if (!store) {
    throw new Error('No barcode store defined')
  }

  return store
}
