import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

type AuctionDateDialogProps = {
  isOpen: boolean
  handleClose: () => void
}

export const AuctionDateDialog: FC<AuctionDateDialogProps> = ({
  isOpen,
  handleClose,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} maxWidth='xs' onClose={handleClose}>
      <DialogContent>
        <Stack direction='column' gap={2}>
          <Box
            role='button'
            aria-label='Sluit melding'
            position='absolute'
            right={12}
            top={12}
            onClick={handleClose}
          >
            <CloseIcon sx={{ width: '20px', height: '20px' }} />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            width='100%'
          >
            <Typography variant='h5'>
              {t('inspection.auctionDateDialog.title')}
            </Typography>
          </Box>
          <Box>{t('inspection.auctionDateDialog.content')}</Box>
          <Box
            borderBottom={1}
            sx={{
              borderColor: ({ rfhColors }) => rfhColors.grey[200],
            }}
            width='100%'
            mt={1.5}
          />
        </Stack>
      </DialogContent>
      <Box mb={4} mx={2}>
        <Button
          autoFocus
          sx={{ width: '100%' }}
          variant='contained'
          onClick={handleClose}
        >
          {t('inspection.auctionDateDialog.closeButtonText')}
        </Button>
      </Box>
    </Dialog>
  )
}
