import { AxiosError } from 'axios'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useMutation } from '@tanstack/react-query'

import { changeCourtesyStatus } from 'src/services/batchService'
import { ChangeCourtesyParams, ClockSupplyLine } from 'src/types/ClockSupplyLine'
import { CourtesyCodes, CourtesyCodesKey } from 'src/types/CourtesyCodes'

import { CourtesyDialog } from './CourtesyDialog'
import { CourtesySwitch } from './CourtesySwitch'

type CourtesyProps = {
  batch?: ClockSupplyLine
  disabled: boolean
}

export const CourtesyRow: FC<CourtesyProps> = ({ batch, disabled }) => {
  const { t } = useTranslation()

  const [{ courtesy, courtesyNote }, setCourtesy] = useState({
    courtesy: batch!.courtesy,
    courtesyNote: batch!.courtesyNote,
  })

  const hasCourtesy = (courtesy || batch!.courtesy) !== CourtesyCodes.geen_coulance

  const [isDialogOpen, setDialogIsOpen] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(hasCourtesy)
  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    mutateAsync,
    isError,
    error,
    isPending: isLoading,
    reset,
  } = useMutation({
    mutationFn: (params: ChangeCourtesyParams) =>
      changeCourtesyStatus(
        {
          clockSupplyLineId: params.clockSupplyLineId,
          courtesy: params.courtesy,
          courtesyNote: params.courtesyNote,
        },
        params.clockSupplyLineId
      ),
    onError: (_error: AxiosError) => {
      // this sets the correct error type
    },
  })

  const handleSubmit = async (value: CourtesyCodes, note?: string) => {
    try {
      await mutateAsync({
        clockSupplyLineId: Number(batch!.id),
        courtesy: value,
        courtesyNote: note,
      })
      setCourtesy({
        courtesy: value,
        courtesyNote: note,
      })
      reset()
      setDialogIsOpen(false)
    } catch (_error) {
      // prevent error from throwing inside component function
    }
  }

  const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked

    setChecked(isChecked)
    setDialogIsOpen(isChecked)

    if (!isChecked) {
      try {
        await mutateAsync({
          clockSupplyLineId: Number(batch!.id),
          courtesy: CourtesyCodes.geen_coulance,
        })
        setCourtesy({
          courtesy: CourtesyCodes.geen_coulance,
          courtesyNote: undefined,
        })
        reset()
        setDialogIsOpen(false)
      } catch (_error) {
        // prevent error from throwing inside component function
      }
    }
  }

  const handleClose = () => {
    setDialogIsOpen(false)
    setChecked(false)
  }

  useEffect(() => {
    switch (error?.response?.status) {
      case 400:
        setErrorMessage(t('courtesy.errorMessage.badRequest'))
        break
      case 500:
        setErrorMessage(t('courtesy.errorMessage.internalServerError'))
        break
      default:
        setErrorMessage(undefined)
    }
  }, [error])

  return (
    <>
      {!disabled && (
        <>
          <TableRow>
            <Box sx={{ my: 2 }}>
              <TableCell component='th'>{t('courtesy.switchLabel')}</TableCell>
            </Box>
            <TableCell align='right'>
              <CourtesySwitch
                disabled={disabled}
                checked={checked}
                isError={isError && !isDialogOpen}
                isLoading={isLoading && !isDialogOpen}
                errorMessage={errorMessage}
                handleChange={handleSwitchChange}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={2}
              sx={{ display: checked && hasCourtesy ? 'table-cell' : 'none' }}
            >
              <Box sx={{ display: 'block', width: '100%' }}>
                {checked && hasCourtesy && (
                  <>{t(`courtesy.codes.${courtesy as CourtesyCodesKey}`)}</>
                )}
                {checked &&
                  hasCourtesy &&
                  courtesy === CourtesyCodes.overig_namelijk && (
                    <>
                      <br />
                      <Typography
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          maxWidth: '230px',
                        }}
                        variant='body2'
                      >
                        {courtesyNote}
                      </Typography>
                    </>
                  )}
              </Box>
            </TableCell>
          </TableRow>

          <CourtesyDialog
            isOpen={isDialogOpen}
            isLoading={isLoading}
            isError={isError}
            errorMessage={errorMessage}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </>
      )}
      {disabled && (
        <TableRow>
          <TableCell component='th'>{t('courtesy.switchLabel')}</TableCell>
          <TableCell>{checked ? t('courtesy.yes') : t('courtesy.no')}</TableCell>
        </TableRow>
      )}
    </>
  )
}
