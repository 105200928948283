import { useParams } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from 'src/utils/api'

type Payload = {
  batchImageId: number
  clockSupplyLineId: number
  fileExtension: 'jpeg'
  image: string | undefined
}

type Props = {
  onSuccess?: () => void
}

export const useUpdateClockPhoto = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient()
  const { code } = useParams()

  return useMutation({
    mutationFn: (payload: Payload) =>
      api.post(
        `api/v1/ClockSupplyLines/${payload.clockSupplyLineId}/AddClockPhoto`,
        payload
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['batch', code] })
      onSuccess?.()
    },
  })
}
