import { FC, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import RfhColors from '@rfh-core/theme'

import { blobToBase64 } from 'src/exporters/helpers'
import type { AddAuditCommentPayload } from 'src/services/inspectionReportsService'
import type { InspectionFile } from 'src/types/InspectionReport'

export type PhotoData = {
  supplierImageId: string
  description: string
  isEditing: boolean
  onDelete: () => void
}

//TODO: Change to rfh core icon when it is available in core
const PhotoUploadIcon = ({ color }: { color?: string }) => (
  <svg
    style={{ width: '5%', height: '5%' }}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='22.8'
    viewBox='0 0 24 22.8'
  >
    <path
      d='M7.19,7.828A1.315,1.315,0,1,0,8.526,9.143,1.326,1.326,0,0,0,7.19,7.828ZM4.25,9.143a2.94,2.94,0,1,1,2.94,2.893A2.917,2.917,0,0,1,4.25,9.143Z'
      transform='translate(0.026 0.242)'
      fill={color || '#ff7200'}
      fillRule='evenodd'
    />
    <path
      d='M10.514,15.832a34.868,34.868,0,0,0-4.2,6.064.809.809,0,0,1-1.092.3.783.783,0,0,1-.306-1.075,36.4,36.4,0,0,1,4.4-6.341,10.992,10.992,0,0,1,4.417-3.2,6.045,6.045,0,0,1,4.617.27,13.035,13.035,0,0,1,4.388,3.679.781.781,0,0,1-.115,1.11.811.811,0,0,1-1.128-.113,11.493,11.493,0,0,0-3.822-3.246,4.421,4.421,0,0,0-3.415-.209A9.44,9.44,0,0,0,10.514,15.832Z'
      transform='translate(0.065 0.502)'
      fill={color || '#ff7200'}
      fillRule='evenodd'
    />
    <path
      d='M1.737,3.714A5.119,5.119,0,0,1,5.328,2.25H13.11a.789.789,0,1,1,0,1.578H5.328a3.5,3.5,0,0,0-2.457,1A3.392,3.392,0,0,0,1.854,7.248V17.769a3.392,3.392,0,0,0,1.018,2.418,3.5,3.5,0,0,0,2.457,1H18.156a3.5,3.5,0,0,0,2.457-1,3.392,3.392,0,0,0,1.018-2.418V12.508a.8.8,0,0,1,1.6,0v5.261A4.958,4.958,0,0,1,21.746,21.3a5.119,5.119,0,0,1-3.591,1.464H5.328A5.119,5.119,0,0,1,1.737,21.3,4.958,4.958,0,0,1,.25,17.769V7.248A4.958,4.958,0,0,1,1.737,3.714Z'
      transform='translate(-0.25 0.033)'
      fill={color || '#ff7200'}
      fillRule='evenodd'
    />
    <path
      d='M18.8.08a.8.8,0,0,1,.8.789V9.286a.8.8,0,0,1-1.6,0V.869A.8.8,0,0,1,18.8.08Z'
      transform='translate(0.975 -0.08)'
      fill={color || '#ff7200'}
      fillRule='evenodd'
    />
    <path
      d='M18.456.311a.811.811,0,0,1,1.134,0l3.421,3.367a.78.78,0,0,1,0,1.116.811.811,0,0,1-1.134,0L19.023,1.985,16.169,4.794a.811.811,0,0,1-1.134,0,.78.78,0,0,1,0-1.116Z'
      transform='translate(0.754 -0.08)'
      fill={color || '#ff7200'}
      fillRule='evenodd'
    />
  </svg>
)

export const ShowPhoto: FC<PhotoData> = ({
  supplierImageId,
  description,
  isEditing,
  onDelete,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          backgroundColor: RfhColors.rfhColors.grey[50],
        }}
      >
        <img
          alt={`${t('createReport.upload.preview')} ${description}`}
          src={supplierImageId}
          style={{
            width: '384px',
            height: '216px',
            objectFit: 'cover',
          }}
        />
        <Typography
          variant='body2'
          sx={{ padding: '10px', paddingTop: '0', width: '100%' }}
        >
          {description}
        </Typography>
      </Box>
      {isEditing ? (
        <Box
          sx={{
            paddingLeft: '8px',
          }}
        >
          <IconButton
            sx={{ width: '30px', height: '30px' }}
            className='outlined'
            onClick={onDelete}
            aria-label='deleteRemarkButton'
          >
            <Delete sx={{ fontSize: '20px' }} />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  )
}

type PhotoUploadProps = {
  addRemark: (object: AddAuditCommentPayload) => void
  stepData: InspectionFile
}

type FileWithPreview = File & { preview: string }

export const PhotoUpload: FC<PhotoUploadProps> = ({ addRemark, stepData }) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState<FileWithPreview[]>([])
  const [descriptions, setDescriptions] = useState<Record<string, string>>({})
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    maxFiles: 5,
    maxSize: 4000000, // 4mb
    onDrop: acceptedFiles => {
      const currentFileNames = files.map(file => file.name)
      const newFiles = acceptedFiles
        .filter(file => !currentFileNames.includes(file.name))
        .map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      setFiles([...files, ...newFiles])
      const emptyDescriptions: Record<string, string> = {}
      newFiles.forEach(file => {
        emptyDescriptions[file.name] = ''
      })
      setDescriptions(emptyDescriptions)
    },
  })

  function deleteDraftRemark(file: FileWithPreview) {
    URL.revokeObjectURL(file.preview)
    setFiles(files.filter(f => f.name !== file.name))
    const newDescriptions: Record<string, string> = {}
    Object.keys(descriptions).forEach(name => {
      if (name !== file.name) {
        newDescriptions[name] = descriptions[name]
      }
    })
    setDescriptions(newDescriptions)
  }

  const previews = files.map(file => (
    <Box
      sx={{ marginBottom: '10px', display: 'flex', flexDirection: 'row' }}
      key={file.name}
    >
      <Box>
        <img
          alt={`${t('createReport.upload.preview')} ${descriptions[file.name]}`}
          src={file.preview}
          style={{
            width: '384px',
            height: '216px',
            objectFit: 'cover',
          }}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </Box>
      <Box></Box>
      <TextField
        placeholder={t('createReport.stepper.photoDescription')}
        multiline
        rows={3}
        sx={{
          width: '100%',
          marginLeft: '10px',
        }}
        value={descriptions[file.name]}
        onChange={event => {
          setDescriptions({
            ...descriptions,
            [file.name]: event.target.value,
          })
        }}
      />
      <Box
        sx={{
          paddingLeft: '8px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          sx={{ width: '30px', height: '30px' }}
          className='outlined'
          onClick={() => {
            deleteDraftRemark(file)
          }}
          aria-label='deleteRemarkButton'
        >
          <Delete sx={{ fontSize: '20px' }} />
        </IconButton>
        <IconButton
          sx={{ width: '30px', height: '30px', marginTop: '8px' }}
          className='contained'
          size='small'
          aria-label='addButton'
          onClick={async () => {
            /* istanbul ignore next */
            const base64Jpg = await blobToBase64(file)
            addRemark({
              clockSupplyLineId: stepData.clockSupplyLineId,
              inspectionRapportId: stepData.inspectionRapportId,
              inspectionFileId: stepData.inspectionFileId,
              comment: descriptions[file.name],
              fileExtension: 'jpg',
              image: base64Jpg.split(',')[1],
              onAdded: () => deleteDraftRemark(file),
            })
          }}
        >
          <Add sx={{ fontSize: '20px' }} />
        </IconButton>
      </Box>
    </Box>
  ))

  useEffect(
    () =>
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      () =>
        files.forEach(file => URL.revokeObjectURL(file.preview)),
    []
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {previews}
      </Box>
      <Box>
        <Box
          {...getRootProps({
            'aria-label': 'Upload image',
            sx: {
              padding: '40px',
              border: isDragActive
                ? `solid 2px ${RfhColors.rfhColors.grey[800]}`
                : `dashed 1px ${RfhColors.rfhColors.grey[800]}`,
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              background: isFocused
                ? RfhColors.rfhColors.grey[100] || 'inherit'
                : 'inherit',
              '&:hover': {
                background: RfhColors.rfhColors.grey[100],
              },
            },
          })}
        >
          <input {...getInputProps()} />
          <PhotoUploadIcon
            color={
              isDragAccept
                ? RfhColors.rfhColors.hydroGreen
                : isDragReject
                ? RfhColors.rfhColors.alertRed
                : RfhColors.rfhColors.dutchOrange
            }
          />
          <Typography
            variant='body2'
            sx={{ color: RfhColors.rfhColors.grey[800], marginTop: '10px' }}
          >
            {isDragAccept
              ? t('createReport.upload.accepted')
              : isDragReject
              ? t('createReport.upload.rejected')
              : t('createReport.upload.initial')}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
