import { AxiosError } from 'axios'

import { Dispatch, SetStateAction, useState } from 'react'

import { getObservationsReport } from 'src/services/batchService'
import { GetObservationsReportResponse } from 'src/types/ObservationsReport'

const notFoundCode = 404

type useObservationsReportReturnType = {
  loading: boolean
  notFound: boolean
  observationsReport?: GetObservationsReportResponse
  error?: AxiosError
  getObservationsReportAsync: (accountNumber: number) => void
  setObservationsReport: Dispatch<
    SetStateAction<GetObservationsReportResponse | undefined>
  >
}

export const useObservationsReport = (): useObservationsReportReturnType => {
  const [loading, setLoading] = useState(false)
  const [observationsReport, setObservationsReport] =
    useState<GetObservationsReportResponse>()
  const [apiError, setApiError] = useState<AxiosError>()
  const [notFound, setNotFound] = useState(false)

  const getObservationsReportAsync = async (accountNumber: number) => {
    if (!loading) {
      setLoading(true)

      try {
        const { data: responseData } = await getObservationsReport(accountNumber)

        setObservationsReport(responseData)
        setNotFound(false)
        setApiError(undefined)
      } catch (error) {
        if ((error as AxiosError).response?.status === notFoundCode) {
          setNotFound(true)
        } else {
          setApiError(error as AxiosError)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    loading,
    notFound,
    error: apiError,
    observationsReport,
    getObservationsReportAsync,
    setObservationsReport,
  }
}
