import { FC } from 'react'

import Chip from '@mui/material/Chip'

type LeniencyIndicatorProps = {
  value: string
  heffing: string
  withMargin?: boolean
}

export const LeniencyIndicator: FC<LeniencyIndicatorProps> = ({
  value,
  heffing,
  withMargin = true,
}) => (
  <Chip
    size='small'
    sx={{
      ml: withMargin ? 2 : 0,
      fontWeight: 'bold',
    }}
    label={value}
    color={heffing === value ? 'info' : 'disabled'}
  />
)
