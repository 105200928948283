import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { CameraModal } from './CameraModal'
import { NotFound } from './NotFound'
import { Searching } from './Searching'
import { useBatchQuery } from './useBatchQuery'

export const BatchFinder = () => {
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const navigate = useNavigate()
  const { isError, isSuccess, isFetching } = useBatchQuery(code)
  const inputRef = useRef<HTMLInputElement>(null)

  // Navigating away is a sideEffect that should NOT happen
  // during the render phase hence the useEffect here
  useEffect(() => {
    if (isSuccess) {
      navigate(`/batch/${code}`)
    }
  }, [isSuccess])

  if (isCameraOpen) {
    return (
      <CameraModal
        onClose={() => {
          setIsCameraOpen(false)
        }}
      />
    )
  }

  return (
    <Stack direction='column' spacing={6} padding={4}>
      <Typography variant='h3'>{t('photoFeature.searchByCode')}</Typography>
      <Stack direction='row'>
        <TextField InputProps={{ inputRef }} fullWidth autoFocus />
        <IconButton
          aria-label='search'
          className='contained'
          sx={{ borderRadius: '0', height: '40px' }}
          onClick={() => {
            setCode(inputRef.current?.value ?? '')
          }}
        >
          <SearchIcon />
        </IconButton>
      </Stack>

      {isError && <NotFound code={code} />}
      {isFetching && <Searching code={code} />}

      <Divider />

      <Typography variant='h3'>{t('photoFeature.searchByScan')}</Typography>

      <Button
        fullWidth
        onClick={() => {
          setIsCameraOpen(previousState => !previousState)
        }}
      >
        {t('photoFeature.scanButton')}
      </Button>
    </Stack>
  )
}
