import { AxiosError } from 'axios'

import { useState } from 'react'

import { queryCharacteristicCodes } from 'src/services/characteristicsService'
import { BatchCharacteristic, Characteristic } from 'src/types/ClockSupplyLine'
import { ApiError, badRequestCode, notFoundCode } from 'src/types/StatusCodes'

type CharacteristicCodesReturntype = {
  loading: boolean
  characteristicCodes?: Characteristic[]
  apiError?: ApiError
  getCharacteristicCodesAsync: (
    currentCharacteristics: BatchCharacteristic[],
    searchQuery?: string
  ) => Promise<void>
}

export const useCharacteristicCodes = (): CharacteristicCodesReturntype => {
  const [loading, setLoading] = useState(false)
  const [characteristicCodes, setCharacteristicCodes] = useState<Characteristic[]>()
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const getCharacteristicCodesAsync = async (
    currentCharacteristics: BatchCharacteristic[],
    searchQuery?: string
  ) => {
    if (!loading) {
      setLoading(true)
      setCharacteristicCodes([])
      try {
        const { data: responseData } = await queryCharacteristicCodes(
          currentCharacteristics,
          searchQuery
        )

        setCharacteristicCodes(responseData.value)
        setApiError(undefined)
      } catch (error) {
        if ((error as AxiosError).response?.status === notFoundCode) {
          setApiError(ApiError.notFound)
        } else if ((error as AxiosError).response?.status === badRequestCode) {
          setApiError(ApiError.badRequest)
        } else {
          setApiError(ApiError.generalFailure)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return { loading, apiError, characteristicCodes, getCharacteristicCodesAsync }
}
