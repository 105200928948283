import { AxiosError } from 'axios'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { ChevronDownSm } from '@rfh-core/icons'

import { StyledAutocomplete } from 'src/components/batch/BatchInput.styles'
import { StyledCharacteristicBullet } from 'src/components/styles/InformationSection.styles'
import { useCharacteristicCodes } from 'src/hooks/useCharacteristicCodes'
import { useCharacteristicValues } from 'src/hooks/useCharacteristicValues'
import { useMutate } from 'src/hooks/useMutate'
import {
  addBatchCharacteristic,
  changeBatchCharacteristic,
  removeBatchCharacteristic,
} from 'src/services/characteristicsService'
import {
  BatchCharacteristic,
  Characteristic,
  CharacteristicValueCode,
  ClockSupplyLine,
} from 'src/types/ClockSupplyLine'
import { badRequestCode } from 'src/types/StatusCodes'
import {
  filterNormalCharacteristics,
  filterRegulatoryCharacteristics,
  sortCharacteristics,
} from 'src/utils/characteristic'

import { AutocompletePopper } from './AutoCompletePopper'
import { StyledTableContainerCharacteristics } from './styles/characteristics.styles'

type CharacteristicsProps = {
  batch: ClockSupplyLine
  setMutatedBatch: (batch: ClockSupplyLine) => void
}

type Row = {
  values: (string | number | JSX.Element)[]
  header?: boolean
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.rfhColors.leafGreen,
    color: theme.rfhColors.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    textAlign: 'center',
    minWidth: '50px',
  },
  [`& .${tooltipClasses.tooltip}:first-letter`]: { textTransform: 'uppercase' },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.rfhColors.leafGreen,
  },
}))

export const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option =>
    (option as CharacteristicValueCode).valueCode +
    (option as CharacteristicValueCode).value,
})

export const Characteristics: FC<CharacteristicsProps> = ({
  batch,
  setMutatedBatch,
}) => {
  const { t } = useTranslation()

  const [generalError, setGeneralError] = useState<boolean>(false)
  const [activeCode, setActiveCode] = useState<string>('')
  const [mutationLoading, setMutationLoading] = useState(false)
  const [mutatedItem, setMutatedItem] = useState<BatchCharacteristic>()
  const [characteristic, setCharacteristic] = useState<Characteristic>()
  const [addMutationError, setAddMutationError] = useState<boolean>(false)
  const [valueCode, setValueCode] = useState<string>('')
  const [inputValue, setInputValue] = useState<string | undefined>()

  const {
    getCharacteristicValuesAsync,
    characteristicValues,
    loading: valuesLoading,
  } = useCharacteristicValues()

  const {
    getCharacteristicCodesAsync,
    characteristicCodes,
    loading: codesLoading,
  } = useCharacteristicCodes()

  const { mutatingActive } = useMutate()

  const resetError = () => {
    setGeneralError(false)
  }
  useEffect(() => {
    getCharacteristicValuesAsync(activeCode)
  }, [activeCode])

  useEffect(() => {
    mutatingActive &&
      getCharacteristicCodesAsync(batch.batchCharacteristics || [], inputValue)
  }, [mutatingActive, batch, inputValue])

  const handleCharacteristicCodeChange = (item: Characteristic) => {
    setCharacteristic(item)
  }

  const handleValueCodeChange = async (value: string, item: BatchCharacteristic) => {
    resetError()

    const params = {
      batchCharacteristicId: Number(item.id),
      clockSupplyLineId: Number(batch.id),
      valueCode: value,
    }

    setMutatedItem(item)
    setMutationLoading(true)

    const characteristics = [...batch.batchCharacteristics!]
    const target = characteristics.findIndex(current => current === item)

    try {
      const { data: responseData } = await changeBatchCharacteristic(params, item.id)
      characteristics[target] = responseData
      setMutatedBatch({ ...batch, batchCharacteristics: characteristics })
    } catch (error) {
      if ((error as AxiosError).response?.status === badRequestCode) {
        characteristics[target].error = true
        characteristics[target].valueCode = value
        setMutatedBatch({ ...batch, batchCharacteristics: characteristics })
      } else {
        setGeneralError(true)
      }
    } finally {
      setMutationLoading(false)
      setMutatedItem(undefined)
    }
  }

  const handleRowRemove = async (item: BatchCharacteristic) => {
    const params = {
      batchCharacteristicId: Number(item.id),
      clockSupplyLineId: Number(batch.id),
    }
    setMutatedItem(item)
    setMutationLoading(true)
    resetError()
    try {
      await removeBatchCharacteristic(params, item.id)
      setMutatedBatch({
        ...batch,
        batchCharacteristics: batch.batchCharacteristics!.filter(
          current => current !== item
        ),
      })
    } catch (error) {
      setGeneralError(true)
    } finally {
      setMutationLoading(false)
      setMutatedItem(undefined)
    }
  }

  const handleRowCreate = async () => {
    const params = {
      characteristicCode: characteristic!.code,
      valueCode,
      clockSupplyLineId: Number(batch.id),
    }
    setMutationLoading(true)
    resetError()
    try {
      const { data: responseData } = await addBatchCharacteristic(params)
      setMutatedBatch({
        ...batch,
        batchCharacteristics: batch.batchCharacteristics!.concat(responseData),
      })
      setAddMutationError(false)
      setCharacteristic(undefined)
      setValueCode('')
    } catch (error) {
      /* istanbul ignore next */
      if ((error as AxiosError).response?.status === badRequestCode) {
        setAddMutationError(true)
      } else {
        setGeneralError(true)
      }
    } finally {
      setMutationLoading(false)
    }
  }

  const rowsWithValueCode999: Record<number, boolean> = {}

  const transformToRow = (
    item: BatchCharacteristic,
    index: number,
    offset = 0
  ): Row => {
    if (item.valueCode === '999' && mutatingActive) {
      /* istanbul ignore next */
      rowsWithValueCode999[index + offset] = true
    }
    return {
      values: [
        <StyledCharacteristicBullet sortingCode={item.sortingCode} />,
        item.characteristicCode,
        item.characteristic?.description || '',
        mutatingActive ? (
          <StyledAutocomplete
            PopperComponent={AutocompletePopper}
            disabled={item.isReadonly}
            popupIcon={<ChevronDownSm />}
            disableClearable={true}
            loading={valuesLoading}
            loadingText='laden...'
            onFocus={() => setActiveCode(item.characteristicCode)}
            options={characteristicValues || []}
            getOptionLabel={option => (option as CharacteristicValueCode).valueCode}
            renderOption={(props, option) => (
              <li {...props}>
                {(option as CharacteristicValueCode).valueCode} -{' '}
                {(option as CharacteristicValueCode).value}
              </li>
            )}
            filterOptions={filterOptions}
            value={item}
            onChange={(_, newValue) => {
              handleValueCodeChange(
                (newValue as CharacteristicValueCode).valueCode,
                item
              )
            }}
            autoSelect={false}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                sx={
                  /* istanbul ignore next */
                  item.valueCode === '999'
                    ? theme => ({
                        '& fieldset': {
                          borderColor: theme.rfhColors.alertRed,
                          borderWidth: '2px',
                        },
                        '> div:hover > fieldset': {
                          borderColor: `${theme.rfhColors.alertRed} !important`,
                          borderWidth: '2px !important',
                        },
                      })
                    : null
                }
                error={item.error}
                helperText={item.error && t('queryState.badRequest.message')}
                inputProps={{
                  ...params.inputProps,
                  'aria-label': `autocomplete-${item.characteristicCode}`,
                }}
              />
            )}
          />
        ) : (
          <LightTooltip
            title={item.characteristicValue!.value!}
            arrow
            placement='right'
          >
            <span style={{ cursor: 'pointer' }}>{item.valueCode}</span>
          </LightTooltip>
        ),

        mutationLoading && item === mutatedItem ? (
          <Box display='flex' alignItems='center'>
            <CircularProgress aria-label={t('queryState.loading.label')} />
          </Box>
        ) : (
          <>
            {mutatingActive && item.sortingCode === 0 && (
              <IconButton
                sx={{
                  svg: {
                    width: '16px',
                    height: '16px',
                  },
                }}
                className='outlined'
                onClick={() => handleRowRemove(item)}
                disabled={item.isReadonly}
                aria-label='removeButton'
              >
                <Delete />
              </IconButton>
            )}
          </>
        ),
      ],
    }
  }

  const emptyRow: Row = {
    values: [
      <StyledCharacteristicBullet sortingCode={0} />,
      <StyledAutocomplete
        popupIcon={<ChevronDownSm />}
        disableClearable={true}
        loading={codesLoading}
        loadingText='laden...'
        options={characteristicCodes || []}
        getOptionLabel={option => (option as Characteristic).code}
        onChange={(_, newValue) => {
          handleCharacteristicCodeChange(newValue as Characteristic)
        }}
        autoSelect
        inputValue={inputValue}
        onInputChange={(_, newValue) => setInputValue(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={addMutationError}
            helperText={addMutationError && t('queryState.badRequest.message')}
          />
        )}
      />,
      <>{characteristic?.description}</>,
      <StyledAutocomplete
        PopperComponent={AutocompletePopper}
        popupIcon={<ChevronDownSm />}
        disableClearable={true}
        loading={valuesLoading}
        loadingText='laden...'
        onFocus={() => setActiveCode(characteristic!.code)}
        options={characteristicValues || []}
        getOptionLabel={option => (option as CharacteristicValueCode).valueCode}
        renderOption={(props, option) => (
          <li {...props}>
            {(option as CharacteristicValueCode).valueCode} -{' '}
            {(option as CharacteristicValueCode).value}
          </li>
        )}
        filterOptions={filterOptions}
        onChange={(_, newValue) =>
          setValueCode((newValue as CharacteristicValueCode).valueCode)
        }
        autoSelect
        autoHighlight
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            error={addMutationError}
            helperText={addMutationError && t('queryState.badRequest.message')}
          />
        )}
      />,

      mutationLoading && !mutatedItem ? (
        <Box display='flex' alignItems='center'>
          <CircularProgress aria-label={t('queryState.loading.label')} />
        </Box>
      ) : (
        <IconButton
          sx={{
            svg: {
              width: '16px',
              height: '16px',
            },
          }}
          className='outlined'
          onClick={() => handleRowCreate()}
          disabled={!valueCode || !characteristic?.code}
          aria-label='addButton'
        >
          <Add />
        </IconButton>
      ),
    ],
  }

  const regulatoryCharacteristics: Row[] = [...batch.batchCharacteristics!]
    .filter(filterRegulatoryCharacteristics)
    .sort(sortCharacteristics)
    /* istanbul ignore next */
    .map((r, i) => transformToRow(r, i, 0))

  const offset = regulatoryCharacteristics.length

  const normalCharacteristics = [...batch.batchCharacteristics!]
    .filter(filterNormalCharacteristics)
    /* istanbul ignore next */
    .map((r, i) => transformToRow(r, i, offset))

  const characteristics = [
    ...regulatoryCharacteristics,
    ...normalCharacteristics,
  ].concat(mutatingActive ? emptyRow : [])

  return (
    <>
      {generalError && (
        <FormHelperText error>
          {t('queryState.generalFailureMutation.message')}
        </FormHelperText>
      )}
      <StyledTableContainerCharacteristics>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                {t('inspection.labels.characteristics')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {characteristics.length > 0 ? (
              characteristics.map((characteristicValue, index) => (
                <TableRow key={index}>
                  {characteristicValue.values.map((value, valueIndex) => (
                    <TableCell
                      size='small'
                      key={valueIndex}
                      style={{ verticalAlign: 'top' }}
                      sx={
                        rowsWithValueCode999[index]
                          ? theme => ({
                              backgroundColor: theme.rfhColors.alertRedShade,
                            })
                          : null
                      }
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>{t('inspection.noResults')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainerCharacteristics>
    </>
  )
}
