import { DateRange } from '@mui/x-date-pickers-pro'

import { CourtesyCodes } from 'src/types/CourtesyCodes'

import { Supplier } from './Supplier'

export type ObservationFindingOverview = {
  inspectionFileId: string
  approvedBy?: string
  approvedOn?: string
  batchReference?: string
  correctionValueCode?: string
  courtesy: CourtesyCodes
  courtesyNote?: string
  courtesyUser?: string
  id: number
  observationCategoryCode?: number
  observationCategoryDescription?: string
  observationTypeCode?: string
  observationTypeDescription?: string
  originalValueCode?: string
  productCode?: string
  productName?: string
  qiClass?: QiClass
  salesOrderValueCode?: string
  plantType?: PlantType
  supplierName: string
  supplierNumber: number
  supplyFormCharacter: string
  supplyFormCode: string
  transactionDate: string
  rate?: Rate
  isSanctioned?: boolean
  location?: string
  auctiongroupCode?: number
  clockSupplyLineId?: number
  dataField?: string
  isQiComponent: boolean
  observationFindingId: number
  supplierId: number
  auctionDate: string
  lastAuditedBy?: string
  observationCategory: string
  qiDescription: string
}

export type ObservationFindingsSearchInput = {
  type?: ChangeType[]
  location?: SearchLocations[]
  product?: string
  supplier?: Pick<Supplier, 'clientNumber' | 'name'>
  approvedBy?: string
  plantType?: PlantType[]
  dateRange?: DateRange<Date> | Date[]
  date?: Date
}

export enum SearchLocations {
  Aalsmeer = 'Aalsmeer',
  Naaldwijk = 'Naaldwijk',
  Rijnsburg = 'Rijnsburg',
  Eelde = 'Eelde',
}

export enum ChangeType {
  bevinding = 'Bevinding',
  reclamatie = 'Reclamatie',
}

export enum QiClass {
  none = 'none',
  a = 'a',
  b = 'b',
  c = 'c',
}

export enum PlantType {
  bloemen = 'Bloemen',
  planten = 'Planten',
}

export enum Rate {
  none = 'geen',
  low = 'laag',
  middle = 'midden',
  high = 'hoog',
  complaint = 'reclamatie',
}
export enum Locations {
  unknown = 'Unknown',
  aalsmeer = 'Aalsmeer',
  naaldwijk = 'Naaldwijk',
  rijnsburg = 'Rijnsburg',
  eelde = 'Eelde',
  rheinMaas = 'RheinMaas',
  rfHconcern = 'RfHconcern',
}
