import { AxiosPromise } from 'axios'

import type {
  ClockSupplyLine,
  BatchStatus,
  ChangePiecesPerPackageParams,
  BatchImage,
  ChangeCourtesyParams,
  BatchCharacteristic,
} from 'src/types/ClockSupplyLine'
import { Command } from 'src/types/Command'
import type { GetObservationsReportResponse } from 'src/types/ObservationsReport'
import { api } from 'src/utils/api'

export const getBatch = (id: string): AxiosPromise<ClockSupplyLine> =>
  api.get(`/api/v1/ClockSupplyLines/${id}`)

export const queryBatch = (batchRef: string): AxiosPromise<ClockSupplyLine> =>
  api.get(`/api/v1/ClockSupplyLines/GetByEanScan(eanCode=${batchRef})`)

export const addBatchStatus = (
  batchStatus: BatchStatus,
  id: number
): AxiosPromise<void> =>
  api.post(`/api/v1/AuditAssignments/Commands/PostDefaultStatus/${id}`, batchStatus, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const getObservationsReport = (
  AccountNumber: number,
  IsoLanguageCode = 'nl'
): AxiosPromise<GetObservationsReportResponse> =>
  api.get('/api/v1/Observations/GetReport', {
    params: { AccountNumber, IsoLanguageCode },
  })

export const changePiecesPerPackage = (
  params: ChangePiecesPerPackageParams,
  id: number
): AxiosPromise<Command> =>
  api.post(`/api/v1/ClockSupplyLines/Commands/ChangePiecesPerPackage/${id}`, params)

export const changeCourtesyStatus = (
  params: ChangeCourtesyParams,
  id: number
): AxiosPromise<Command> =>
  api.post(`/api/v1/ClockSupplyLines/Commands/ChangeCourtesyStatus/${id}`, params)

export const changeBatchImage = (
  batchImage: BatchImage,
  newSupplierImageId: string
): AxiosPromise<Command> =>
  api.post(`/api/v1/BatchImages/Commands/ChangeSupplierImage/${batchImage.id}`, {
    batchImageId: batchImage.id,
    clockSupplyLineId: batchImage.clockSupplyLineId,
    supplierImageId: newSupplierImageId,
  })

export const getBatchCharacteristics = (
  id: number
): AxiosPromise<BatchCharacteristic[]> =>
  api.get(`/api/v1/ClockSupplyLines/${id}/BatchCharacteristics/`)
