import 'src/components/styles/EmptyState/EmptyStateIcon.css'

export const EmptyStateIcon = (): JSX.Element => (
  <svg
    aria-labelledby='empty-state-icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='100 0 575 370'
  >
    <title id='empty-state-icon'>a magnifying glass with an open book</title>
    <path
      className='EmptyStateIcon__book'
      d='M649,113.15H290.61a19.22,19.22,0,0,0-19.2,19.2v211.2a19.22,19.22,0,0,0,19.2,19.2H649a19.22,19.22,0,0,0,19.2-19.2V132.35a19.22,19.22,0,0,0-19.2-19.2Zm0,0'
    />
    <path
      className='EmptyStateIcon__page'
      d='M642.71,315.1v19.2a6.4,6.4,0,0,1-6.4,6.4H303.51a6.4,6.4,0,0,1-6.4-6.4V315.1l38.23-19.2,153.6,6.4,128-6.4Zm0,0'
    />
    <path
      className='EmptyStateIcon__page'
      d='M306.09,323.94a202.93,202.93,0,0,1,161,2.65,2,2,0,0,0,2.83-1.81V97.5a202.94,202.94,0,0,0-169-1.74,6.49,6.49,0,0,0-3.8,5.91V318.09a6.43,6.43,0,0,0,9,5.85Zm0,0'
    />
    <path
      className='EmptyStateIcon__page'
      d='M469.91,327.9a202.93,202.93,0,0,1,163.82-4,6.43,6.43,0,0,0,9-5.85V101.67a6.49,6.49,0,0,0-3.8-5.91,202.94,202.94,0,0,0-169,1.74Zm0,0'
    />
    <path
      className='EmptyStateIcon__text'
      d='M448.07,145.14a320.89,320.89,0,0,0-129.12,0,4.3,4.3,0,0,1-5.44-4.33h0a7.14,7.14,0,0,1,5.44-6.67,320.89,320.89,0,0,1,129.12,0,7.14,7.14,0,0,1,5.44,6.67h0A4.31,4.31,0,0,1,448.07,145.14Z'
    />
    <path
      className='EmptyStateIcon__text'
      d='M448.56,175.14a320.89,320.89,0,0,0-129.12,0A4.3,4.3,0,0,1,314,170.8h0a7.14,7.14,0,0,1,5.44-6.67,320.89,320.89,0,0,1,129.12,0A7.14,7.14,0,0,1,454,170.8h0A4.3,4.3,0,0,1,448.56,175.14Z'
    />
    <path
      className='EmptyStateIcon__text'
      d='M448.56,205.14a320.89,320.89,0,0,0-129.12,0A4.3,4.3,0,0,1,314,200.8h0a7.14,7.14,0,0,1,5.44-6.67,320.89,320.89,0,0,1,129.12,0A7.14,7.14,0,0,1,454,200.8h0A4.3,4.3,0,0,1,448.56,205.14Z'
    />
    <path
      className='EmptyStateIcon__plant'
      d='M591.17,168.13c3.45-8.92,7.37-13.47,7.4-13.5a3.24,3.24,0,0,0,.18-3.66,4.14,4.14,0,0,0-3.33-2.26c-10.15-.4-17.86,5.28-22.6,10.14,5.49-19,13.73-31.26,17.32-35.83A3,3,0,0,0,591,121a4.2,4.2,0,0,0-2.42-3.46,4.58,4.58,0,0,0-4.38-.11c-9.65,5.75-17.49,14.46-23.22,25.23a84.81,84.81,0,0,0-4.11,9c-4.64-11.24-12.22-24.66-23.49-30.42a3.4,3.4,0,0,0-3.86.7,3.57,3.57,0,0,0-1,3.85,164.89,164.89,0,0,1,8.86,35.12,31.1,31.1,0,0,0-25.73-6.6,4.31,4.31,0,0,0-3.21,3,2.8,2.8,0,0,0,1.25,3.49h0c.8.11,20.6,10.94,19.93,42.72q-4.16.48-8.29,1.13a4.84,4.84,0,0,0-3.94,5.24l7,46.43a4.53,4.53,0,0,0,.19,1.12l-2.39.35a6.68,6.68,0,0,0-5.44,6.37,4.52,4.52,0,0,0,5.44,4.55,208.27,208.27,0,0,1,70.35,1.25,4.33,4.33,0,0,0,5.41-4.36,7,7,0,0,0-5.41-6.56l-2.38-.44a4.34,4.34,0,0,0,.18-1.11l6.93-46.18a5.05,5.05,0,0,0-3.92-5.38q-3.65-.71-7.31-1.28C585,188.25,588.2,175.79,591.17,168.13Z'
    />
    <rect
      className='EmptyStateIcon__handle'
      x='212.28'
      y='160.25'
      width='17.46'
      height='74.36'
      rx='8.73'
      ry='8.73'
      transform='translate(204.33 -98.45) rotate(45)'
    />
    <path
      className='EmptyStateIcon__glass'
      d='M390.15,33a106,106,0,1,0,0,149.89A106,106,0,0,0,390.15,33ZM252.7,170.49a88.39,88.39,0,1,1,125,0A88.39,88.39,0,0,1,252.7,170.49Z'
    />
    <rect
      className='EmptyStateIcon__handle'
      x='142.2'
      y='174.49'
      width='46.84'
      height='156.65'
      rx='23.42'
      ry='23.42'
      transform='translate(227.27 -43.07) rotate(45)'
    />
    <path className='EmptyStateIcon__handle' d='M209,221.55l-40.88,40.88' />
    <path className='EmptyStateIcon__handle' d='M162.39,267.93l-11.31,11.31' />
  </svg>
)
