export const translations = {
  NL: {
    qualityReport: 'Kwaliteitsrapportage',
    controlAndGuidance: 'Controle & Begeleiding',
    details: 'Details',
    growerName: 'Aanvoerder',
    growerNumber: 'Aanv. Nummer',
    date: 'Datum',
    qiScore: 'QI-Klasse',
    lotNumber: 'Partij ID',
    product: 'Productomschrijving',
    location: 'Locatie',
    coulance: 'Coulance',
    reason: 'Reden',
    corrections: 'Kwaliteitsaanpassing',
    originalValue: 'Aangeboden waarde',
    adjustedValue: 'Correctie waarde',
    class: 'Heffingcategorie',
    remarks: 'Opmerking',
    qi: 'QI',
    yes: 'Ja',
    no: 'Nee',
    photo: 'Foto',
    page: 'bladzijde',
    until: 'tot en met',
    employee: 'Medewerker controle en begeleiding',
    info: 'Ter informatie',
    paragraph1:
      'Bij het keuren van de vermelde partijen zijn er afwijkingen op de partij informatie geconstateerd. Deze aanpassingen zullen mogelijk leiden tot een heffing volgens het programma Belonen & Handhaven. Ook hebben deze aanpassingen mogelijke gevolgen voor uw berekende QI-score.',
    paragraph2:
      'Voor meer informatie kunt u terecht op onze kwaliteitspagina op de website van Royal FloraHolland. Hier vind u ook links naar de VBN website, onze aanvoervoorschriften, RFH Photo en het keurverzoek:',
    url: 'https://www.royalfloraholland.com/aanvoeren/marktplaats/veilen-planten-bloemen',
    title: 'Royal FloraHolland | Planten bloemen veilen',
    kindRegards: 'Met vriendelijke groet,',
  },
  EN: {
    qualityReport: 'Quality Report',
    controlAndGuidance: 'Control & Guidance',
    details: 'Details',
    growerName: 'Grower name',
    growerNumber: 'Grower number',
    date: 'Date',
    qiScore: 'QI-score',
    lotNumber: 'Lotnumber',
    product: 'Product',
    location: 'Location',
    coulance: 'Coulance',
    reason: 'Reason',
    corrections: 'Correction(s)',
    originalValue: 'Original value',
    adjustedValue: 'Adjusted value',
    class: 'Class',
    remarks: 'Remarks',
    qi: 'QI',
    yes: 'Yes',
    no: 'No',
    photo: 'Photo',
    page: 'page',
    until: 'until',
    employee: 'Team Control & Guidance',
    info: 'For your information',
    paragraph1:
      // eslint-disable-next-line @typescript-eslint/quotes
      "While inspection your supply we had to adjust the listed lots. According to the guidelines of 'Belonen & Handhaven' there are costs associated with these corrections. Furthermore, these corrections will also have an effect on your current QI - score.",
    paragraph2:
      'Please visit our Web pages on the RoyalFlora Holland website. Here you will find links to the VBN website, supply regulations, RFH Photo and the inspection request:',
    url: 'https://www.royalfloraholland.com/en/supplying/marketplace/auctioning-flowers-and-plants',
    title: 'Royal FloraHolland | Auctioning flowers and plants',
    kindRegards: 'Kind regards,',
  },
  DE: {
    qualityReport: 'Änderungsbericht',
    controlAndGuidance: 'Kontrolle & Begleitung',
    details: 'Einzelheiten',
    growerName: 'Name',
    growerNumber: 'Lieferant',
    date: 'Datum',
    qiScore: 'QI-Punktzahl',
    lotNumber: 'Partienummer',
    product: 'Produkt',
    location: 'Standort',
    coulance: 'Coulance',
    reason: 'Grund',
    corrections: 'Korrekturen',
    originalValue: 'Originalwert',
    adjustedValue: 'Korrigierter Wert',
    class: 'Klasse',
    remarks: 'Beratung',
    qi: 'QI',
    yes: 'Ja',
    no: 'Nein',
    photo: 'Bild',
    page: 'seite',
    until: 'bis',
    employee: 'Mitarbeiter Kontrolle & Begleitung',
    info: 'Information',
    paragraph1:
      'Bei der Prüfung Ihres Angebots mussten wir Korrekturen an den Partie informationen vornehmen. Im Rahmen des Belonen & Handhaven Programms sind damit Kosten verbunden. Die Anpassungen haben auch Auswirkungen auf den berechneten QI-Punktzahl.',
    paragraph2:
      'Weitere Informationen finden Sie auf unserer Qualitätsseite auf der Homepage von Royal FloraHolland. Hier finden Sie Links zur VBN-Homepage, Informationen über Anliefervorschriften, Vorschriften für Produktfotos und Antrag auf eine Qualitätsprüfung:',
    url: 'https://www.royalfloraholland.com/de/anlieferung/marktplatz/blumen-und-pflanzen-versteigern',
    title: 'Royal FloraHolland | Blumen und Pflanzen versteigern',
    kindRegards: 'Mit freundlichem Gruß,',
  },
}
