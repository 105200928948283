/* eslint-disable id-blacklist */
import { observer } from 'mobx-react-lite'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { nlNL } from '@mui/x-data-grid'
import { useOktaAuth } from '@okta/okta-react'

import {
  FinanceSettlementSearchForm,
  defaultValues,
} from 'src/components/FinanceSettlement/FinanceSettlementSearchForm'
import { FinanceSettlementTable } from 'src/components/FinanceSettlement/FinanceSettlementTable'
import { Layout } from 'src/components/Layout'
import { PageTabs } from 'src/components/PageTabs'
import { getFinanceSettlement } from 'src/services/financeSettlementService'
import {
  FinanceSettlementOverview,
  FinanceSettlementSearchInput,
} from 'src/types/financeSettlement'
import { ODataResponse } from 'src/types/odata'

export const FinanceSettlement: FC = observer(() => {
  const { t } = useTranslation()
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ODataResponse<FinanceSettlementOverview[]>>()
  useOktaAuth()

  const [searchValues, setSearchValues] =
    useState<FinanceSettlementSearchInput>(defaultValues)

  const fetchData = async (
    values: FinanceSettlementSearchInput,
    pageSize: number,
    currentPage: number
  ) => {
    setLoading(true)

    try {
      const { data: responseData } = await getFinanceSettlement(
        values,
        pageSize,
        currentPage
      )
      setData(responseData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(defaultValues, rowsPerPage, page)
  }, [])

  const handleSubmit = (formValues: FinanceSettlementSearchInput) => {
    setPage(0)
    setSearchValues(formValues)
    fetchData(formValues, rowsPerPage, 0)
  }

  const handlePageChange = (currentPage: number) => {
    setPage(currentPage)
    fetchData(searchValues, rowsPerPage, currentPage)
  }

  const handleRowsPerPageChange = (currentRowsPerPage: number) => {
    setRowsPerPage(currentRowsPerPage)
    fetchData(searchValues, currentRowsPerPage, page)
  }

  return (
    <Layout hasDrawer={true}>
      <Container
        sx={{
          flexGrow: 1,
          maxWidth: '1536px',
          mt: 8,
          px: '0px !important',
        }}
        maxWidth={false}
      >
        <Typography variant='h3'>{t('FinanceSettlement.title')}</Typography>
        <PageTabs />
        <FinanceSettlementSearchForm onSearch={handleSubmit} />

        <FinanceSettlementTable
          tablePaginationProps={{
            count: data?.['@odata.count'] ?? 0,
            rowsPerPage,
            page,
            onPageChange: (_event, currentPage) => handlePageChange(currentPage),
            onRowsPerPageChange: event =>
              handleRowsPerPageChange(Number(event.target.value)),
            labelRowsPerPage:
              nlNL.components.MuiDataGrid.defaultProps.localeText.MuiTablePagination
                ?.labelRowsPerPage,
          }}
          rows={data?.value ?? []}
          loading={loading}
          refetchData={fetchData}
        />
      </Container>
    </Layout>
  )
})
