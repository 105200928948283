import { useTranslation } from 'react-i18next'

import { Button, Typography, Stack } from '@mui/material'
import RfhColors from '@rfh-core/theme'
import { useIsMutating, useQueryClient } from '@tanstack/react-query'

import { resize } from '../resize'
import { UploadImage } from './UploadImage'
import { useIntersection } from './useIntersection'
import { useUploadInspectionPhoto } from './useUploadInspectionPhoto'

type Props = {
  clockSupplyLineId: number
  files: File[]
  onSuccess: () => void
  onCancel: () => void
}

export const InspectionPhoto = ({
  clockSupplyLineId,
  files,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation()
  const { mutate: uploadPhoto } = useUploadInspectionPhoto()
  const [containerRef, isVisible] = useIntersection({ threshold: 1 })
  // We dont need the outcome of this hook, but calling it
  // ensures we are subscribed to updates in the mutationCache
  useIsMutating({ mutationKey: ['uploadInspectionPhoto'] })

  const queryClient = useQueryClient()
  const mutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ['uploadInspectionPhoto'], status: 'success' })

  if (mutations.length === files.length) {
    mutations.forEach(mutation => {
      queryClient.getMutationCache().remove(mutation)
    })
    onSuccess()
  }

  const save = () => {
    files.map(async file =>
      uploadPhoto({
        clockSupplyLineId,
        fileExtension: 'jpeg',
        name: file.name,
        image: (await resize(URL.createObjectURL(file), 1920)).split(',')[1],
      })
    )
  }

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Stack p={4} spacing={4}>
        <Typography variant='h3'>
          {t('photoFeature.addInspectionPhotosHeading')}
        </Typography>
        <p>
          {files.length}{' '}
          {files.length === 1 ? t('photoFeature.photo') : t('photoFeature.photos')}
        </p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '1rem',
          }}
        >
          {files.map(file => (
            <UploadImage
              retry={async () =>
                uploadPhoto({
                  clockSupplyLineId,
                  fileExtension: 'jpeg',
                  name: file.name,
                  image: (await resize(URL.createObjectURL(file), 1920)).split(',')[1],
                })
              }
              key={file.name}
              src={URL.createObjectURL(file)}
              name={file.name}
            />
          ))}
        </div>
      </Stack>
      <Stack
        ref={containerRef}
        spacing={4}
        p={4}
        style={{
          position: 'sticky',
          marginTop: 'auto',
          bottom: '-1px',
          backgroundColor: isVisible ? 'initial' : RfhColors.rfhColors.white,
          ...(!isVisible && {
            boxShadow: `0px -20px 20px ${RfhColors.rfhColors.grey[100]}`,
          }),
        }}
      >
        <Button
          variant='outlined'
          onClick={() => {
            if (window.confirm(t('photoFeature.cancelConfirm'))) {
              onCancel()
            }
          }}
        >
          {t('buttons.cancel')}
        </Button>
        <Button onClick={() => save()}>
          {t('photoFeature.addInspectionPhotosButton')}
        </Button>
      </Stack>
    </div>
  )
}
