import { createRoot } from 'react-dom/client'

import { LicenseInfo } from '@mui/x-date-pickers-pro'
import * as Sentry from '@sentry/react'

import App from 'src/App'
import 'src/i18n/config'

import packageJson from '../package.json'
import { Config } from './configs/Config'

LicenseInfo.setLicenseKey(
  'a280ebba5ccba0a333d797d9f6853fecTz02OTQxNSxFPTE3MTkzODc1MTg3OTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

Sentry.init({
  dsn: 'https://8673804395a838b030d17f551f9c041e@o902103.ingest.us.sentry.io/4506891291656192',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  environment: Config.env,
  release: `${packageJson.name}@${packageJson.version}`,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
