import { ChangeEventHandler, FC, FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { CourtesyCodes, CourtesyCodesKey } from 'src/types/CourtesyCodes'

type CourtesyDialogProps = {
  isOpen: boolean
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  handleSubmit: (value: CourtesyCodes, note?: string) => void
  handleClose: () => void
}

const isCourtesyKey = (input: string): input is CourtesyCodesKey =>
  Object.keys(CourtesyCodes).includes(input)

export const CourtesyDialog: FC<CourtesyDialogProps> = ({
  isOpen,
  isLoading,
  isError,
  errorMessage,
  handleSubmit,
  handleClose,
}) => {
  const { t } = useTranslation()
  const { setHotkeyActive } = useHotkeyStore()
  const { offline } = useOnlineOffline()

  const [value, setValue] = useState<CourtesyCodes | undefined>(undefined)
  const [note, setNote] = useState<string>('')
  const [validationError, setValidationError] = useState<boolean>(false)

  const maximumTextLength = 100

  const resetValues = () => {
    setNote('')
    setValue(undefined)
  }
  const handleNoteChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.value.length <= maximumTextLength) {
      setNote(event.target.value)
    }
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    handleSubmit(value!, note)
  }
  const onClose = () => {
    resetValues()
    handleClose()
  }

  useEffect(() => {
    !isLoading && resetValues()
  }, [isLoading])

  useEffect(() => {
    const isValid = !(value === CourtesyCodes.overig_namelijk && !note)
    setValidationError(!isValid)
  }, [value, note])

  return (
    <Dialog
      open={isOpen}
      maxWidth='xs'
      onClose={handleClose}
      TransitionProps={{
        onEntered: () => setHotkeyActive(false),
        onExited: () => setHotkeyActive(true),
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Stack direction='column' gap={1}>
            <Box fontWeight='bold'> {t('courtesy.title')}</Box>
            <Box>{t('courtesy.subtitle')}</Box>
            <RadioGroup
              onChange={event => setValue(event?.target.value as CourtesyCodes)}
              value={value}
              sx={{
                '& .MuiRadio-root': {
                  pl: 0,
                },
              }}
            >
              {Object.keys(CourtesyCodes)
                .filter((_item, index) => !!index)
                .filter(isCourtesyKey)
                .map(key => {
                  if (key !== 'overig_namelijk') {
                    return (
                      <FormControlLabel
                        key={key}
                        value={key}
                        control={<Radio />}
                        label={t(`courtesy.codes.${key}`)}
                      />
                    )
                  } else {
                    return (
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                      >
                        <FormControlLabel
                          key={key}
                          value={key}
                          control={<Radio />}
                          label={t(`courtesy.codes.${key}`)}
                        />
                        <Typography
                          variant='body2'
                          sx={{ alignSelf: 'center' }}
                        >{`${note.length}/${maximumTextLength}`}</Typography>
                      </Box>
                    )
                  }
                })}
            </RadioGroup>
            <Box display='flex' flexDirection='row'>
              <TextField
                placeholder={t('courtesy.textFieldPlaceholder')}
                disabled={offline || value !== CourtesyCodes.overig_namelijk}
                value={note}
                onChange={handleNoteChange}
                error={validationError}
                inputRef={input => {
                  if (input != null) {
                    input.focus()
                  }
                }}
                helperText={
                  validationError && t('courtesy.errorMessage.validationError')
                }
                sx={{ width: '100%' }}
              />
            </Box>
            {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
            <Box
              borderBottom={1}
              sx={{
                borderColor: ({ rfhColors }) => rfhColors.grey[400],
              }}
              width='100%'
              mt={4}
            />
          </Stack>
        </DialogContent>
        <Box sx={{ marginBottom: '20px', marginLeft: '16px', marginRight: '24px' }}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            width='100%'
          >
            <Button variant='text' disabled={offline} onClick={onClose}>
              {t('courtesy.cancel')}
            </Button>
            {isLoading ? (
              <CircularProgress aria-label={t('queryState.loading.label')} />
            ) : (
              <Button
                autoFocus={true}
                variant='contained'
                disabled={offline || !value || isLoading || validationError}
                type='submit'
              >
                {t('courtesy.confirm')}
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Dialog>
  )
}
