export const badRequestCode = 400
export const notFoundCode = 404
export const internalServerErrorCode = 500
export const successCode = 200
export const createdCode = 201
export const acceptedCode = 202
export const noContentCode = 204

export enum ApiError {
  notFound = 'notFound',
  badRequest = 'badRequest',
  generalFailure = 'generalFailure',
}
