import { BatchCharacteristic } from 'src/types/ClockSupplyLine'
import { NegativeCodes, PositiveCodes } from 'src/types/inspectionCodes'

export const filterNegativeCodes = (item: BatchCharacteristic): boolean =>
  Object.values(NegativeCodes).includes(item.characteristicCode as NegativeCodes)

export const filterPositiveCodes = (item: BatchCharacteristic): boolean =>
  Object.values(PositiveCodes).includes(item.characteristicCode as PositiveCodes)

export const filterCharacteristics = (item: BatchCharacteristic): boolean =>
  !Object.values(PositiveCodes).includes(item.characteristicCode as PositiveCodes) &&
  !Object.values(NegativeCodes).includes(item.characteristicCode as NegativeCodes)

export const filterRegulatoryCharacteristics = (item: BatchCharacteristic): boolean =>
  filterCharacteristics(item) && item.sortingCode !== 0

export const filterNormalCharacteristics = (item: BatchCharacteristic): boolean =>
  filterCharacteristics(item) && item.sortingCode === 0

export const sortCharacteristics = (
  a: BatchCharacteristic,
  b: BatchCharacteristic
): number => {
  const valueA = a.sortingCode
  const valueB = b.sortingCode

  if (valueA < valueB) {
    return -1
  }

  if (valueA > valueB) {
    return 1
  }

  return 0
}

export const dataGridLayout = [2, 2, 8]
export const mutableDataGridLayout = [3, 3, 4.7, 1.3]

export const dataGridLayoutFallback = [12]
export const locationsDataGridLayout = [3, 9]

export const characteristicsDataGridLayout = [1, 2, 6, 2, 1]
export const mutableCharacteristicsDataGridLayout = [1, 2, 4.7, 3, 1.3]

const randomNumber = 0

export const emptyBatchCharacteristic: BatchCharacteristic = {
  characteristicCode: '',
  id: randomNumber,
  sortingCode: randomNumber,
  valueCode: '',
  characteristic: { code: '', description: '' },
  characteristicValue: {
    characteristicCode: '',
    value: '',
    valueCode: '',
  },
  clockSupplyLineId: randomNumber,
  isReadonly: false,
}
