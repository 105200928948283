import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

export const Logout: FC = () => {
  const { oktaAuth } = useOktaAuth()
  const navigate = useNavigate()

  const logoutAndClearSession = async () => {
    oktaAuth.tokenManager.clear()
    await oktaAuth.signOut()
    navigate('/')
  }

  logoutAndClearSession()

  return null
}
