import { NavLink, useParams } from 'react-router-dom'

import { Stack, Typography } from '@mui/material'
import RfhColors from '@rfh-core/theme'
import { useQueryClient } from '@tanstack/react-query'

import { queryBatch } from 'src/services/batchService'

type Props = {
  supplyFormCharacters: string[]
  supplyFormCode: string
}

export const BatchSwitcher = ({ supplyFormCharacters, supplyFormCode }: Props) => {
  const { code } = useParams()

  const queryClient = useQueryClient()
  supplyFormCharacters.forEach(partij => {
    queryClient.prefetchQuery({
      queryKey: ['batch', `${supplyFormCode}${partij}`],
      queryFn: () => queryBatch(`${supplyFormCode}${partij}`),
      staleTime: 1000 * 60 * 2,
    })
  })

  // This logic is here to ensure the A partij is selected when the route is
  // - /batch/F2A6HA
  // - /batch/F2A6HAa
  // given the supplyFormId is F2A6HA (aka briefnummer)
  const isSelected = (isRouteMatch: boolean, partij: string) =>
    isRouteMatch || (partij.toLowerCase() === 'a' && code === supplyFormCode)

  return (
    <Stack direction='row' spacing={4} alignItems='center' marginY={4}>
      <Typography variant='body1'>Partij:</Typography>
      {supplyFormCharacters.map(partij => (
        <NavLink
          to={`/batch/${supplyFormCode}${partij}`}
          style={({ isActive }) => ({
            color: isSelected(isActive, partij)
              ? RfhColors.rfhColors.black
              : RfhColors.rfhColors.dutchOrange,
            backgroundColor: isSelected(isActive, partij)
              ? RfhColors.rfhColors.hydroGreenShade
              : 'transparent',
            border: `1px solid ${
              isSelected(isActive, partij)
                ? RfhColors.rfhColors.hydroGreen
                : RfhColors.rfhColors.dutchOrange
            }`,
            borderRadius: '50%',
            height: '32px',
            width: '32px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
            textTransform: 'uppercase',
          })}
          key={partij}
        >
          {partij}
        </NavLink>
      ))}
    </Stack>
  )
}
