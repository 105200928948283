import noop from 'lodash.noop'

import { createContext, useContext, useState, FC, ReactNode, useMemo } from 'react'

import Alert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

interface NotificationContextType {
  notification: string | string[]
  severity: AlertProps['severity']
  showNotification: (
    notificationMessage: string[],
    severityLevel: AlertProps['severity']
  ) => void
  hideNotification: () => void
}

const NotificationContext = createContext<NotificationContextType>({
  notification: [],
  severity: 'success',
  showNotification: noop,
  hideNotification: noop,
})

export const useNotification: () => NotificationContextType = () =>
  useContext(NotificationContext)

export const NotificationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [notification, setNotification] = useState<string[]>([])
  const [severity, setSeverity] = useState<AlertProps['severity']>()

  const showNotification = (
    notificationMessage: string[],
    severityLevel: AlertProps['severity']
  ) => {
    setNotification(notificationMessage)
    setSeverity(severityLevel)
  }

  const hideNotification = () => {
    setNotification([])
    setSeverity(undefined)
  }

  const value: NotificationContextType = useMemo(
    () => ({
      notification,
      severity,
      showNotification,
      hideNotification,
    }),
    [notification, severity, showNotification, hideNotification]
  )

  return (
    <NotificationContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!notification.length}
        onClose={hideNotification}
      >
        <Alert onClose={hideNotification} severity={severity} sx={{ width: '100%' }}>
          {notification?.length > 1 ? (
            <ul>
              {notification.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            notification[0]
          )}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  )
}
