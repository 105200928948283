export enum NegativeCodes {
  K01 = 'K01',
  K02 = 'K02',
}

export enum PositiveCodes {
  P01 = 'P01',
  P02 = 'P02',
  P03 = 'P03',
}

export enum CodeTypes {
  PositiveCode = 'PositiveCode',
  NegativeCode = 'NegativeCode',
}
