import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Paper, Stack, Typography } from '@mui/material'

import Scanner from 'src/components/Scanner'

import { NotFound } from './NotFound'
import { Searching } from './Searching'
import { useBatchQuery } from './useBatchQuery'

type CameraModalProps = {
  onClose: () => void
}
export const CameraModal = ({ onClose }: CameraModalProps) => {
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const { isFetching, isError, isSuccess } = useBatchQuery(code)
  const navigate = useNavigate()

  // Navigating away is a sideEffect that should NOT happen
  // during the render phase hence the useEffect here
  useEffect(() => {
    if (isSuccess) {
      navigate(`/batch/${code}`)
    }
  }, [isSuccess])

  return (
    <>
      <Paper elevation={0} sx={{ padding: 2 }}>
        <Stack direction='row' justifyContent='flex-end'>
          <IconButton color='inherit' aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant='h4'>{t('photoFeature.cameraModalTitle')}</Typography>
        <Typography variant='body2'>{t('photoFeature.cameraModalSubtitle')}</Typography>
      </Paper>

      {isError && <NotFound code={code} />}
      {isFetching && <Searching code={code} />}

      <Scanner
        onUpdate={result => {
          setCode(result.getText())
        }}
      />
    </>
  )
}
