import { useTranslation } from 'react-i18next'

import { Check, WarningAmberRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { useIsMutating, useQueryClient } from '@tanstack/react-query'

const gridOverlayStyle = {
  gridArea: '1 / 1',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
}

export const UploadImage = ({ src, name, retry }: any) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  // We dont need the outcome of this hook, but calling it
  // ensures we are subscribed to updates in the mutationCache
  useIsMutating({ mutationKey: ['uploadInspectionPhoto'] })

  const mutation = queryClient
    .getMutationCache()
    .find({ predicate: ({ state }) => state.variables?.name === name })

  return (
    <div style={{ display: 'grid' }}>
      <img
        src={src}
        alt={name}
        style={{
          // gebruik objectFit: 'cover' om de foto's te laten passen in de grid
          // hierdoor zijn de foto's wat ingezoomd maar voorkomt de zwarte balk.
          objectFit: 'cover',

          // gebruik objectFit: 'contain' om de foto's volledig te tonen
          // hierdoor is een zwarte balk zichtbaar, maar wel de hele foto getoond.
          // objectFit: 'contain',
          width: '100%',
          height: '150px',
          gridArea: '1 / 1',
        }}
      />

      {mutation?.state.status === 'pending' && (
        <div style={gridOverlayStyle}>
          <CircularProgress />
        </div>
      )}

      {mutation?.state.status === 'success' && (
        <div style={gridOverlayStyle}>
          <Check color='success' />
        </div>
      )}

      {/*
        A bug in react query v4 will prevent retries on the last photo. The mutation cache loses the
        information about the variables that were used to start the mutation, this is fixed in v5
        This is also why the onlick is not tested.
      */}
      {mutation?.state.status === 'error' && (
        <div
          onClick={
            /* istanbul ignore next */ () => {
              retry()
            }
          }
          role='button'
          style={{
            cursor: 'pointer',
            color: 'white',
            ...gridOverlayStyle,
          }}
        >
          <WarningAmberRounded color='error' sx={{ placeSelf: 'center' }} />
          <p style={{ margin: '12px' }}>{t('photoFeature.addInspectionPhotosError')}</p>
        </div>
      )}
    </div>
  )
}
