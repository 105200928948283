import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Upload, Edit } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import theme from '@rfh-core/theme'

import { Loader } from 'src/components/Loader'
import { Config } from 'src/configs/Config'
import { useNotification } from 'src/hooks/useNotification'

import { addDetailPhoto, resize } from '../resize'
import { useBatchQuery } from '../useBatchQuery'
import { EditPhoto } from './EditPhoto'
import { useUpdateClockPhoto } from './useUpdateClockPhoto'

export const ClockPhoto = () => {
  const { t } = useTranslation()
  const { code } = useParams() as { code: string }
  const navigate = useNavigate()

  const [preview, setPreview] = useState<string>()
  const [editMode, setEditMode] = useState(false)
  const { data, isLoading, isError } = useBatchQuery(code)
  const { mutateAsync: saveHoofdfoto } = useUpdateClockPhoto({
    onSuccess: () => {
      showNotification([t('photoFeature.clockPhotoSaved')], 'success')
      navigate(`/batch/${code}`)
    },
  })
  const { showNotification } = useNotification()

  const changeClockPhoto = async (file: File) => {
    const src = URL.createObjectURL(file)
    setPreview(await resize(src, 640))
  }

  const changeDetailPhoto = async (file: File) => {
    const src = URL.createObjectURL(file)
    setPreview(await addDetailPhoto(preview ?? originalSrc, src))
  }

  if (isLoading) return <Loader isLoading />

  const batchImageId = data?.batchImages?.[0]?.id
  if (data === undefined || batchImageId === undefined || isError) {
    // We should never get here, but if we do, we want to know about it
    // so once we add error monitorring to this is app, we should include a log here.

    // OR we could implement data router with a loader, so we can fetch before loading the component
    // OR we could wrap the component in component that handles the loading phase...

    // JIRA-Ticket: https://royalfloraholland.atlassian.net/browse/UI-2257
    return <div>Not found or corrupt batch</div>
  }

  const originalSrc = `${Config.beeldbankHostValue}/foto/volledig/${
    data.batchImages![0]?.supplierImageId
  }`
  if (editMode) {
    return (
      <EditPhoto
        src={preview ?? originalSrc}
        onClose={() => setEditMode(false)}
        savePreview={src => setPreview(src)}
      />
    )
  }

  return (
    <Stack p={4} spacing={4} minHeight='100vh'>
      <Typography variant='h3'>{t('photoFeature.editClockPhotoHeading')}</Typography>
      <img src={preview ?? originalSrc} style={{ width: '100%' }} alt='klokfoto' />

      <Stack
        alignItems='center'
        direction='row'
        p={4}
        spacing={4}
        sx={{ backgroundColor: theme.rfhColors.white }}
      >
        <Typography variant='body1' sx={{ flex: 1 }}>
          {t('photoFeature.mainPhoto')}
        </Typography>
        <IconButton
          aria-label={t('photoFeature.modifyPhoto')}
          className='contained'
          size='small'
          onClick={() => setEditMode(true)}
        >
          <Edit />
        </IconButton>
        <IconButton className='contained' size='small' component='label'>
          <Upload />
          <input
            aria-label={t('photoFeature.editClockPhoto')}
            type='file'
            hidden
            accept='image/*'
            onChange={({ target: { files } }) => files && changeClockPhoto(files[0])}
          />
        </IconButton>
      </Stack>

      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        p={4}
        spacing={2}
        style={{ marginBottom: '16px' }}
        sx={{ backgroundColor: theme.rfhColors.white }}
      >
        <Typography variant='body1'>{t('photoFeature.detailPhoto')}</Typography>
        <IconButton className='contained' size='small' component='label'>
          <Upload />
          <input
            aria-label={t('photoFeature.detailPhoto')}
            type='file'
            hidden
            accept='image/*'
            onChange={({ target: { files } }) => files && changeDetailPhoto(files[0])}
          />
        </IconButton>
      </Stack>

      <Button
        style={{ marginTop: 'auto' }}
        variant='outlined'
        onClick={() => {
          if (
            preview === undefined ||
            window.confirm(t('photoFeature.cancelConfirm'))
          ) {
            navigate(`/batch/${code}`)
          }
        }}
      >
        {t('buttons.cancel')}
      </Button>
      <Button
        disabled={preview === undefined}
        onClick={() => {
          saveHoofdfoto({
            clockSupplyLineId: data.id,
            fileExtension: 'jpeg',
            image: preview?.split(',')[1], // this is the base64 image string exluding the data:image/jpeg;base64,
            batchImageId,
          })
        }}
      >
        {t('buttons.save')}
      </Button>
    </Stack>
  )
}
