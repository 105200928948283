import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

export const ObservationFindingsCard: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Card
      className='CardPrimary'
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <CardMedia
        component='img'
        image='/assets/images/RoyalFloraHolland_shoot-1-2_1024_08.jpg'
      />
      <CardContent sx={{ flex: 1 }}>
        <Box>
          <Typography variant='h4'>
            {t('home.observationFindingsCard.title')}
          </Typography>
          <Typography variant='body2'>
            {t('home.observationFindingsCard.bodyText')}
          </Typography>
        </Box>
        <CardActions sx={{ marginTop: 'auto' }}>
          <Button onClick={() => navigate('/findings')} fullWidth variant='contained'>
            {t('home.observationFindingsCard.buttonText')}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}
