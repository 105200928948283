import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import { Supplier } from 'src/types/Supplier'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'

export const getSuppliers = (
  searchString: string | undefined
): AxiosPromise<ODataResponse<Supplier[]>> => {
  const query = buildQuery<Supplier>({
    filter: {
      or: [
        { 'tolower(name)': { contains: searchString?.toLowerCase() } },
        { "cast(clientNumber, 'Edm.String')": { contains: searchString } }, // eslint-disable-line @typescript-eslint/quotes
      ],
    },
  })

  return api.get(`/odata/v1/Suppliers${query}`)
}
