import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

export const PageTabs: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [activePage, setActivePage] = useState(location.pathname)
  const { t } = useTranslation()

  const handleTabChange = (_event: React.ChangeEvent<unknown>, value: string): void => {
    navigate(value)
  }

  useEffect(() => {
    setActivePage(location.pathname)
  }, [location])

  return (
    <Box my={4}>
      <Tabs value={activePage} onChange={handleTabChange}>
        <Tab label={t('FinanceSettlement.overview')} value='/financial-corrections' />
      </Tabs>
    </Box>
  )
}
