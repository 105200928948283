import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import {
  FinanceSettlementOverview,
  FinanceSettlementRollBackInput,
  FinanceSettlementSearchInput,
} from 'src/types/financeSettlement'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'
import { getIsoDate } from 'src/utils/getIsoDate'

export const getFinanceSettlement = (
  searchInput: FinanceSettlementSearchInput,
  top = 25,
  page = 0
): AxiosPromise<ODataResponse<FinanceSettlementOverview[]>> => {
  const originalDateGE = searchInput?.dateRange?.[0]
  const originalDateLE = searchInput?.dateRange?.[1]
  let modifiedDateGE: Date | undefined
  let modifiedDateLE: Date | undefined

  if (originalDateGE) {
    modifiedDateGE = new Date(originalDateGE)
    modifiedDateGE.setHours(0, 0, 0, 0)
    modifiedDateGE = getIsoDate(modifiedDateGE)
  }

  if (originalDateLE) {
    modifiedDateLE = new Date(originalDateLE)
    modifiedDateLE.setHours(23, 59, 59, 999)
    modifiedDateLE = getIsoDate(modifiedDateLE)
  }
  const filter = {
    supplierId: searchInput?.supplierId
      ? { eq: Number(searchInput?.supplierId) }
      : undefined,

    invoiceCreatedInvoiceId: searchInput?.invoiceCreatedInvoiceId
      ? { eq: searchInput?.invoiceCreatedInvoiceId.toUpperCase() }
      : undefined,

    supplyFormCode: searchInput?.supplyFormCode
      ? { eq: searchInput?.supplyFormCode.toUpperCase() }
      : undefined,

    invoiceCreatedInvoiceDateTime: searchInput?.dateRange?.every(input => input)
      ? {
          ge: modifiedDateGE,
          le: modifiedDateLE,
        }
      : undefined,
  }

  const query = buildQuery<FinanceSettlementOverview>({
    top,
    skip: page * top,
    orderBy: [
      ['invoiceCreatedInvoiceDateTime', 'desc'],
      ['invoiceCreatedInvoiceId', 'asc'],
      ['supplyFormCode', 'asc'],
      ['supplyFormCharacter', 'asc'],
      ['transactionType', 'asc'],
    ],
    filter,
    count: true,
  })

  return api.get(`/odata/v1/FinanceSettlementViews${query}`)
}

export const postRollback = (
  params: FinanceSettlementRollBackInput
): AxiosPromise<void> =>
  api.post(`/api/v1/FinanceSettlementViews/${params.settlementId}/Rollback`, params)
