/* returns the x, y, width and height of the detail image
  where x and y are relative to the main image
  convenient to be called like this:
  context.drawImage(detail, ...resizeDetail(main, detail))
  More on drawing Images on Canvas can be found here
  https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
*/
export const resizeDetail = <I extends { width: number; height: number }>(
  main: I,
  detail: I
) => {
  const max = Math.max(main.width, main.height) * 0.35
  const factor = Math.max(detail.width, detail.height) / max
  const width = detail.width / factor
  const height = detail.height / factor

  return [main.width - width, main.height - height, width, height] as const
}
