import { ChangeEventHandler, FC, FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { OrangeScanIcon } from '@rfh-core/icons'

import { Form } from 'src/components/batch/BatchInput.styles'
import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import { validateInput } from 'src/utils/validateInput'

export const BatchSearchCard: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { offline } = useOnlineOffline()

  const [searchedBatchRef, setSearchedBatchRef] = useState<string>('')
  const [validationError, setValidationError] = useState<boolean>(false)

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value.replace(/[[\]]/g, '')

    const isValid = validateInput(value)
    setValidationError(!isValid)
    setSearchedBatchRef(value)

    if (event.target.value.slice(-1) === ']') {
      navigate(`/inspection/${value}`)
    }
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    navigate(`/inspection/${searchedBatchRef}`)
  }

  return (
    <Form onSubmit={handleSubmit} sx={{ width: '100%' }}>
      <Card className='CardPrimary'>
        <CardMedia
          component='img'
          image='assets/images/RoyalFloraHolland_shoot-1-2_1024_07.jpg'
        />
        <CardContent>
          <Box sx={{ mb: 5 }}>
            <Typography variant='h4'>{t('home.batchSearchCard.title')}</Typography>
            <Typography variant='body2'>
              {t('home.batchSearchCard.bodyText')}
            </Typography>

            <TextField
              error={validationError}
              helperText={
                validationError ? <>{t('inspection.batchInput.helperText')}</> : null
              }
              fullWidth
              variant='filled'
              label={t('home.batchSearchCard.labelText')}
              inputProps={{
                className: 'batchRefInputField',
              }}
              placeholder={t('home.batchSearchCard.placeholderText')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <OrangeScanIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleInputChange}
              value={searchedBatchRef}
              autoFocus
            />
          </Box>
          <CardActions>
            <Button
              type='submit'
              variant='contained'
              fullWidth
              disabled={offline || validationError}
            >
              {t('home.batchSearchCard.buttonText')}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Form>
  )
}
