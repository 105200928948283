import React from 'react'

import {
  Font,
  Page,
  Text,
  Link,
  View,
  Image,
  Svg,
  G,
  Path,
  Rect,
  Defs,
  ClipPath,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer'

import type {
  InspectionFile,
  InspectionReportImages,
  InspectionReport as InspectionReportType,
  Language,
} from 'src/types/InspectionReport'

import {
  // testImage,
  halyardDisplayNormal300,
  halyardDisplayItalic300,
  halyardDisplayNormal400,
  halyardDisplayItalic400,
  halyardDisplayNormal700,
  halyardDisplayItalic700,
  halyardMicroNormal400,
  halyardMicroItalic400,
  halyardMicroNormal700,
  halyardMicroItalic700,
  halyardTextNormal300,
  halyardTextItalic300,
  halyardTextNormal400,
  halyardTextItalic400,
  halyardTextNormal700,
  halyardTextItalic700,
} from './base64Resources'
import { exampleInspectionReport, exampleImages } from './example'
import { translations } from './translations'

Font.register({
  family: 'Halyard Display',
  fonts: [
    {
      src: halyardDisplayNormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: halyardDisplayItalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: halyardDisplayItalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: halyardDisplayNormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: halyardDisplayNormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: halyardDisplayItalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
  ],
})

Font.register({
  family: 'Halyard Micro',
  fonts: [
    {
      src: halyardMicroNormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: halyardMicroItalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: halyardMicroItalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
    {
      src: halyardMicroNormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
})

Font.register({
  family: 'Halyard Text',
  fonts: [
    {
      src: halyardTextNormal300,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: halyardTextItalic300,
      fontStyle: 'italic',
      fontWeight: 300,
    },
    {
      src: halyardTextNormal400,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: halyardTextItalic400,
      fontStyle: 'italic',
      fontWeight: 400,
    },
    {
      src: halyardTextNormal700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    {
      src: halyardTextItalic700,
      fontStyle: 'italic',
      fontWeight: 700,
    },
  ],
})

const COLORS = {
  defaultColor: '#000',
  coverHeaderColor: '#fff',
  linkColor: '#ff7200',
  borderColor: '#ccc',
  circleBorderColor: '#380f92',
  circleBackgroundColor: '#d2c9e6',
  alternativeCircleBorderColor: '#606060',
  alternativeCircleBackgroundColor: '#ecebeb',
  inspectionTableBorderColor: '#e0e0e0',
  oddColor: '#f8f8f8',
}

const LOCATIONS = {
  0: 'Onbekend',
  1: 'Aalsmeer',
  2: 'Naaldwijk',
  3: 'Rijnsburg',
  7: 'Eelde',
  24: 'RheinMaas',
  25: 'RFHconcern',
}

const coverPageStyles = StyleSheet.create({
  layout: {
    flexDirection: 'column',
  },
  blocks: {
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
  },
})

type CoverPageProps = {
  supplierName: string
  supplierId: number
  date: string
  qiClass: string
  inspections: string[]
  languageCode: Language
  name: string
  email: string
}

const CoverPage = ({
  supplierName,
  supplierId,
  date,
  qiClass,
  inspections,
  languageCode,
  name,
  email,
}: CoverPageProps): React.ReactElement => (
  <Page size='A4' style={coverPageStyles.layout}>
    <CoverHeader languageCode={languageCode} />
    <View style={coverPageStyles.blocks}>
      <Details
        supplierName={supplierName}
        supplierId={supplierId}
        date={date}
        qiClass={qiClass}
        languageCode={languageCode}
      />
      <Inspections inspections={inspections} languageCode={languageCode} />
    </View>
    <Info languageCode={languageCode} />
    <View style={coverPageStyles.blocks}>
      <Contact name={name} email={email} languageCode={languageCode} />
      {/* <RatesTable languageCode={languageCode} /> */}
    </View>
    <Footer languageCode={languageCode} />
  </Page>
)

const coverHeaderStyles = StyleSheet.create({
  title: {
    position: 'absolute',
    color: COLORS.coverHeaderColor,
    fontFamily: 'Halyard Display',
    fontSize: '24',
    fontWeight: 700,
    lineHeight: '1.5',
    paddingLeft: 40,
    paddingTop: 100,
  },
  svg: {
    marginLeft: -20,
  },
})

type CoverHeaderProps = {
  languageCode: Language
}

const CoverHeader = ({ languageCode }: CoverHeaderProps): React.ReactElement => (
  <View>
    <Svg
      width='620.5'
      height='188.5'
      viewBox='0 0 1241 377.001'
      style={coverHeaderStyles.svg}
    >
      <G transform='translate(-2555.5 -1968.5)'>
        <Rect width='1240' height='376' transform='translate(2556 1969)' fill='#fff' />
        <Path
          d='M484.486,942.648a1068.379,1068.379,0,0,1,355.544-376h447.713v376Z'
          transform='translate(2508.257 1402.352)'
          fill='#e0e0e0'
        />
        <Path
          d='M1288.243,943.148H483.631l.42-.745c10.9-19.359,22.543-38.611,34.612-57.224,11.971-18.463,24.676-36.785,37.762-54.458,12.99-17.542,26.7-34.909,40.746-51.617,13.954-16.6,28.609-32.983,43.56-48.7,14.863-15.627,30.409-31.007,46.207-45.713,15.718-14.632,32.1-28.981,48.686-42.65,16.518-13.611,33.676-26.9,51-39.512,17.263-12.565,35.142-24.778,53.14-36.3l.123-.079h448.36Zm-802.9-1h801.9v-375H840.177c-17.94,11.488-35.759,23.662-52.967,36.187-17.305,12.6-34.447,25.877-50.95,39.475-16.573,13.656-32.938,27.992-48.641,42.61-15.783,14.692-31.315,30.058-46.164,45.67-14.937,15.7-29.579,32.075-43.519,48.657-14.034,16.693-27.73,34.043-40.707,51.569-13.074,17.656-25.767,35.961-37.727,54.407C507.6,904.08,496.109,923.058,485.342,942.148Z'
          transform='translate(2508.257 1402.352)'
        />
        <G transform='translate(3491 2140.355)'>
          <Path
            d='M89.184,59.151a37.7,37.7,0,0,1-25.14,61.906A37.7,37.7,0,0,1,89.184,59.151M92.72,50.44A46.225,46.225,0,0,0,60.5,129.767,46.225,46.225,0,0,0,92.714,50.44'
            transform='translate(-13.991 -15.121)'
            fill='#0cc988'
          />
          <Path
            d='M167.769,72.7l-5.752-6.561h-1.363V72.7h-4.7V53.58h9.659c4.131,0,6.863,2.514,6.863,6.253a6,6,0,0,1-5.011,6.078l5.983,6.792Zm-2.677-10.5c1.684,0,2.8-.91,2.8-2.311s-1.083-2.283-2.8-2.283h-4.438V62.2Z'
            transform='translate(-46.929 -16.063)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M198.044,67.464a7.528,7.528,0,0,1-7.835,7.562c-4.242,0-7.919-3.025-7.919-7.562s3.592-7.534,7.919-7.534,7.835,3.025,7.835,7.534m-4.389,0a3.5,3.5,0,1,0,0,.371,3.5,3.5,0,0,0,0-.371'
            transform='translate(-54.859 -17.966)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M213.821,79.657h-4.927l2.306-5.336L205.28,60.45h4.892l3.39,8.368,3.621-8.368h4.892Z'
            transform='translate(-61.78 -18.122)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M245.811,74.6h-4.557V73.46a6.43,6.43,0,0,1-4.445,1.568c-3.788,0-6.989-3.053-6.989-7.59s3.194-7.59,6.989-7.59a6.43,6.43,0,0,1,4.445,1.568V60.275h4.557Zm-4.557-7.163a3.568,3.568,0,0,0-3.425-3.711h-.084a3.721,3.721,0,0,0,0,7.422,3.554,3.554,0,0,0,3.495-3.6A.461.461,0,0,0,241.254,67.438Z'
            transform='translate(-69.17 -17.941)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M257.92,72.081h4.557V51.53H257.92Z'
            transform='translate(-77.63 -15.448)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M160.627,101.254h8.946v4.11h-8.946v6.848h-4.7V93.09h14.726v4.138h-10.03Z'
            transform='translate(-46.924 -27.907)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M180.17,111.586h4.557V91H180.17Z'
            transform='translate(-54.221 -27.281)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M206.114,106.964a7.528,7.528,0,0,1-7.835,7.562c-4.243,0-7.919-3.025-7.919-7.562s3.592-7.534,7.919-7.534,7.835,3.025,7.835,7.534m-4.389,0a3.495,3.495,0,1,0-3.446,3.711,3.561,3.561,0,0,0,3.446-3.669v-.042'
            transform='translate(-57.289 -29.808)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M227.1,99.794l-.2,4.565h-.825c-3.187,0-4.927,1.653-4.927,5.364V114.2H216.6V99.913h4.557v2.738a5.527,5.527,0,0,1,4.927-2.941,3.893,3.893,0,0,1,1.027.112'
            transform='translate(-65.189 -29.892)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M249.421,114.1h-4.557v-1.141a6.43,6.43,0,0,1-4.445,1.568c-3.788,0-6.989-3.053-6.989-7.59s3.194-7.6,6.989-7.6a6.437,6.437,0,0,1,4.445,1.568V99.8h4.557Zm-4.557-7.163a3.569,3.569,0,0,0-3.425-3.711h-.084a3.721,3.721,0,0,0,0,7.422,3.554,3.554,0,0,0,3.495-3.6A.464.464,0,0,0,244.864,106.936Z'
            transform='translate(-70.256 -29.779)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M173.353,151.763h-4.669V144.2h-8.038v7.562h-4.7V132.64h4.7v7.45h8.038v-7.45h4.669Z'
            transform='translate(-46.929 -39.764)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M200.414,146.506a7.528,7.528,0,0,1-7.835,7.562c-4.242,0-7.919-3.025-7.919-7.562S188.245,139,192.586,139s7.835,3.025,7.835,7.534m-4.389,0a3.495,3.495,0,1,0-3.446,3.669,3.561,3.561,0,0,0,3.446-3.669v-.042'
            transform='translate(-55.573 -41.671)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M210.89,151.151h4.557V130.6H210.89Z'
            transform='translate(-63.471 -39.153)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M222.21,151.151h4.557V130.6H222.21Z'
            transform='translate(-66.877 -39.153)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M248.471,153.632h-4.557V152.49a6.43,6.43,0,0,1-4.445,1.568c-3.788,0-6.989-3.053-6.989-7.59s3.194-7.59,6.989-7.59a6.43,6.43,0,0,1,4.445,1.568v-1.141h4.557Zm-4.557-7.163a3.568,3.568,0,0,0-3.425-3.711h-.084a3.721,3.721,0,0,0,0,7.422,3.554,3.554,0,0,0,3.495-3.6.3.3,0,0,0,.007-.112Z'
            transform='translate(-69.972 -41.633)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M275.066,144.847v8.83h-4.529V145.82a2.506,2.506,0,0,0-2.446-2.8c-1.8,0-2.964,1.225-2.964,3.991v6.652H260.57V139.308h4.557v1.4a5.893,5.893,0,0,1,4.389-1.8c3.334,0,5.556,2.367,5.556,5.966'
            transform='translate(-78.427 -41.643)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M300.691,151.151h-4.557V150.01a6.43,6.43,0,0,1-4.445,1.568c-3.788,0-6.989-3.053-6.989-7.59s3.194-7.59,6.989-7.59a6.43,6.43,0,0,1,4.445,1.568V130.6h4.557Zm-4.557-7.163a3.568,3.568,0,0,0-3.425-3.711H292.6a3.721,3.721,0,0,0,0,7.422,3.554,3.554,0,0,0,3.495-3.6.153.153,0,0,0,.035-.112Z'
            transform='translate(-85.691 -39.153)'
            fill='rgba(0,0,0,0)'
          />
          <Path
            d='M61.51,21.328H68.5V0H61.51Z'
            transform='translate(-18.495)'
            fill='#3a4d41'
          />
          <Path
            d='M40.892,26.268,36,9.176,42.71,7.25l4.892,17.1Z'
            transform='translate(-10.817 -2.174)'
            fill='#3a4d41'
          />
          <Path
            d='M23.408,36.464l-7.548-12.1,5.913-3.7,7.548,12.1Z'
            transform='translate(-4.753 -6.194)'
            fill='#3a4d41'
          />
          <Path
            d='M86.893,26.265l-6.7-1.926,4.892-17.1,6.7,1.933Z'
            transform='translate(-24.121 -2.171)'
            fill='#3a4d41'
          />
          <Path
            d='M103.713,36.464,97.8,32.76l7.548-12.1,5.913,3.7Z'
            transform='translate(-29.421 -6.194)'
            fill='#3a4d41'
          />
          <Path
            d='M13.92,50.44a46.225,46.225,0,0,0,32.2,79.327A46.2,46.2,0,0,0,13.969,50.454m3.495,8.7A37.715,37.715,0,0,1,51.55,96.668a37.39,37.39,0,0,1-8.925,24.388A37.7,37.7,0,0,1,17.463,59.151'
            transform='translate(0.032 -15.121)'
            fill='#3a4d41'
          />
        </G>
        <Path
          d='M829.284,527.178v-376h136.4c289.014,39.049,550.063,174.975,746.542,376Z'
          transform='translate(1726.716 1817.823)'
          fill='#3a4d41'
        />
        <Path
          d='M1713.415,527.678H828.784v-377H965.719l.033,0c35.8,4.837,71.811,11.273,107.04,19.13,34.885,7.78,69.872,17.148,103.988,27.843,33.831,10.606,67.647,22.78,100.507,36.184,32.635,13.313,65.135,28.167,96.6,44.152,31.3,15.9,62.337,33.311,92.257,51.747,29.815,18.371,59.25,38.211,87.489,58.97,28.188,20.722,55.875,42.867,82.291,65.821s52.21,47.279,76.664,72.3Zm-883.63-1h881.254c-24.188-24.7-49.678-48.718-75.774-71.393-26.4-22.936-54.061-45.064-82.227-65.77-28.216-20.743-57.629-40.568-87.421-58.925-29.9-18.422-60.913-35.819-92.185-51.707-31.437-15.972-63.911-30.815-96.521-44.118-32.835-13.394-66.623-25.558-100.428-36.155-34.089-10.687-69.049-20.047-103.907-27.821-35.19-7.848-71.163-14.278-106.922-19.11H829.784Z'
          transform='translate(1726.716 1817.823)'
        />
      </G>
    </Svg>
    <View style={coverHeaderStyles.title}>
      <Text>{translations[languageCode].qualityReport}</Text>
      <Text>{translations[languageCode].controlAndGuidance}</Text>
    </View>
  </View>
)

const detailsStyles = StyleSheet.create({
  details: {
    flexGrow: 1,
    flexBasis: 0,
    border: 1,
    borderStyle: 'solid',
    borderColor: COLORS.borderColor,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
  },
  tableHeader: {
    fontFamily: 'Halyard Display',
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  label: {
    width: '40%',
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 700,
  },
  value: {
    width: '60%',
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
  },
  circle: {
    backgroundColor: COLORS.circleBackgroundColor,
    width: 20,
    height: 20,
    borderRadius: 10,
    border: 1,
    borderStyle: 'solid',
    borderColor: COLORS.circleBorderColor,
  },
  alternativeCircle: {
    backgroundColor: COLORS.alternativeCircleBackgroundColor,
    width: 20,
    height: 20,
    borderRadius: 10,
    border: 1,
    borderStyle: 'solid',
    borderColor: COLORS.alternativeCircleBorderColor,
  },
  circleValue: {
    position: 'absolute',
    top: 1.5,
    left: 6,
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
  },
  alternativeCircleValue: {
    position: 'absolute',
    top: 1,
    left: 7,
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
  },
})

type DetailsProps = Omit<CoverPageProps, 'inspections' | 'name' | 'email'>

const Details = ({
  supplierName,
  supplierId,
  date,
  qiClass,
  languageCode,
}: DetailsProps): React.ReactElement => (
  <View style={detailsStyles.details}>
    <Text
      style={detailsStyles.tableHeader}
    >{`${translations[languageCode].details}`}</Text>
    <View>
      <View style={detailsStyles.row}>
        <Text
          style={detailsStyles.label}
        >{`${translations[languageCode].growerName}`}</Text>
        <Text style={detailsStyles.value}>{supplierName}</Text>
      </View>
      <View style={detailsStyles.row}>
        <Text
          style={detailsStyles.label}
        >{`${translations[languageCode].growerNumber}`}</Text>
        <Text style={detailsStyles.value}>{supplierId}</Text>
      </View>
      <View style={detailsStyles.row}>
        <Text style={detailsStyles.label}>{`${translations[languageCode].date}`}</Text>
        <Text style={detailsStyles.value}>{date}</Text>
      </View>
      <View style={detailsStyles.row}>
        <Text
          style={detailsStyles.label}
        >{`${translations[languageCode].qiScore}`}</Text>
        <View
          style={
            qiClass === 'geen' ? detailsStyles.alternativeCircle : detailsStyles.circle
          }
        >
          <Text
            style={
              qiClass === 'geen'
                ? detailsStyles.alternativeCircleValue
                : detailsStyles.circleValue
            }
          >
            {qiClass === 'geen' ? '-' : qiClass}
          </Text>
        </View>
      </View>
    </View>
  </View>
)

const inspectionsStyles = StyleSheet.create({
  inspections: {
    flexGrow: 1,
    flexBasis: 0,
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
    padding: 10,
    border: 1,
    borderStyle: 'solid',
    borderColor: COLORS.borderColor,
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  label: {
    fontFamily: 'Halyard Display',
    fontSize: 14,
    fontWeight: 700,
    color: COLORS.defaultColor,
    textDecoration: 'none',
  },
  value: {
    paddingTop: 3,
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
  },
})

type InspectionsProps = Pick<CoverPageProps, 'inspections' | 'languageCode'>

const Inspections = ({
  inspections,
  languageCode,
}: InspectionsProps): React.ReactElement => (
  <View style={inspectionsStyles.inspections}>
    {inspections.map(inspection => (
      <View key={inspection} style={inspectionsStyles.row}>
        <Link src={`#${inspection}`} style={inspectionsStyles.label}>
          {`${translations[languageCode].lotNumber} ${inspection}`}
        </Link>
        {/* <Text style={inspectionsStyles.value}>{`${translations[languageCode].page} 2 ${translations[languageCode].until} 7`}</Text> */}
      </View>
    ))}
  </View>
)

const infoStyles = StyleSheet.create({
  info: {
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
  },
  header: {
    fontFamily: 'Halyard Text',
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 10,
  },
  paragraph: {
    fontFamily: 'Halyard Text',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    marginBottom: 15,
  },
  lastParagraph: {
    fontFamily: 'Halyard Text',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  link: {
    fontFamily: 'Halyard Text',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    color: COLORS.linkColor,
    marginBottom: 15,
  },
})

type InfoProps = {
  languageCode: Language
}

const Info = ({ languageCode }: InfoProps): React.ReactElement => (
  <View style={infoStyles.info}>
    <Text style={infoStyles.header}>{translations[languageCode].info}</Text>
    <Text style={infoStyles.paragraph}>{translations[languageCode].paragraph1}</Text>
    <Text style={infoStyles.lastParagraph}>
      {translations[languageCode].paragraph2}
    </Text>
    <Link src={translations[languageCode].url} style={infoStyles.link}>
      {translations[languageCode].title}
    </Link>
    <Text style={infoStyles.lastParagraph}>
      {translations[languageCode].kindRegards}
    </Text>
  </View>
)

const contactStyles = StyleSheet.create({
  contact: {
    // width: '30%',
    flexGrow: 0.6,
    flexBasis: 0,
  },
  text: {
    fontFamily: 'Halyard Text',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  link: {
    fontFamily: 'Halyard Text',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    color: COLORS.defaultColor,
    textDecoration: 'none',
  },
})

type ContactProps = {
  languageCode: Language
  name: string
  email: string
}

const Contact = ({ name, email, languageCode }: ContactProps): React.ReactElement => (
  <View style={contactStyles.contact}>
    <Text style={contactStyles.text}>{name}</Text>
    {/* <Text style={contactStyles.text}>{translations[languageCode].employee}</Text> */}
    {/* <Text style={contactStyles.text}>Legmeerdijk 313</Text> */}
    {/* <Text style={contactStyles.text}>1430 BA Aalsmeer</Text> */}
    <Text style={contactStyles.text}>
      E{' '}
      <Link style={contactStyles.link} src={`mailto:${email}`}>
        {email}
      </Link>
    </Text>
    {/* <Text style={contactStyles.text}>
      M{' '}
      <Link style={contactStyles.link} src='tel:+31 (0) 6 22054459'>
        +31 (0) 6 22054459
      </Link>
    </Text> */}
  </View>
)

// const ratesTableStyles = StyleSheet.create({
//   table: {
//     flexGrow: 1,
//     flexBasis: 0,
//     border: 1,
//     borderStyle: 'solid',
//     borderColor: COLORS.defaultColor,
//   },
//   row: {
//     flexDirection: 'row',
//   },
//   even: {
//     backgroundColor: COLORS.borderColor,
//   },
//   firstColumn: {
//     width: '55%',
//     paddingTop: 5,
//     paddingBottom: 5,
//     paddingLeft: 5,
//     textAlign: 'left',
//   },
//   text: {
//     fontFamily: 'Halyard Text',
//     fontSize: 8,
//     fontWeight: 400,
//     width: '20%',
//     paddingTop: 5,
//     paddingBottom: 5,
//     border: 1,
//     borderStyle: 'solid',
//     borderColor: COLORS.defaultColor,
//     textAlign: 'center',
//   },
// })
//
// type RatesTableProps = {
//   languageCode: Language
// }
//
// const RatesTable = ({ languageCode }: RatesTableProps): React.ReactElement => (
//   <View style={ratesTableStyles.table}>
//     <View style={ratesTableStyles.row}>
//       <Text style={[ratesTableStyles.text, ratesTableStyles.firstColumn]}>
//         Categorie heffing
//       </Text>
//       <Text style={ratesTableStyles.text}>Q.I.-score A</Text>
//       <Text style={ratesTableStyles.text}>Q.I.-score B</Text>
//       <Text style={ratesTableStyles.text}>Q.I.-score C</Text>
//       <Text style={ratesTableStyles.text}>{'Geen Q.I.-score (<50 partijen)'}</Text>
//     </View>
//     <View style={[ratesTableStyles.row, ratesTableStyles.even]}>
//       <Text style={[ratesTableStyles.text, ratesTableStyles.firstColumn]}>
//         1. Onbekende waarde op EAB
//       </Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//     </View>
//     <View style={ratesTableStyles.row}>
//       <Text style={[ratesTableStyles.text, ratesTableStyles.firstColumn]}>
//         2. Wijziging sortering / voorschrift
//       </Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 25,-</Text>
//       <Text style={ratesTableStyles.text}>€ 40,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//     </View>
//     <View style={[ratesTableStyles.row, ratesTableStyles.even]}>
//       <Text style={[ratesTableStyles.text, ratesTableStyles.firstColumn]}>
//         3. Productreclamatie
//       </Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 25,-</Text>
//       <Text style={ratesTableStyles.text}>€ 40,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//     </View>
//     <View style={ratesTableStyles.row}>
//       <Text style={[ratesTableStyles.text, ratesTableStyles.firstColumn]}>
//         4. Wijziging kwaliteit / foto
//       </Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//       <Text style={ratesTableStyles.text}>€ 40,-</Text>
//       <Text style={ratesTableStyles.text}>€ 75,-</Text>
//       <Text style={ratesTableStyles.text}>€ 15,-</Text>
//     </View>
//   </View>
// )

const footerStyles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    fontSize: 8,
    paddingBottom: 10,
  },
  date: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.5,
    marginLeft: 40,
    flexGrow: 1,
    flexBasis: 0,
  },
  page: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.5,
    marginRight: 40,
    flexGrow: 1,
    flexBasis: 0,
    textAlign: 'right',
  },
  svg: {
    position: 'absolute',
    bottom: -15,
    left: 0,
  },
})

type FooterProps = {
  languageCode: Language
}

const Footer = ({ languageCode }: FooterProps): React.ReactElement => (
  <View style={footerStyles.footer} fixed>
    <Text style={footerStyles.date}>
      {new Date().toISOString().split('T')[0].split('-').reverse().join('-')}
    </Text>
    <Svg style={footerStyles.svg} width='620' height='60' viewBox='0 0 1240 60'>
      <Defs>
        <ClipPath id='a'>
          <Rect width='626' height='60' />
        </ClipPath>
      </Defs>
      <G transform='translate(0 -2259)'>
        <G transform='translate(466 2259)'>
          <Path
            d='M417.874,85.526,458.668,0C236.641,29.339,32.879,246.741,3.541,485.581-11.37,606.972,21.647,710.546,86.866,779.189c222.015-29.338,425.777-246.74,455.115-485.579C556.892,172.218,523.885,68.656,458.668,0'
            transform='translate(392.074 -220.779) rotate(53)'
            fill='#ecebeb'
          />
        </G>
        <Rect width='1240' height='60' transform='translate(0 2259)' />
      </G>
    </Svg>
    <Text
      style={footerStyles.page}
      render={({ pageNumber }) => `${translations[languageCode].page} ${pageNumber}`}
    ></Text>
  </View>
)

const inspectionHeaderStyles = StyleSheet.create({
  header: {
    fontFamily: 'Halyard Display',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.5,
    marginTop: 25,
    marginBottom: 10,
  },
})

type InspectionHeaderProps = Pick<InspectionPageProps, 'inspection' | 'languageCode'>

const InspectionHeader = ({
  inspection,
  languageCode,
}: InspectionHeaderProps): React.ReactElement => (
  <View style={inspectionHeaderStyles.header} fixed>
    <Text>{`${translations[languageCode].lotNumber} ${inspection}`}</Text>
  </View>
)

const inspectionTableStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  borderBottomRow: {
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: COLORS.inspectionTableBorderColor,
  },
  borderLeftRightRow: {
    borderLeft: 1,
    borderRight: 1,
    borderStyle: 'solid',
    borderColor: COLORS.inspectionTableBorderColor,
  },
  odd: {
    backgroundColor: COLORS.oddColor,
  },
  rowHeader: {
    fontWeight: 'bold',
  },
  column: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    width: '50%',
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  columnSmall: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    width: '50%',
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 5,
  },
  columnHeader: {
    fontFamily: 'Halyard Display',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.5,
  },
  firstHeader: {
    paddingTop: 0,
    marginBottom: -10,
  },
  lastColumn: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    width: '20%',
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  lastColumnSmall: {
    fontFamily: 'Halyard Text',
    fontSize: 10,
    width: '20%',
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  remark: {
    marginTop: 10,
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
  },
  remarkHeader: {
    fontFamily: 'Halyard Display',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.8,
  },
  remarkBody: {
    lineHeight: 1.5,
  },
})

type InspectionTableProps = {
  inspection: string
  inspectionFile: InspectionFile
  languageCode: Language
}

const InspectionTable = ({
  inspection,
  inspectionFile,
  languageCode,
}: InspectionTableProps): React.ReactElement => (
  <View id={inspection}>
    <View>
      <View style={inspectionTableStyles.row}>
        <Text
          style={[
            inspectionTableStyles.columnSmall,
            inspectionTableStyles.columnHeader,
            inspectionTableStyles.firstHeader,
          ]}
        >
          {translations[languageCode].product}
        </Text>
        <Text
          style={[
            inspectionTableStyles.columnSmall,
            inspectionTableStyles.columnHeader,
            inspectionTableStyles.firstHeader,
          ]}
        >
          {translations[languageCode].location}
        </Text>
        <Text
          style={[
            inspectionTableStyles.columnSmall,
            inspectionTableStyles.columnHeader,
            inspectionTableStyles.firstHeader,
          ]}
        >
          {translations[languageCode].coulance}
        </Text>
        <Text
          style={[
            inspectionTableStyles.columnSmall,
            inspectionTableStyles.columnHeader,
            inspectionTableStyles.firstHeader,
          ]}
        >
          {inspectionFile.clockSupplyLine.courtesyNote
            ? translations[languageCode].reason
            : ''}
        </Text>
        <Text
          style={[
            inspectionTableStyles.lastColumnSmall,
            inspectionTableStyles.columnHeader,
            inspectionTableStyles.firstHeader,
          ]}
        ></Text>
      </View>
      <View style={inspectionTableStyles.row}>
        <Text style={inspectionTableStyles.columnSmall}>
          {inspectionFile.clockSupplyLine.productName}
        </Text>
        <Text style={inspectionTableStyles.columnSmall}>
          {
            LOCATIONS[
              inspectionFile.clockSupplyLine.location as 0 | 1 | 2 | 3 | 7 | 24 | 25
            ]
          }
        </Text>
        <Text style={inspectionTableStyles.columnSmall}>
          {inspectionFile.clockSupplyLine.courtesy
            ? translations[languageCode].yes
            : translations[languageCode].no}
        </Text>
        <Text style={inspectionTableStyles.columnSmall}>
          {inspectionFile.clockSupplyLine.courtesyNote || ''}
        </Text>
        <Text style={inspectionTableStyles.lastColumnSmall}></Text>
      </View>
    </View>
    <View>
      <View style={[inspectionTableStyles.row, inspectionTableStyles.borderBottomRow]}>
        <Text
          style={[inspectionTableStyles.column, inspectionTableStyles.columnHeader]}
        >
          {translations[languageCode].corrections}
        </Text>
        <Text
          style={[inspectionTableStyles.column, inspectionTableStyles.columnHeader]}
        >
          {translations[languageCode].originalValue}
        </Text>
        <Text
          style={[inspectionTableStyles.column, inspectionTableStyles.columnHeader]}
        >
          {translations[languageCode].adjustedValue}
        </Text>
        <Text
          style={[inspectionTableStyles.column, inspectionTableStyles.columnHeader]}
        >
          {translations[languageCode].class}
        </Text>
        <Text
          style={[inspectionTableStyles.lastColumn, inspectionTableStyles.columnHeader]}
        >
          {translations[languageCode].qi}
        </Text>
      </View>
      <View style={inspectionTableStyles.borderLeftRightRow}>
        {inspectionFile.clockSupplyLine.observationFindings.map(
          (observationFinding, index) => (
            <View
              key={index}
              style={[
                inspectionTableStyles.row,
                inspectionTableStyles.borderBottomRow,
                index % 2 === 0 ? inspectionTableStyles.odd : {},
              ]}
            >
              <Text style={inspectionTableStyles.column}>
                {observationFinding.observationCategory}
              </Text>
              <Text style={inspectionTableStyles.column}>
                {observationFinding.originalValueCode}
              </Text>
              <Text style={inspectionTableStyles.column}>
                {observationFinding.correctionValueCode ||
                  observationFinding.salesOrderValueCode}
              </Text>
              <Text style={inspectionTableStyles.column}>
                {observationFinding.rate}
              </Text>
              <Text style={inspectionTableStyles.lastColumn}>
                {observationFinding.isQiComponent
                  ? translations[languageCode].yes
                  : translations[languageCode].no}
              </Text>
            </View>
          )
        )}
      </View>
    </View>
    <View style={inspectionTableStyles.remark}>
      <Text style={inspectionTableStyles.remarkHeader}>
        {translations[languageCode].remarks}
      </Text>
      <Text style={inspectionTableStyles.remarkBody}>{inspectionFile.remark}</Text>
    </View>
  </View>
)

const inspectionItemStyles = StyleSheet.create({
  item: {
    flexDirection: 'column',
  },
  // w384h216 == XS size in API (16:9 aspect ratio)
  image: {
    width: 384 / 1.52,
    height: 216 / 1.52,
  },
  photoHeader: {
    fontFamily: 'Halyard Display',
    fontSize: 12,
    fontWeight: 700,
    marginTop: 5,
  },
  remark: {
    width: 121,
    fontFamily: 'Halyard Text',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.5,
    marginBottom: 10,
  },
})

type InspectionItemProps = {
  image: string
  index: number
  commentsTotal: number
  description: string
  languageCode: Language
}

const InspectionItem = ({
  image,
  index,
  commentsTotal,
  description,
  languageCode,
}: InspectionItemProps): React.ReactElement => (
  <View style={inspectionItemStyles.item} wrap={false}>
    <Image
      style={{ ...inspectionItemStyles.image, objectFit: 'contain' }}
      src={image}
    />
    <View>
      <Text style={inspectionItemStyles.photoHeader}>
        {`${translations[languageCode].photo} ${index}/${commentsTotal}`}
      </Text>
      <Text style={inspectionItemStyles.remark}>{description}</Text>
    </View>
  </View>
)

const inspectionPageStyles = StyleSheet.create({
  layout: {
    flexDirection: 'column',
  },
  wrapper: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  inspectionItems: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
})

type InspectionPageProps = {
  inspection: string
  inspectionFile: InspectionFile
} & Pick<InspectionReportProps, 'images' | 'languageCode'>

const InspectionPage = ({
  inspection,
  inspectionFile,
  images,
  languageCode,
}: InspectionPageProps): React.ReactElement => (
  <Page size='A4' style={inspectionPageStyles.layout}>
    <View style={inspectionPageStyles.wrapper}>
      <InspectionHeader inspection={inspection} languageCode={languageCode} />
      <InspectionTable
        inspection={inspection}
        inspectionFile={inspectionFile}
        languageCode={languageCode}
      />
      <View style={inspectionPageStyles.inspectionItems}>
        {inspectionFile.audits
          .map(audit => audit.comments.map(comment => comment))
          .flat()
          .map((comment, commentIndex) => (
            <InspectionItem
              key={commentIndex}
              image={images[comment.supplierImageQuery]}
              index={commentIndex + 1}
              commentsTotal={
                inspectionFile.audits.map(audit => audit.comments.map(c => c)).flat()
                  .length
              }
              description={comment.description}
              languageCode={languageCode}
            />
          ))}
      </View>
    </View>
    <Footer languageCode={languageCode} />
  </Page>
)

type InspectionReportProps = {
  inspectionReport: InspectionReportType
  images: InspectionReportImages
  languageCode: Language
  name: string
  email: string
}

export const InspectionReport = ({
  inspectionReport,
  images,
  languageCode,
  name,
  email,
}: InspectionReportProps): React.ReactElement => (
  <Document>
    <CoverPage
      supplierName={inspectionReport.supplierName}
      supplierId={inspectionReport.supplierId}
      date={inspectionReport.inspectionFiles[0].clockSupplyLine.auctionDate
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')}
      qiClass={inspectionReport.inspectionFiles[0].clockSupplyLine.qiClass}
      inspections={inspectionReport.inspectionFiles.map(
        inspectionFile =>
          `${inspectionFile.clockSupplyLine.supplyFormCode}${inspectionFile.clockSupplyLine.supplyFormCharacter}`
      )}
      languageCode={languageCode}
      name={name}
      email={email}
    />
    {inspectionReport.inspectionFiles.map(inspectionFile => (
      <InspectionPage
        key={`${inspectionFile.clockSupplyLine.supplyFormCode}${inspectionFile.clockSupplyLine.supplyFormCharacter}`}
        inspection={`${inspectionFile.clockSupplyLine.supplyFormCode}${inspectionFile.clockSupplyLine.supplyFormCharacter}`}
        inspectionFile={inspectionFile}
        images={images}
        languageCode={languageCode}
      />
    ))}
  </Document>
)

export const InspectionReportViewer = (): React.ReactElement => (
  <PDFViewer width={1000} height={1000} showToolbar={false}>
    <InspectionReport
      inspectionReport={exampleInspectionReport}
      images={exampleImages}
      languageCode='NL'
      name='Voornaam Achternaam'
      email='voornaam.achternaam@example.com'
    />
  </PDFViewer>
)
