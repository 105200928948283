import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { ClockSupplyLine } from 'src/types/InspectionReport'

type QualityTableProps = {
  clockSupplyLine: ClockSupplyLine
  location: (
    location: number
  ) => '' | 'Onbekend' | 'Aalsmeer' | 'Naaldwijk' | 'Rijnsburg' | 'Eelde'
}

export const QualityTable: FC<QualityTableProps> = ({ clockSupplyLine, location }) => {
  const { t } = useTranslation()

  return (
    <Table sx={{ width: 'fit-content' }}>
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              paddingBottom: 0,
            }}
            colSpan={3}
          >
            {t('createReport.stepper.productDescription')}
          </TableCell>
          <TableCell
            align='left'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              paddingBottom: 0,
            }}
            colSpan={3}
          >
            {t('createReport.stepper.location')}
          </TableCell>
          <TableCell
            align='left'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              paddingBottom: 0,
            }}
            colSpan={3}
          >
            {t('createReport.stepper.coulance')}
          </TableCell>
          {clockSupplyLine.courtesyNote ? (
            <TableCell
              align='left'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: 'none',
                paddingBottom: 0,
              }}
              colSpan={3}
            >
              {t('createReport.stepper.reason')}
            </TableCell>
          ) : null}
        </TableRow>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 'none' } }}>
          <TableCell
            colSpan={3}
            component='th'
            style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
          >
            <Typography variant='body2'>{clockSupplyLine.productName}</Typography>
          </TableCell>
          <TableCell
            colSpan={3}
            align='left'
            component='th'
            style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
          >
            <Typography variant='body2'>
              {location(clockSupplyLine.location)}
            </Typography>
          </TableCell>
          <TableCell
            colSpan={3}
            align='left'
            component='th'
            style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
          >
            <Typography variant='body2'>
              {clockSupplyLine.courtesy ? 'Ja' : 'Nee'}
            </Typography>
          </TableCell>
          {clockSupplyLine.courtesyNote ? (
            <TableCell
              colSpan={3}
              align='left'
              component='th'
              style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
            >
              <Typography variant='body2'>{clockSupplyLine.courtesyNote}</Typography>
            </TableCell>
          ) : null}
        </TableRow>
        <TableRow>
          <TableCell
            style={{ border: 'none', backgroundColor: 'white', color: 'black' }}
            colSpan={3}
          >
            {t('createReport.stepper.qualityChange')}
          </TableCell>
          <TableCell
            align='left'
            style={{ border: 'none', backgroundColor: 'white', color: 'black' }}
            colSpan={3}
          >
            {t('createReport.stepper.offeredValue')}
          </TableCell>
          <TableCell
            align='left'
            style={{ border: 'none', backgroundColor: 'white', color: 'black' }}
            colSpan={3}
          >
            {t('createReport.stepper.correctionValue')}
          </TableCell>
          <TableCell
            align='left'
            style={{ border: 'none', backgroundColor: 'white', color: 'black' }}
            colSpan={3}
          >
            {t('createReport.stepper.taxCategory')}
          </TableCell>
          <TableCell
            align='left'
            style={{ border: 'none', backgroundColor: 'white', color: 'black' }}
            colSpan={3}
          >
            {t('createReport.stepper.qi')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {clockSupplyLine?.observationFindings?.map(finding => (
          <TableRow
            key={finding.observationFindingId}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell colSpan={3} component='th'>
              {finding.observationCategory}
            </TableCell>
            <TableCell align='left' colSpan={3}>
              {finding.originalValueCode}
            </TableCell>
            <TableCell align='left' colSpan={3}>
              {finding.correctionValueCode || finding.salesOrderValueCode}
            </TableCell>
            <TableCell align='left' colSpan={3}>
              {finding.rate}
            </TableCell>
            <TableCell align='left' colSpan={3}>
              {finding.isQiComponent ? 'Ja' : 'Nee'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
