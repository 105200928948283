import RfhColors from '@rfh-core/theme'

import packageJson from '../../package.json'

export const logVersion = (): void => {
  console.info(
    `%cVersion: ${packageJson.version}`,
    `background: ${RfhColors.rfhColors.hydroGreen}; color:white; padding:8px`
  )
}
