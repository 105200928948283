import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Switch, { SwitchProps } from '@mui/material/Switch'
import { useMutation } from '@tanstack/react-query'

import { useNotification } from 'src/hooks/useNotification'
import { postObservationFindingsQiComponent } from 'src/services/observationFindingsService'
import { ObservationFindingOverview } from 'src/types/ObservationFindings'

export const ObservationFindingsQiToggle: FC<{ row: ObservationFindingOverview }> = ({
  row,
}) => {
  const [localValue, setLocalValue] = useState(row.isQiComponent)
  const { t } = useTranslation()
  const { showNotification } = useNotification()

  const mutation = useMutation<
    unknown,
    unknown,
    { mutationRow: ObservationFindingOverview; updatedValue: boolean }
  >({
    mutationKey: ['postObservationFindingsQiComponent', row.id, localValue],
    mutationFn: ({ mutationRow, updatedValue }) =>
      postObservationFindingsQiComponent(mutationRow, updatedValue),
    onError: () => {
      setLocalValue(row.isQiComponent)
      showNotification([t('observationFindings.toggleQiError')], 'error')
    },
  })

  const handleChange: SwitchProps['onChange'] = (_event, checked) => {
    setLocalValue(checked)
    mutation.mutate({ mutationRow: row, updatedValue: checked })
  }

  return (
    <Switch
      checked={localValue}
      size='small'
      inputProps={{ 'aria-label': t('observationFindings.toggleQi') }}
      onChange={handleChange}
    />
  )
}
