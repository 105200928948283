// import axios from 'axios'

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })

// Needed for fetching images from the network
// and immediately converting them to base64 strings
// so they can be rendered in a PDF.
// async function getImageBlobFromUrl(url: string) {
//   const image: string = await axios
//     .get(url, {
//       responseType: 'blob',
//     })
//     .then(response => blobToBase64(response.data))
//   // console.log(image)
//   return image
// }
// getImageBlobFromUrl('https://picsum.photos/384/216.jpg')
