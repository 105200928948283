import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, ReactNode, useContext } from 'react'

export interface MutateStore {
  mutatingActive: boolean
  setMutatingActive: (active: boolean) => void
}

export const MutateStoreContext = createContext<MutateStore | null>(null)

export const initialState: MutateStore = {
  mutatingActive: false,
  setMutatingActive(active) {
    this.mutatingActive = active
  },
}

// Store as component
export const MutateStoreProvider: FC<{
  initial?: MutateStore
  children: ReactNode
}> = ({ children, initial = initialState }) => {
  const mutateStore: MutateStore = useLocalObservable(() => initial)

  return (
    <MutateStoreContext.Provider value={mutateStore}>
      {children}
    </MutateStoreContext.Provider>
  )
}

export const useMutateStore = (): MutateStore => {
  const store = useContext(MutateStoreContext)

  if (!store) {
    throw new Error('No mutate store defined')
  }

  return store
}
