/* istanbul ignore file */
import nl from 'date-fns/locale/nl'

import { Control, Controller } from 'react-hook-form'

import { DateRange, LocalizationProvider, nlNL } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

type UseDateRangePickerProps = {
  name: string
  control: Control<any>
}

export const useDateRangePicker = ({
  name,
  control,
}: UseDateRangePickerProps): JSX.Element => (
  <>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={nl}
      localeText={{
        ...nlNL.components.MuiLocalizationProvider.defaultProps.localeText,
        start: '',
        end: '',
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateRangePicker
            onChange={newValue => onChange(newValue)}
            value={value as DateRange<Date>}
          />
        )}
      />
    </LocalizationProvider>
  </>
)
