import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { CloseIcon, OrangeScanIcon, SearchIcon } from '@rfh-core/icons'

import { useNotification } from 'src/hooks/useNotification'

import { CameraModal } from '../photoFeature/CameraModal'
import { useBatchQuery } from '../photoFeature/useBatchQuery'

type BottomNavigationProps = {
  setIsCameraModalShown: Dispatch<SetStateAction<boolean>>
}
export default function BottomBar(props: BottomNavigationProps) {
  const [showSearchBox, setShowSearchBox] = useState(false)
  const [code, setCode] = useState('')
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const { isError, isSuccess, isFetching } = useBatchQuery(code)
  const { showNotification } = useNotification()
  const { t } = useTranslation()

  useEffect(() => {
    if (isError) {
      showNotification([t('errors.genericError')], 'error')
    }
    if (isSuccess) {
      setShowSearchBox(false)
      navigate(`/batch/${code}`)
    }
  }, [isSuccess, isError])

  if (isCameraOpen) {
    return (
      <CameraModal
        onClose={() => {
          setIsCameraOpen(false)
          props.setIsCameraModalShown(false)
        }}
      />
    )
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '56px',
        backgroundColor: 'white',
        zIndex: 2,
      }}
      elevation={3}
    >
      {!showSearchBox && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '56px',
            width: '150px',
            margin: '0 auto',
          }}
        >
          <IconButton
            aria-label='scan'
            onClick={() => {
              setIsCameraOpen(previousState => !previousState)
              props.setIsCameraModalShown(true)
            }}
          >
            <OrangeScanIcon />
          </IconButton>
          <IconButton onClick={() => setShowSearchBox(true)} aria-label='zoek'>
            <SearchIcon
              sx={{
                width: '20px',
                height: '20px',
                color: ({ rfhColors }) => rfhColors.dutchOrange,
              }}
            />
          </IconButton>
        </div>
      )}
      {showSearchBox && !isFetching && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '90%',
            margin: '0 auto',
          }}
        >
          <IconButton
            sx={{
              svg: {
                width: '16px',
                height: '16px',
              },
            }}
            aria-label='close'
            onClick={() => setShowSearchBox(false)}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            name='bottom-nav-search-input'
            InputProps={{ inputRef }}
            fullWidth
            autoFocus
          />
          <IconButton
            aria-label='search'
            className='contained'
            sx={{ borderRadius: '0', height: '40px' }}
            onClick={() => {
              setCode(inputRef.current?.value ?? '')
            }}
          >
            <SearchIcon />
          </IconButton>
        </div>
      )}
      {isFetching && <CircularProgress aria-label='loading' size={32} />}
    </Paper>
  )
}
