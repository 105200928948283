/* eslint-disable id-blacklist */
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FileDownload } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import { useIsFetching } from '@tanstack/react-query'

import { queryClient } from 'src/App'
import { getObservationFindings } from 'src/services/observationFindingsService'
import { CourtesyCodes } from 'src/types/CourtesyCodes'
import { ObservationFindingsSearchInput, Rate } from 'src/types/ObservationFindings'
import { exportRowsToCSV, Field } from 'src/utils/csv'
import { getFormattedDate } from 'src/utils/format'

type DownloadButtonProps = {
  searchValues: ObservationFindingsSearchInput
  count: number
  loading: boolean
}

export const DownloadButton: FC<DownloadButtonProps> = ({
  searchValues,
  count,
  loading,
}) => {
  const isDownloading = useIsFetching({ queryKey: ['observationFindingsCsv'] })

  const { t } = useTranslation()
  const [isError, setIsError] = useState(false)
  const fields: Field[] = [
    {
      value: row => getFormattedDate(row.transactionDate),
      label: t('observationFindings.tableHeaders.transactionDate'),
    },
    {
      value: 'supplierNumber',
      label: t('observationFindings.tableHeaders.supplierNumber'),
    },
    {
      value: 'supplierName',
      label: t('observationFindings.tableHeaders.supplierName'),
    },
    {
      value: row => `${row.supplyFormCode} ${row.supplyFormCharacter}`,
      label: t('observationFindings.tableHeaders.supplyFormCode'),
    },
    {
      value: 'productName',
      label: t('observationFindings.tableHeaders.productName'),
    },
    {
      value: 'approvedBy',
      label: t('observationFindings.tableHeaders.approvedBy'),
    },
    {
      value: 'observationCategoryDescription',
      label: t('observationFindings.tableHeaders.observationCategoryDescription'),
    },
    {
      value: 'salesOrderValueCode',
      label: t('observationFindings.tableHeaders.salesOrderValueCode'),
    },
    {
      value: 'qiClass',
      label: t('observationFindings.tableHeaders.qiClass'),
    },
    {
      value: row => (row.courtesy !== CourtesyCodes.geen_coulance ? 'Ja' : 'Nee'),
      label: t('observationFindings.tableHeaders.courtesy'),
    },
    {
      value: 'location',
      label: t('observationFindings.nestedTableHeaders.location'),
    },
    {
      value: row => (row.rate === Rate.complaint ? 'Reclamatie' : 'Bevinding'),
      label: t('observationFindings.nestedTableHeaders.type'),
    },
    {
      value: 'productCode',
      label: t('observationFindings.nestedTableHeaders.productCode'),
    },
    {
      value: 'plantType',
      label: t('observationFindings.nestedTableHeaders.product'),
    },
    {
      value: 'observationTypeCode',
      label: t('observationFindings.nestedTableHeaders.type'),
    },
    {
      value: 'rate',
      label: t('observationFindings.nestedTableHeaders.observationCategory'),
    },
    {
      value: 'dataField',
      label: t('observationFindings.nestedTableHeaders.characteristicCode'),
    },
    {
      value: 'originalValueCode',
      label: t('observationFindings.nestedTableHeaders.originalValue'),
    },
    {
      value: 'correctionValueCode',
      label: t('observationFindings.nestedTableHeaders.correctionValue'),
    },
    {
      value: row =>
        row.courtesy !== CourtesyCodes.geen_coulance && row.courtesyUser
          ? row.courtesyUser
          : '',
      label: t('observationFindings.nestedTableHeaders.courtesyBy'),
    },
    {
      value: row =>
        row.courtesy !== CourtesyCodes.geen_coulance && row.courtesyNote
          ? row.courtesyNote
          : '',
      label: t('observationFindings.nestedTableHeaders.courtesyDescription'),
    },
  ]

  const downloadCsv = async () => {
    setIsError(false)

    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['observationFindingsCsv', searchValues],
        queryFn: () => getObservationFindings(searchValues, count),
      })

      exportRowsToCSV(data.value, fields)
    } catch (error) {
      setIsError(true)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Button
        disabled={isDownloading > 0 || loading}
        onClick={downloadCsv}
        sx={{
          m: '2px',
          width: '36px',
          height: '36px',
          minWidth: '0px',
          padding: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: ({ rfhColors }) => rfhColors.dutchOrange,
          svg: {
            color: ({ rfhColors }) => rfhColors.white,
          },
        }}
      >
        {isDownloading > 0 ? (
          <CircularProgress aria-label={t('queryState.loading.label')} size={24} />
        ) : (
          <FileDownload />
        )}
      </Button>
      {isError && (
        <FormHelperText error>{t('observationFindings.downloadError')}</FormHelperText>
      )}
    </Box>
  )
}
