import { FC } from 'react'

import Grid from '@mui/material/Grid'

import { Characteristics } from 'src/components/Characteristics'
import { NegativeCodes } from 'src/components/NegativeCodes'
import { PositiveCodes } from 'src/components/PositiveCodes'
import type { ClockSupplyLine } from 'src/types/ClockSupplyLine'
import { gridSpacing } from 'src/views/InspectionDetails'

import { CharacteristicsGridItem } from './styles/Grid.styles'

type InformationSectionProps = {
  batch: ClockSupplyLine
  setMutatedBatch: (batch: ClockSupplyLine) => void
}

export const InformationSection: FC<InformationSectionProps> = ({
  batch,
  setMutatedBatch,
}) => (
  <Grid
    item
    xs={12}
    sm={6}
    lg={8}
    sx={{
      '& .MuiOutlinedInput-root': {
        background: 'white',
      },
      '& .MuiAutocomplete-root': {
        background: 'transparent',
      },
    }}
  >
    <Grid container spacing={gridSpacing}>
      <CharacteristicsGridItem item xs={12} lg={6}>
        <NegativeCodes batch={batch} setMutatedBatch={setMutatedBatch} />
      </CharacteristicsGridItem>
      <CharacteristicsGridItem item xs={12} lg={6}>
        <PositiveCodes batch={batch} setMutatedBatch={setMutatedBatch} />
      </CharacteristicsGridItem>
      <CharacteristicsGridItem item xs={12}>
        <Characteristics batch={batch} setMutatedBatch={setMutatedBatch} />
      </CharacteristicsGridItem>
    </Grid>
  </Grid>
)
