import { Dispatch, FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import { useMutate } from 'src/hooks/useMutate'
import { useOnlineOffline } from 'src/hooks/useOnlineOffline'
import { BatchStatus } from 'src/types/ClockSupplyLine'

type BatchActionProps = {
  batchStatusHandler: Dispatch<BatchStatus>
  setMutating: (active: boolean) => void
}

export const BatchResultActions: FC<BatchActionProps> = ({
  batchStatusHandler,
  setMutating,
}) => {
  const { t } = useTranslation()
  const { offline } = useOnlineOffline()
  const { mutatingActive } = useMutate()

  return (
    <Box mt={2.5} display='flex' flexDirection='column'>
      <Stack
        direction='row'
        gap={2}
        justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
      >
        {mutatingActive ? (
          <Button
            variant='contained'
            onClick={() => {
              setMutating(false)
              batchStatusHandler(BatchStatus.Approved)
            }}
            sx={{ width: ['100%', '100%', 'auto'] }}
          >
            {t('inspection.batchActions.saveModify')}
          </Button>
        ) : (
          <Button
            disabled={offline}
            variant='outlined'
            onClick={() => setMutating(true)}
            sx={{ width: ['100%', '100%', 'auto'] }}
          >
            {t('inspection.batchActions.modify')}
          </Button>
        )}
        <Button
          variant='contained'
          disabled={mutatingActive || offline}
          onClick={() => batchStatusHandler(BatchStatus.Approved)}
          sx={{ width: ['100%', '100%', 'auto'] }}
        >
          {t('inspection.batchActions.next')}
        </Button>
      </Stack>
    </Box>
  )
}
