import { useEffect, useRef } from 'react'

import { useHotkeyStore } from 'src/stores/HotkeyStore'
import { Code } from 'src/types/Code'

export const useHotkey = (key: Code, handler: (event: KeyboardEvent) => void): void => {
  const handlerRef = useRef(handler)

  // TODO: currently isHotkeyActive acts for all hotkeys at the same time -> oude TODO, moeten we hier nog wat mee?
  const { isHotkeyActive } = useHotkeyStore()

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  const handleHotkey = (event: KeyboardEvent) => {
    if (!isHotkeyActive) {
      return
    }
    if (event.key === key) {
      handlerRef.current(event)
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleHotkey)
    return () => window.removeEventListener('keyup', handleHotkey)
  }, [isHotkeyActive])
}
