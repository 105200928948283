import { forwardRef } from 'react'
import {
  BrowserRouter as Router,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import rfhTheme from '@rfh-core/theme'
import '@rfh-core/theme/dist/fonts.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import 'src/App.css'
import { FilterDrawerContextProvider } from 'src/components/FilterDrawer'
import { Loader } from 'src/components/Loader'
import { Routes } from 'src/components/Routes'
import { NotificationProvider } from 'src/hooks/useNotification'
import { AccountStoreProvider } from 'src/stores/AccountStore'
import { BarcodeStoreProvider } from 'src/stores/BarcodeStore'
import { BatchStatusStoreProvider } from 'src/stores/BatchStatusStore'
import { HotkeyStoreProvider } from 'src/stores/HotkeyStore'
import { MutateStoreProvider } from 'src/stores/MutateStore'
import { TableStoreProvider } from 'src/stores/TableStore'
import { logVersion } from 'src/utils/logVersion'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const themeOverrides = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
}

const theme = createTheme(rfhTheme, themeOverrides)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
    },
  },
})
const App = (): JSX.Element => {
  logVersion()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <QueryClientProvider client={queryClient}>
          <Loader />
          <NotificationProvider>
            <MutateStoreProvider>
              <FilterDrawerContextProvider>
                <BarcodeStoreProvider>
                  <AccountStoreProvider>
                    <BatchStatusStoreProvider>
                      <HotkeyStoreProvider>
                        <TableStoreProvider>
                          <Routes />
                        </TableStoreProvider>
                      </HotkeyStoreProvider>
                    </BatchStatusStoreProvider>
                  </AccountStoreProvider>
                </BarcodeStoreProvider>
              </FilterDrawerContextProvider>
            </MutateStoreProvider>
          </NotificationProvider>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}
export default App
