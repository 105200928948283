import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import {
  FinanceSettlementOverview,
  FinanceSettlementSearchInput,
} from 'src/types/financeSettlement'

import { Feature, useFeatureFlag } from '../../hooks/useFeatureFlag'
import { StyledTableContainer } from './FinanceSettlement.styles'
import { FinanceSettlementTableBody } from './FinanceSettlementTableBody'

export type RefetchDataFunction = (
  values: FinanceSettlementSearchInput,
  pageSize: number,
  currentPage: number
) => Promise<void>
type FinanceSettlementTableProps = {
  rows: FinanceSettlementOverview[]
  loading: boolean
  tablePaginationProps: TablePaginationProps
  refetchData: RefetchDataFunction
}

export const FinanceSettlementTable: FC<FinanceSettlementTableProps> = observer(
  ({ rows, loading, tablePaginationProps, refetchData }) => {
    const { t } = useTranslation()
    const isFinancieelCorrectieOverzichtMuteren = useFeatureFlag(
      Feature.FinancieelCorrectieOverzichtMuteren
    )

    return (
      <>
        <StyledTableContainer>
          <Table className='zebra'>
            <TableHead>
              <TableRow>
                {Object.values(
                  t('FinanceSettlement.tableHeaders', { returnObjects: true })
                ).map(header => (
                  <TableCell align='left' key={`header-${header}`}>
                    {header}
                  </TableCell>
                ))}
                {isFinancieelCorrectieOverzichtMuteren && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              <FinanceSettlementTableBody
                refetchData={refetchData}
                tablePaginationProps={tablePaginationProps}
                loading={loading}
                rows={rows}
              />
            </TableBody>
            <TableFooter>
              <TablePagination
                sx={{
                  '.MuiInputBase-root ': {
                    padding: ({ spacing }) => spacing(0, 2),
                  },
                }}
                data-testid='FinanceSettlementTablePagination'
                {...tablePaginationProps}
              />
            </TableFooter>
          </Table>
        </StyledTableContainer>
      </>
    )
  }
)
