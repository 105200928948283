/* eslint-disable id-blacklist */
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Save from '@mui/icons-material/Save'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import type {
  AddAuditCommentPayload,
  ChangeInspectionFileRemarkPayload,
  DeleteAuditCommentPayload,
} from 'src/services/inspectionReportsService'
import type { InspectionFile, InspectionReportImages } from 'src/types/InspectionReport'

import { PhotoUpload, ShowPhoto } from './PhotoUpload'
import { QualityTable } from './QualityTable'

type StepContentLayoutProps = {
  onNext: () => void
  isLastStep: () => boolean
  stepData: InspectionFile
  imagesFromApi: InspectionReportImages
  addRemark: (object: AddAuditCommentPayload) => void
  deleteRemark: (object: DeleteAuditCommentPayload) => void
  editComment: (object: ChangeInspectionFileRemarkPayload) => void
  isEditing: boolean
  setIsEditingRemark: (editing: boolean) => void
}

export const StepContentLayout: FC<StepContentLayoutProps> = ({
  onNext,
  isLastStep,
  stepData,
  imagesFromApi,
  addRemark,
  isEditing,
  setIsEditingRemark,
  deleteRemark,
  editComment,
}) => {
  const { t } = useTranslation()
  const [remarkValue, setRemarkValue] = useState(stepData.remark)
  const mapLocationFromEnum = (location: number) => {
    switch (location) {
      case 0:
        return 'Onbekend'
      case 1:
        return 'Aalsmeer'
      case 2:
        return 'Naaldwijk'
      case 3:
        return 'Rijnsburg'
      case 7:
        return 'Eelde'
      default:
        // istanbul ignore next
        return ''
    }
  }
  // istanbul ignore next
  return (
    <Paper
      sx={{
        paddingBottom: 5,
        borderTop: 'none',
        marginLeft: '11px',
      }}
    >
      <Grid container spacing={3} sx={{ paddingX: 5, marginBottom: 8 }}>
        <QualityTable
          clockSupplyLine={stepData.clockSupplyLine}
          location={mapLocationFromEnum}
        />
        <Grid item xs={8}>
          <Typography variant='body1' sx={{ pb: 1 }}></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' sx={{ pb: 1 }}>
            {t('createReport.stepper.remark')}
          </Typography>
          {isEditing ? (
            <Box sx={{ display: 'flex' }}>
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                placeholder={t('createReport.stepper.reportDescription')}
                defaultValue={stepData.remark}
                multiline
                rows={2}
                onChange={e => setRemarkValue(e.target.value)}
              />
              <Box sx={{ paddingLeft: '8px', alignSelf: 'flex-end' }}>
                <IconButton
                  sx={{ width: '30px', height: '30px' }}
                  className='contained'
                  onClick={() =>
                    editComment({
                      inspectionRapportId: stepData.inspectionRapportId,
                      inspectionFileId: stepData.inspectionFileId,
                      remark: remarkValue,
                    })
                  }
                  aria-label='deleteRemarkButton'
                >
                  <Save sx={{ fontSize: '20px' }} />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Typography variant='body1' className='book'>
              {stepData.remark}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' sx={{ pb: 1 }}>
            {t('createReport.stepper.photoUpload')}
          </Typography>
          <Grid>
            {stepData.audits.map(audit =>
              audit.comments.map((comment, index) => (
                <ShowPhoto
                  key={index}
                  supplierImageId={imagesFromApi[comment.supplierImageQuery]}
                  description={comment.description}
                  isEditing={isEditing}
                  onDelete={() => {
                    deleteRemark({
                      clockSupplyLineId: stepData.clockSupplyLineId,
                      inspectionRapportId: stepData.inspectionRapportId,
                      inspectionFileId: stepData.inspectionFileId,
                      auditAssignmentId: audit.auditId,
                    })
                  }}
                />
              ))
            )}
          </Grid>
          {isEditing && <PhotoUpload stepData={stepData} addRemark={addRemark} />}
        </Grid>
      </Grid>

      <Divider light />
      <Box sx={{ paddingX: 5, mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {isEditing ? (
            <>
              <Button disabled variant='text'>
                {t('createReport.buttons.edit')}
              </Button>
              {!isLastStep && (
                <Button
                  variant='contained'
                  onClick={() => {
                    setIsEditingRemark(false)
                    onNext()
                  }}
                  disabled={isLastStep}
                >
                  {!isLastStep && t('createReport.buttons.next')}
                </Button>
              )}
            </>
          ) : (
            <Button variant='text' onClick={() => setIsEditingRemark(true)}>
              {t('createReport.buttons.edit')}
            </Button>
          )}
          {!isEditing && (
            <Box>
              {!isLastStep && (
                <Button variant='contained' onClick={onNext} disabled={isLastStep}>
                  {!isLastStep && t('createReport.buttons.next')}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  )
}
