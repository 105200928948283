import { useDebounce } from 'use-debounce'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import NativeSelect from '@mui/material/NativeSelect'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { ChevronDownSm } from '@rfh-core/icons'
import { useMutation } from '@tanstack/react-query'

import { queryClient } from 'src/App'
import {
  PostProductCodeParams,
  getProducts,
  postProductCode,
} from 'src/services/productService'
import { Product } from 'src/types/ClockSupplyLine'

import { StyledInputLabel } from './ChangeProductDialog.styles'
import { ChangeProductDialogTableBody } from './ChangeProductDialogTableBody'

export type ChangeProductFilterValues = {
  description: string | undefined
  type: number | undefined
}

const initialValues = {
  description: undefined,
  type: undefined,
}

export const ChangeProductDialog: FC<
  DialogProps & {
    clockSupplyLineId: number
    onProductChange: (product: Product) => void
  }
> = ({ clockSupplyLineId, onProductChange, ...rest }) => {
  const { t } = useTranslation()

  const [values, setValues] = useState<ChangeProductFilterValues>(initialValues)
  const [debouncedValues] = useDebounce(values, 1000)
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const hourInMs = 60 * 60 * 100

  useEffect(() => {
    if (values.description) {
      queryClient.fetchQuery({
        queryKey: ['products', values.description, values.type],
        queryFn: () => getProducts(values),
        staleTime: hourInMs,
      })
      setSelectedProduct(undefined)
    }
  }, [debouncedValues])

  const handleClose: DialogProps['onClose'] = (event, reason) => {
    rest.onClose!(event, reason)
    setValues(initialValues)
    setSelectedProduct(undefined)
  }

  const { mutate, isError } = useMutation({
    mutationFn: (params: PostProductCodeParams) => postProductCode(params),
    onSuccess: () => {
      handleClose({}, 'escapeKeyDown')
      onProductChange(selectedProduct!)
    },
  })

  return (
    <Dialog {...rest} onClose={handleClose}>
      <DialogContent
        sx={{
          width: { xs: '100%', md: '500px' },
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <Box>
          <Typography variant='body1' className='bold'>
            {t('inspection.changeProductDialog.title')}
          </Typography>
          <Typography variant='body1'>
            {t('inspection.changeProductDialog.description')}
          </Typography>
        </Box>
        {isError && (
          <Alert severity='error'>{t('inspection.changeProductDialog.error')}</Alert>
        )}
        <Grid container columnSpacing={1}>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <StyledInputLabel>
                {t('inspection.changeProductDialog.form.descriptionLabel')}
              </StyledInputLabel>
              <TextField
                onChange={event => {
                  setValues(current => ({
                    ...current,
                    description: event.target.value,
                  }))
                }}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <StyledInputLabel>
                {t('inspection.changeProductDialog.form.producttypeLabel')}
              </StyledInputLabel>
              <NativeSelect
                IconComponent={() => (
                  <ChevronDownSm
                    sx={{
                      align: 'right',
                      maxHeight: 24,
                    }}
                  />
                )}
                fullWidth
                defaultValue={-1}
                onChange={event => {
                  setValues(current => ({
                    ...current,
                    type: Number(event.target.value),
                  }))
                }}
              >
                <option value={-1} disabled>
                  {t('inspection.changeProductDialog.form.producttypePlaceholder')}
                </option>
                <option value={1}>
                  {t('inspection.changeProductDialog.form.producttypeValuePlants')}
                </option>
                <option value={2}>
                  {t('inspection.changeProductDialog.form.producttypeValueFlowers')}
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: '300px' }}>
          <Table
            stickyHeader
            size='medium'
            sx={{
              border: ({ rfhColors }) => `1px solid ${rfhColors.grey[100]}`,
            }}
          >
            <TableHead
              sx={{
                th: {
                  backgroundColor: ({ rfhColors }) => rfhColors.leafGreen,
                  color: ({ rfhColors }) => rfhColors.white,
                  fontWeight: 'bold',
                },
              }}
            >
              <TableRow>
                {t('inspection.changeProductDialog.table.headers', {
                  returnObjects: true,
                }).map(header => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <ChangeProductDialogTableBody
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              queryKeys={[
                'products',
                debouncedValues.description,
                debouncedValues.type,
              ]}
            />
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderTop: ({ rfhColors }) => `1px solid ${rfhColors.grey[100]}`,
            pt: 2,
          }}
        >
          <Button variant='text' onClick={() => handleClose({}, 'escapeKeyDown')}>
            {t('inspection.changeProductDialog.button.close')}
          </Button>
          <Button
            variant='contained'
            type='submit'
            disabled={!selectedProduct}
            onClick={() =>
              mutate({
                clockSupplyLineId,
                vbnProductCode: selectedProduct!.vbnProductCode,
              })
            }
          >
            {t('inspection.changeProductDialog.button.submit')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
