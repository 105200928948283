/* istanbul ignore file */
import { FC, ReactElement } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export type EmptyStateProps = {
  header: string
  subheader?: ReactElement | string
  graphic?: ReactElement | string
  bottomComponent?: ReactElement | string
}

export const EmptyState: FC<EmptyStateProps> = ({
  header,
  subheader,
  graphic,
  bottomComponent,
}) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      maxWidth={theme.breakpoints.values.md}
      display='flex'
      gap={2}
      alignItems='center'
      flexWrap={smallScreen ? 'wrap' : 'nowrap'}
    >
      <Box display='flex' flexDirection='column' gap={1}>
        <Box>
          <Typography variant='h4'>{header}</Typography>
        </Box>
        <Box>{subheader}</Box>
        <Box mt={1}>{bottomComponent}</Box>
      </Box>
      <Box width='100%' maxWidth={theme.breakpoints.values.sm}>
        {graphic}
      </Box>
    </Box>
  )
}
