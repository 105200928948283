import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'

import { useOnlineOffline } from 'src/hooks/useOnlineOffline'

type CourtesySwitchProps = {
  checked: boolean
  isError: boolean
  isLoading: boolean
  disabled?: boolean
  errorMessage?: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CourtesySwitch: FC<CourtesySwitchProps> = ({
  checked,
  isError,
  isLoading,
  errorMessage,
  disabled = false,
  handleChange,
}) => {
  const { t } = useTranslation()
  const { offline } = useOnlineOffline()

  return (
    <Box>
      {isLoading ? (
        <CircularProgress size={24} aria-label={t('queryState.loading.label')} />
      ) : (
        <Switch
          disabled={disabled || offline}
          inputProps={{ tabIndex: -1 }}
          checked={checked}
          onChange={handleChange}
        />
      )}
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  )
}
