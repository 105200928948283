import { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Footer } from '@rfh-core/components'

import { AppBar } from 'src/components/Appbar'

import { FilterDrawerContainer } from './FilterDrawer'

export type LayoutProps = {
  slot?: ReactNode
  children?: ReactNode
  hasDrawer?: boolean
  minHeight?: string
}

export const Layout: FC<LayoutProps> = ({
  slot,
  children,
  hasDrawer,
  minHeight = 'calc(100vh - 54px)',
}) => (
  // TODO: Checken waarom de paddingBottom is toegevoegd? De footer staat nu erg raar.
  <Box position='relative' minHeight='100vh'>
    <AppBar />
    {slot}
    <FilterDrawerContainer enabled={hasDrawer} minHeight={minHeight}>
      <Container sx={{ flexGrow: 1, maxWidth: '1536px' }} maxWidth={false}>
        {children}
      </Container>

      <Footer maxWidth='xl' />
    </FilterDrawerContainer>
  </Box>
)
