import { FC, ReactNode } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export type FormFields = {
  observationId: string[]
  supplierId: string[]
  inspectionFileId: string[]
  keurverslagId: string
}

export const InspectionReportsFormProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const form = useForm<FormFields>({
    defaultValues: {
      observationId: [],
      inspectionFileId: [],
      keurverslagId: '',
    },
  })
  return <FormProvider {...form}>{children}</FormProvider>
}
