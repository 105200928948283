import { useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'

type Props = {
  backgroundColor: string
  onCropChange: (crop: Area) => void
  rotation: number
  src: string
}

export const RfhCroppper = ({
  backgroundColor,
  onCropChange,
  rotation,
  src,
}: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  return (
    <Cropper
      image={`${src}${src.startsWith('http') ? '?1=5' : ''}`}
      crop={crop}
      zoom={zoom}
      maxZoom={10}
      minZoom={0.1}
      aspect={1}
      restrictPosition={false}
      rotation={rotation}
      classes={{
        // Jest omits HSL colors, so we use a class to test the color, only in test mode
        ...(process.env.NODE_ENV === 'test' && {
          containerClassName: `jestColor=${backgroundColor}`,
        }),
      }}
      style={{
        containerStyle: {
          position: 'relative',
          width: '100%',
          aspectRatio: '1',
          backgroundColor,
        },
      }}
      onCropChange={setCrop}
      onCropComplete={(_, _croppedAreaPixels) => {
        onCropChange(_croppedAreaPixels)
      }}
      onZoomChange={setZoom}
    />
  )
}
