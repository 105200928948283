import { AxiosError } from 'axios'

import {
  ChangeEventHandler,
  Dispatch,
  FC,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { useMutation } from '@tanstack/react-query'

import { changeBatchImage } from 'src/services/batchService'
import type { BatchImage, ClockSupplyLine } from 'src/types/ClockSupplyLine'

type BatchImageEditProps = {
  batchImage: BatchImage
  mutatedImageId: string | undefined
  handleMutatedImageId: Dispatch<SetStateAction<string | undefined>>
  updateMutatedBatch: Dispatch<SetStateAction<ClockSupplyLine | undefined>>
}

export const BatchImageEdit: FC<BatchImageEditProps> = ({
  batchImage,
  mutatedImageId,
  handleMutatedImageId,
  updateMutatedBatch,
}) => {
  const [isEditting, setIsEditting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const { t } = useTranslation()

  const {
    mutateAsync,
    isError,
    error,
    isPending: isLoading,
    reset,
  } = useMutation({
    mutationFn: (newImageId: string) => changeBatchImage(batchImage, newImageId),
    onError: (_error: AxiosError) => {
      // this sets the correct error type
    },
  })

  useEffect(() => {
    switch (error?.response?.status) {
      case 400:
        setErrorMessage(t('inspection.batchImage.errorMessage.badRequest'))
        break
      case 500:
        setErrorMessage(t('inspection.batchImage.errorMessage.internalServerError'))
        break
      default:
        setErrorMessage(undefined)
    }
  }, [error])

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    handleMutatedImageId(event.target.value)
  }

  const handleClose = () => {
    reset()
    handleMutatedImageId(batchImage.supplierImageId)
    setIsEditting(false)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()
    try {
      await mutateAsync(mutatedImageId!)

      updateMutatedBatch(prevBatch => ({
        ...prevBatch!,
        batchImages: [{ ...batchImage, supplierImageId: mutatedImageId! }],
      }))
      setIsEditting(false)
      reset()
    } catch (_error) {
      console.log(error)
      // prevent error from throwing inside component function
    }
  }

  return (
    <>
      <Box
        sx={{
          visibility: isLoading ? 'visible' : 'hidden',
          backdropFilter: 'blur(8px)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress aria-label={t('queryState.loading.label')} />
      </Box>
      <Box position='absolute' width='100%' p={2}>
        {isEditting ? (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                backgroundColor: ({ rfhColors }) => rfhColors.white,
                border: ({ rfhColors }) => `1px solid ${rfhColors.grey[50]}`,
                display: 'flex',
                p: 1,
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              <TextField
                inputProps={{ 'aria-label': t('inspection.batchImage.imageRefLabel') }}
                InputProps={{ sx: { height: '32px' } }}
                variant='outlined'
                value={mutatedImageId}
                onChange={handleChange}
                fullWidth
                error={isError}
                helperText={errorMessage}
              />
              <IconButton
                sx={{
                  svg: {
                    width: '16px',
                    height: '16px',
                  },
                }}
                type='submit'
                className='contained'
                disabled={!mutatedImageId || isLoading}
                aria-label={t('inspection.batchImage.saveBtnLabel')}
              >
                <CheckIcon />
              </IconButton>

              <IconButton
                sx={{
                  svg: {
                    width: '16px',
                    height: '16px',
                  },
                }}
                className='outlined'
                disabled={isLoading}
                aria-label={t('inspection.batchImage.closeBtnLabel')}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </form>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 0.5,
            }}
          >
            <IconButton
              sx={{
                svg: {
                  width: '16px',
                  height: '16px',
                },
              }}
              className='contained'
              disabled={false}
              aria-label={t('inspection.batchImage.editBtnLabel')}
              onClick={() => setIsEditting(true)}
            >
              <EditIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}
