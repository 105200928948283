import { ObservationFindingOverview } from 'src/types/ObservationFindings'

export type Field = {
  value:
    | keyof ObservationFindingOverview
    | ((row: ObservationFindingOverview) => string)
  label: string
}

export const delimiterOrBreak = (index: number, length: number): string =>
  index < length - 1 ? ',' : '\r\n'

const mapFieldToRow =
  (fields: Field[]) =>
  (row: ObservationFindingOverview): string => {
    let csvRow = ''
    fields.forEach((field, index, array) => {
      const value =
        typeof field.value === 'function' ? field.value(row) : row[field.value]

      csvRow = csvRow.concat(
        `${JSON.stringify(value)}${delimiterOrBreak(index, array.length)}`
      )
    })

    return csvRow
  }

export const convertJson2Csv = (
  rows: ObservationFindingOverview[],
  fields: Field[]
): string => {
  let csv = ''
  // add headings
  fields.forEach((field, index, array) => {
    csv = csv.concat(
      `${JSON.stringify(field.label)}${delimiterOrBreak(index, array.length)}`
    )
  })
  // convert rows
  csv = csv.concat(rows.map(mapFieldToRow(fields)).join(''))

  return csv
}

export const exportRowsToCSV = (
  rows: ObservationFindingOverview[],
  fields: Field[]
): void => {
  const csv = convertJson2Csv(rows, fields)

  const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csv)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute(
    'download',
    `Kwaliteitsbevindingen-export-${new Date().toISOString()}.csv`
  )
  document.body.appendChild(link) // Required for FF

  link.click()
}
