/* eslint-disable id-blacklist */

/* istanbul ignore file */
import { AxiosError, AxiosResponse } from 'axios'

import { FC, forwardRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useNavigate,
} from 'react-router-dom'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { nlNL } from '@mui/x-data-grid'
import { useIsFetching, useMutation } from '@tanstack/react-query'

import { queryClient } from 'src/App'
import { FormFields } from 'src/components/InspectionReportsFormProvider'
import { Layout } from 'src/components/Layout'
import {
  defaultValues,
  ObservationFindingsSearchForm,
} from 'src/components/observationFindings/ObservationFindingsSearchForm'
import { ObservationFindingsTable } from 'src/components/observationFindings/ObservationFindingsTable'
import { useNotification } from 'src/hooks/useNotification'
import {
  getObservationFindingsRapports,
  postInspectionReport,
} from 'src/services/inspectionReportsService'
import {
  ObservationFindingOverview,
  ObservationFindingsSearchInput,
} from 'src/types/ObservationFindings'
import { ODataResponse } from 'src/types/odata'

import { Feature, useFeatureFlag } from '../hooks/useFeatureFlag'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  return <RouterLink ref={ref} to={href} {...other} />
})

export const InspectionReports: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const fetching = useIsFetching({ queryKey: ['inspectionReports'] })
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [value, setValue] = useState('one')
  const form = useFormContext<FormFields>()
  const isKeurverslagenSchermMuterenEnabled = useFeatureFlag(
    Feature.KeurverslagenSchermMuteren
  )
  const [searchValues, setSearchValues] =
    useState<ObservationFindingsSearchInput>(defaultValues)

  const response = queryClient.getQueryData<
    AxiosResponse<ODataResponse<ObservationFindingOverview[]>>
  >(['inspectionReports', searchValues, rowsPerPage, page])

  const fetchData = async (
    values: ObservationFindingsSearchInput,
    pageSize: number,
    currentPage: number
  ) =>
    queryClient.fetchQuery({
      queryKey: ['inspectionReports', values, pageSize, currentPage],
      queryFn: () => getObservationFindingsRapports(values, pageSize, currentPage),
      staleTime: 0,
    })

  const handleSearchFormSubmit = async (formValues: ObservationFindingsSearchInput) => {
    setPage(0)
    setSearchValues(formValues)
    form.reset({
      observationId: [],
    })
    try {
      const res = await fetchData(formValues, rowsPerPage, 0)
      form.reset({
        observationId: res.data.value.map(
          ({ observationFindingId }) => observationFindingId + ''
        ),
      })
    } catch (e) {
      // show notification?
    }
  }

  const handlePageChange = (currentPage: number) => {
    setPage(currentPage)
    fetchData(searchValues, rowsPerPage, currentPage)
    form.reset({
      observationId: [],
    })
  }

  const handleRowsPerPageChange = (currentRowsPerPage: number) => {
    setRowsPerPage(currentRowsPerPage)
    fetchData(searchValues, currentRowsPerPage, page)
    form.reset({
      observationId: [],
    })
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
    form.reset({
      observationId: [],
    })
  }

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: async () => {
      if (!response) {
        return
      }
      const observationIds = form.getValues('observationId')
      const data = await postInspectionReport(
        response.data.value
          .filter(({ observationFindingId }) =>
            observationIds.includes(observationFindingId + '')
          )
          .map(({ inspectionFileId }) => inspectionFileId),
        response.data.value[0].supplierId
      )
      return data
    },
    onSuccess: data => {
      navigate(
        `/inspection-reports/new?keurverslagId=${data?.data?.id ? data?.data?.id : ''}`
      )
    },
    onError: (_error: AxiosError) => {
      showNotification([_error.message], 'error')
    },
  })

  return (
    <Layout hasDrawer={true}>
      <CssBaseline />
      <Container
        sx={{
          flexGrow: 1,
          maxWidth: '1536px',
          mt: 8,
          px: '0px !important',
        }}
        maxWidth={false}
      >
        <Typography variant='h3'>{t('inspectionReports.title')}</Typography>

        <Tabs
          sx={{
            mb: 10,
          }}
          value={value}
          onChange={handleTabChange}
        >
          <Tab value='one' label={t('inspectionReports.tabs.create')} />
          {/* TODO add history tab <Tab value='two' label={t('inspectionReports.tabs.archive')} /> */}
        </Tabs>
        <ObservationFindingsSearchForm
          onSearch={handleSearchFormSubmit}
          inspectionReports={true}
        />

        <ObservationFindingsTable
          tablePaginationProps={{
            count: response?.data?.['@odata.count'] ?? 0,
            rowsPerPage,
            page,
            onPageChange: (_event, currentPage) => handlePageChange(currentPage),
            onRowsPerPageChange: event =>
              handleRowsPerPageChange(Number(event.target.value)),
            labelRowsPerPage:
              nlNL.components.MuiDataGrid.defaultProps.localeText.MuiTablePagination
                ?.labelRowsPerPage,
          }}
          rows={response?.data?.value ?? []}
          loading={fetching > 0}
        />
        {isKeurverslagenSchermMuterenEnabled && (
          <Button
            onClick={() => {
              mutateAsync()
            }}
            sx={{ mt: 4 }}
            disabled={isLoading || !form.getValues('observationId').length}
            LinkComponent={LinkBehavior}
          >
            {t('inspectionReports.buttonText')}
          </Button>
        )}
      </Container>
    </Layout>
  )
}
