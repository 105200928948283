import { AxiosPromise } from 'axios'
import buildQuery from 'odata-query'

import { ChangeProductFilterValues } from 'src/components/dialogs/ChangeProductDialog'
import { Product } from 'src/types/ClockSupplyLine'
import { ODataResponse } from 'src/types/odata'
import { api } from 'src/utils/api'

type Filters = {
  or: Record<string, unknown>[]
  productGroup: Record<string, unknown> | undefined
}

export const getProducts = (
  values: ChangeProductFilterValues,
  top = 25,
  page = 0
): AxiosPromise<ODataResponse<Product[]>> => {
  let productGroup

  switch (values.type) {
    case 1:
      // > 20000000 plant
      productGroup = { gt: 20000000 }
      break
    case 2:
      // < 20000000 bloem
      productGroup = { lt: 20000000 }
      break
    default:
      productGroup = undefined
      break
  }

  const filters: Filters = {
    or: [{ productName: { contains: values.description?.toUpperCase() } }],
    productGroup,
  }

  if (/^\d+$/.test(values.description || '')) {
    filters.or.push({
      vbnProductCode: {
        eq: Number(values.description),
      },
    })
  }

  const query = buildQuery<Product>({
    top,
    filter: filters,
    skip: page * top,
    count: true,
  })

  return api.get(`/odata/v1/Products${query}`)
}

export type PostProductCodeParams = {
  vbnProductCode: number
  clockSupplyLineId: number
}

export const postProductCode = (params: PostProductCodeParams): AxiosPromise<void> =>
  api.post(
    `/api/v1/ClockSupplyLines/Commands/ChangeProductCode/${params.clockSupplyLineId}`,
    params
  )
