/* eslint-disable id-blacklist */
import { observer } from 'mobx-react-lite'

import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { nlNL } from '@mui/x-data-grid'
import { useOktaAuth } from '@okta/okta-react'
import { ChevronDownSm } from '@rfh-core/icons'

import { ObservationFindingsTable } from 'src/components//observationFindings/ObservationFindingsTable'
import { DownloadButton } from 'src/components/DownloadButton'
import { Layout } from 'src/components/Layout'
import {
  ObservationFindingsSearchForm,
  defaultValues,
} from 'src/components/observationFindings/ObservationFindingsSearchForm'
import { useNotification } from 'src/hooks/useNotification'
import { getObservationFindings } from 'src/services/observationFindingsService'
import { useTableStore } from 'src/stores/TableStore'
import {
  ObservationFindingOverview,
  ObservationFindingsSearchInput,
} from 'src/types/ObservationFindings'
import { ODataResponse } from 'src/types/odata'

export const ObservationFindings: FC = observer(() => {
  const { t } = useTranslation()
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ODataResponse<ObservationFindingOverview[]>>()
  const { authState } = useOktaAuth()
  const { setRows } = useTableStore()
  const { showNotification } = useNotification()

  const initialState: ObservationFindingsSearchInput = {
    ...defaultValues,
    approvedBy: authState!.accessToken!.claims.sub,
  }
  const [searchValues, setSearchValues] =
    useState<ObservationFindingsSearchInput>(initialState)

  const fetchData = async (
    values: ObservationFindingsSearchInput,
    pageSize: number,
    currentPage: number
  ) => {
    setLoading(true)

    try {
      const { data: responseData } = await getObservationFindings(
        values,
        pageSize,
        currentPage
      )
      setData(responseData)
      setRows(responseData.value)
    } catch (error) {
      showNotification([t('observationFindings.apiError')], 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(initialState, rowsPerPage, page)
  }, [])

  const handleSubmit = (formValues: ObservationFindingsSearchInput) => {
    setPage(0)
    setSearchValues(formValues)
    fetchData(formValues, rowsPerPage, 0)
  }

  const handlePageChange = (currentPage: number) => {
    setPage(currentPage)
    fetchData(searchValues, rowsPerPage, currentPage)
  }

  const handleRowsPerPageChange = (currentRowsPerPage: number) => {
    setRowsPerPage(currentRowsPerPage)
    fetchData(searchValues, currentRowsPerPage, page)
  }

  const { toggleAllRowsExpansion, expandedRows } = useTableStore()

  const hasExpandedRow = expandedRows.length > 0

  return (
    <Layout hasDrawer={true}>
      <Container
        sx={{
          flexGrow: 1,
          maxWidth: '1536px',
          mt: 8,
          px: '0px !important',
        }}
        maxWidth={false}
      >
        <Typography variant='h3'>{t('observationFindings.title')}</Typography>
        <ObservationFindingsSearchForm onSearch={handleSubmit} />

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          mb={2}
          mt={8}
          gap={2}
        >
          <Typography variant='h4'>{t('observationFindings.tableTitle')}</Typography>

          <Box ml='auto'>
            <Button variant='text' onClick={() => toggleAllRowsExpansion()}>
              {hasExpandedRow
                ? t('observationFindings.collapseAll')
                : t('observationFindings.expandAll')}
              <ChevronDownSm
                sx={{
                  transform: hasExpandedRow ? 'rotate(-180deg)' : 'none',
                  fontSize: 'initial',
                  ml: 1,
                }}
              />
            </Button>
          </Box>

          <DownloadButton
            searchValues={searchValues}
            count={data?.['@odata.count'] ?? 0}
            loading={loading}
          />
        </Box>

        <ObservationFindingsTable
          tablePaginationProps={{
            count: data?.['@odata.count'] ?? 0,
            rowsPerPage,
            page,
            onPageChange: (_event, currentPage) => handlePageChange(currentPage),
            onRowsPerPageChange: event =>
              handleRowsPerPageChange(Number(event.target.value)),
            labelRowsPerPage:
              nlNL.components.MuiDataGrid.defaultProps.localeText.MuiTablePagination
                ?.labelRowsPerPage,
          }}
          rows={data?.value || []}
          loading={loading}
        />
      </Container>
    </Layout>
  )
})
